import { useState } from 'react';
import { Outlet } from 'react-router-dom';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
//
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import { Box, Button, TextField, Typography } from '@material-ui/core';
// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const [welcome,setWelcome] =useState(false)

  return (
    <>
    {/* {
      !welcome ? 
      <Box sx={{ width: "100%", height: "100%", position: "absolute", backgroundColor: '#4dbfab', display:"flex",justifyContent:"center",alignItems:"center"  }}>
        <Box sx={{backgroundColor:"white",width:"500px",height:"150px"}}>
          <Typography fontSize={"small"} align='center'>Enter Today's Exchange Rate</Typography>
          <Box sx={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",mt:3}}>
          <TextField 
          placeholder='Enter Rate' disabled align="center" width="50px" />
          <Button variant='contained' sx={{backgroundColor:"#4dbfab",mt:1}} onClick={()=>setWelcome(true)} >Update and Continue</Button>
          </Box>
        </Box>
        </Box> 
      :
    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      <MainStyle>
        <Outlet />
      </MainStyle>
    </RootStyle>
    } */}

<RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      <MainStyle>
        <Outlet />
      </MainStyle>
    </RootStyle>
    </>
  );
}
