import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
// material
import {
  Box,
  Grid,
  Card,
  Stack,
  Switch,
  TextField,
  FormControlLabel,
  Typography,
  FormHelperText,Button
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
// hooks
import useAuth from '../../../../hooks/useAuth';
import useIsMountedRef from '../../../../hooks/useIsMountedRef';
import { UploadAvatar } from '../../../upload';
// utils
import { fData } from '../../../../utils/formatNumber';
//
// import countries from '../../../../countries';
import countries from '../../user/countries';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { useNavigate } from 'react-router';
import axios from 'axios';
import { REST_API } from 'src/defaultValues';
import { styled } from '@material-ui/styles';
import { MLinearProgress } from 'src/components/@material-extend';
import LoadingScreen from 'src/components/LoadingScreen';
// ----------------------------------------------------------------------



const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1)
}));

export default function ViewStaffDetails({setViewDetailsPage,id,customer}) {
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();
  const { user, updateProfile } = useAuth();
  const [data,setData]=useState([])
 const [isLoading, setIsLoading] = useState(true);

  
  const navigate=useNavigate()


  useEffect(()=> {
    customer ?

    axios.get(`${REST_API}webservice/customer/find-customer/${id}`)
       .then(res =>{
        if(res.data.status===1) {
          console.log(res.data.result);
          setData(...res.data.result)
        }else{
          console.log("not getting data");
        }
        
       }).catch(err =>console.log(err))
       .finally(() => {
        setTimeout(() => {
          setIsLoading(false);
        }, 2000); 
      })

    :
       axios.get(`${REST_API}webservice/customer/find-staff/${id}`)
       .then(res =>{
        if(res.data.status===1) {
          console.log(res.data.result);
          setData(...res.data.result)
        }else{
          console.log("not getting data");
        }
        
       }).catch(err =>console.log(err))
       .finally(() => {
        setTimeout(() => {
          setIsLoading(false);
        }, 2000); 
      })
  },[])

 

  const UpdateUserSchema = Yup.object().shape({
    displayName: Yup.string().required('Name is required')
  });

  const formik = useFormik({
    enableReinitialize: true,
    // initialValues: {
    //   displayName: data? data.firstName + data.lastName : '',
    //   email: data.email,
    //   photoURL: data.photoURL,
    //   phoneNumber: data.phoneNumber,
    //   country: data.country,
    //   address: data.address,
    //   state: data.state,
    //   city: data.city,
    //   zipCode: data.zipCode,
    //   about: data.about,
    //   isPublic: data.isPublic,
    //   password:data.password
    // },

    initialValues: {
      displayName: data? data.firstName + data.lastName : '',
      email: data ? data.email : '',
      photoURL: data ?  `${REST_API}${data?.image}` : '',
      phoneNumber: data ? data.phoneNumber : '',
      country: data ? data.country : '',
      address: data ? data.address : '',
      state: data ? data.state : '',
      city: data ? data.city : '',
      zipCode: data ? data.zipCode : '',
      about: data ? data.about : '',
      isPublic: data ? data.isPublic : '',
      password:data ? data.pass : ''
    },
    
    validationSchema: UpdateUserSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        // await updateProfile({ ...values });
        enqueueSnackbar('Update success', { variant: 'success' });
        if (isMountedRef.current) {
          setSubmitting(false);
        }
      } catch (error) {
        if (isMountedRef.current) {
          setErrors({ afterSubmit: error.code });
          setSubmitting(false);
        }
      }
    }
  });

  const { values, errors, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        setFieldValue('photoURL', {
          ...file,
          preview: URL.createObjectURL(file)
        });
      }
    },
    [setFieldValue]
  );

  const handleBack=()=> {
    // navigate(PATH_DASHBOARD.staffDetails.root)
    setViewDetailsPage(false)
  }


  if (isLoading) {
    return(
      <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <LoadingScreen />
      </Box>
    ) 
  }

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Card sx={{ py: 10, px: 3, textAlign: 'center' }}>
              <UploadAvatar
                accept="image/*"
                file={values.photoURL}
                maxSize={3145728}
                onDrop={handleDrop}
                error={Boolean(touched.photoURL && errors.photoURL)}
                caption={
                  <Typography
                    variant="caption"
                    sx={{
                      mt: 2,
                      mx: 'auto',
                      display: 'block',
                      textAlign: 'center',
                      color: 'text.secondary'
                    }}
                  >
                    Allowed *.jpeg, *.jpg, *.png, *.gif
                    <br /> max size of {fData(3145728)}
                  </Typography>
                }
              />

              <FormHelperText error sx={{ px: 2, textAlign: 'center' }}>
                {touched.photoURL && errors.photoURL}
              </FormHelperText>

              {/* <FormControlLabel
                control={<Switch {...getFieldProps('isPublic')} color="primary" />}
                labelPlacement="start"
                label="Public Profile"
                sx={{ mt: 5 }}
              /> */}
            </Card>
          </Grid>

          <Grid item xs={12} md={8}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={{ xs: 2, md: 3 }}>
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                <Box sx={{ width: '100%' }}>
                    <LabelStyle>Name</LabelStyle>
                  <TextField fullWidth  defaultValue={"Name"} disabled
                  {...getFieldProps('displayName')} />
                  </Box>

              <Box sx={{ width: '100%' }}>
                    <LabelStyle>Email Address</LabelStyle>

                  <TextField fullWidth disabled  defaultValue={"Email"}
                  value={data? data.email : ""}
                  {...getFieldProps('email')} />
                  </Box>

                </Stack>

                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>

                <Box sx={{ width: '100%' }}>
                    <LabelStyle>Phone Number</LabelStyle>
                  <TextField fullWidth  defaultValue={"xxx xxxx xxx"} disabled
                  value={data? data.phoneNumber : ""}
                  {...getFieldProps('phoneNumber')} />
                  </Box>
                    <Box sx={{width:"100%"}}>
                          <LabelStyle>Address</LabelStyle>
                  <TextField fullWidth  {...getFieldProps('address')} disabled/>
                  </Box>
                </Stack>

                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                <Box sx={{width:"100%"}}>
                          <LabelStyle>Country</LabelStyle>
                  <TextField
                    select
                    fullWidth
                   
                    placeholder="Country" disabled
                    value={data? data.country : ""}
                    {...getFieldProps('country')}
                    SelectProps={{ native: true }}
                    error={Boolean(touched.country && errors.country)}
                    helperText={touched.country && errors.country}
                  >
                    <option value="" />
                    {countries.map((option) => (
                      <option key={option.code} value={option.label}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                  </Box>
                  <Box sx={{width:"100%"}}>
                          <LabelStyle>State/Region</LabelStyle>
                  <TextField fullWidth  disabled
                  value={data? data.state : ""} 
                  {...getFieldProps('state')} />
                  </Box>
                </Stack>

                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                <Box sx={{width:"100%"}}>
                          <LabelStyle>City</LabelStyle>
                  <TextField fullWidth  value={data? data.city : ""} disabled
                  {...getFieldProps('city')} />
                  </Box>
                  <Box sx={{width:"100%"}}>
                          <LabelStyle>zipCode</LabelStyle>
                  <TextField fullWidth  value={data? data.zipCode : ""} disabled
                  {...getFieldProps('zipCode')} />
                  </Box>
                </Stack>
                       
                <Box sx={{width:"100%"}}>
                          <LabelStyle>About</LabelStyle>
                <TextField  
                value={data? data.about : ""}
                 {...getFieldProps('about')} 
                 fullWidth multiline minRows={4} maxRows={4}   disabled/>
                 </Box>

                 <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                 <Box sx={{width:"100%"}}>
                          <LabelStyle>Password</LabelStyle>
                <TextField
                    fullWidth    disabled
                    value={data ? data.password : ''}
                    {...getFieldProps('password')}
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                  />
                 </Box>

                </Stack>

              </Stack>

              
              

              <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                {/* <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                  Update
                </LoadingButton> */}
                <Button variant='contained' sx={{backgroundColor:"#32b59f",
                    '&:hover': {
                      backgroundColor: "#32b59f", 
                    },}}
                    onClick={handleBack}
                    >Back</Button>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}