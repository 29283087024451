import faker from 'faker';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { useTheme } from '@material-ui/core/styles';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import {
  Box,
  Card,
  Table,
  Button,
  Divider,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CardHeader,
  TableContainer,
  TextField,
  InputLabel, Select, MenuItem, Typography, Stack, Container,
  Autocomplete

} from '@material-ui/core';
// utils
import { fCurrency } from '../../../../utils/formatNumber';
//
import Label from '../../../Label';
import Scrollbar from '../../../Scrollbar';
import MoreMenuButton from '../../../MoreMenuButton';
import { Link , useLocation } from 'react-router-dom';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import * as Yup from 'yup'
import { useFormik, Form, FormikProvider } from 'formik'
import { useEffect, useState } from 'react';
import { LoadingButton } from '@material-ui/lab'
import axios from 'axios'
import { REST_API } from 'src/defaultValues';
// ----------------------------------------------------------------------


const randomDate = faker.date.past(); 
const INVOICES = [];
for (let i = 1; i <= 100; i++) {
  INVOICES.push({
    id: i,
    percentage: `${i}%`
  });
}

// ----------------------------------------------------------------------

export default function VATAdd({update,setUpdatePage,itemId,setRefresh}) {
  const theme = useTheme();
  const navigate=useNavigate()
  const [data,setData]=useState([])

  useEffect(()=> {
    axios.get(`${REST_API}webservice/admin/get-vat/${itemId}`)
    .then(res => {
      if(res.data.status===1) {
        console.log("get vat",res.data);
        setData(res.data.result)
      }else {
        console.log("not getting vat details");

      }
      
    }).catch(err =>console.log(err))
  },[])

  const RegisterSchema = Yup.object().shape({
    name: Yup.string().required('VAT name  is required'),
    percentage: Yup.string().required('percentage is required'),
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name:data ? data?.name : '',
      percentage: data ? data?.percentage : '',
    },
    validationSchema: RegisterSchema,
    
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      
      try {
        console.log('Form data:', values)
        // await register(values.email, values.password, values.Name, values.phoneNumber);
     let response;
       {update ? 
        response =  await axios
        .put(`${REST_API}webservice/admin/update-vat/${itemId}`, {values})
        .then(res =>{
          if(res.data.status===1){
            console.log(res.data)
            setUpdatePage(false)
            setRefresh(true)
            enqueueSnackbar('Vat Details Updated successfully', {
              variant: 'success',
              action: (key) => (
                <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                  <Icon icon={closeFill} />
                </MIconButton>
              ),
            })
          }else {
            console.log("not updating");
            enqueueSnackbar('Vat Details not Updated ', {
              variant: 'success',
              action: (key) => (
                <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                  <Icon icon={closeFill} />
                </MIconButton>
              ),
            })
          }
          
        })
        .catch(err => console.log(err))
      :
      response =  await axios
      .post(`${REST_API}webservice/admin/add-vat`, {values})
      .then((res) => {
        navigate(PATH_DASHBOARD.settings.vat)
        console.log(res.data)
        enqueueSnackbar('VAT Details added successfully', {
          variant: 'success',
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          ),
        })
      })
      .catch((err) => {
        console.log(err)
      })
    }
      
       
        if (isMountedRef.current) {
          setSubmitting(false)
          //   navigate('/');
          // navigate(PATH_DASHBOARD.staffDetails.root);
        }
      } catch (error) {
        console.error(error)
        if (isMountedRef.current) {
          setErrors({ afterSubmit: error.message })
          setSubmitting(false)
        }
      }
    },
  })
  const { errors,values, touched, handleSubmit, isSubmitting, getFieldProps,setFieldValue } = formik


  const handleBack=()=> {
    navigate(PATH_DASHBOARD.settings.vat)
    setUpdatePage ? setUpdatePage(false) : ''
  }
  return (
    <>
    <Box sx={{m:2}}>
    <Typography sx={{color:"#32b59f",fontSize:"small"}}>Settings / VAT / Add</Typography>
   </Box>
    <Card>  
    <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
      <Box sx={{display:"flex",justifyContent:"space-between"}}>
      <CardHeader title="Add New VAT" sx={{ mb: 1,fontSize:"small" }} />
      <Button
      sx={{
        mr:3,
        mt:3,
        height:"40px",
        color:"#fff",
        backgroundColor: "#32b59f",
        '&:hover': {
          backgroundColor: "#32b59f", 
        },
      }} onClick={handleBack}
    > 
     Back 
    </Button>
        </Box>
      <Divider sx={{mt:3}}/>
       <Container>
      <Box sx={{width:"100%",p:3}}>
        <Box>
          {/* <TextField variant="outlined" disabled placeholder='Search ...'/> */}

             <Stack spacing={{ xs: 2, md: 3 }} sx={{mt:3}}>
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                {/* <TextField fullWidth variant="outlined" label="VAT Title"  placeholder='Enter Title'/> */}
                {/* <Typography>VAT Title</Typography> */}
                <TextField 
                            fullWidth
                            defaultValue={update?"Title":""}
                            variant="outlined"
                            placeholder='Enter Title'
                            label="VAT Title"
                            {...getFieldProps('name')}
                            error={Boolean(touched.name && errors.name)}
                            helperText={touched.name && errors.name}
                          />
          
                    {/* <Autocomplete
                            select
                            fullWidth
                            options={INVOICES}
                            getOptionLabel={(option) => option.percentage}
                            value = {INVOICES.find((option) => option.percentage == values.percentage) || null}
                            onChange={(event, newValue) => {
                              // console.log('newValue---',newValue);
                              if (newValue) {
                                const selectedoption = INVOICES.find(option => option.percentage == newValue.percentage);
                                // console.log('selectedOption---',selectedOption);
                                if (selectedoption) {
                                  setFieldValue('percentage', selectedoption.percentage);
                                } else {
                                  setFieldValue('percentage', '');
                                }
                              } else {
                              setFieldValue('percentage', '');
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Select percentage"
                                error={Boolean(touched.percentage && errors.percentage)}
                                helperText={touched.percentage && errors.percentage}
                              />
                            )}
                          /> */}
                           <TextField 
                            fullWidth
                            type='number'
                            defaultValue={update?"Select percentage":""}
                            variant="outlined"
                            placeholder='Enter VAT Percentage'
                            label="VAT Percentage"
                            {...getFieldProps('percentage')}
                            error={Boolean(touched.percentage && errors.percentage)}
                            helperText={touched.percentage && errors.percentage}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              )
                            }}
                          />
                    </Stack>
                    </Stack>
        </Box>
       
      </Box>
      <Divider sx={{mt:3}}/>
          <Box sx={{display:"flex",justifyContent:"end",mb:3}}>
          <Button 
      sx={{mr:3,mt:3,height:"40px",color:"#fff",backgroundColor:"#32b59f",'&:hover': {backgroundColor: "#32b59f"}}}
    onClick={handleBack}>
      Cancel 
    </Button>
         <LoadingButton
                  loading={isSubmitting}
                  type="submit"
                  sx={{
                    mr: 3,
                    mt: 3,
                    height: '40px',
                    color: '#fff',
                    backgroundColor: '#32b59f',
                    '&:hover': { backgroundColor: '#32b59f' },
                  }}
                >
                  {update ?"Update": "Save"}
                  
        </LoadingButton>
          </Box>
      </Container>


      {/* <Box sx={{ p: 2, textAlign: 'right' }}>
        <Button
          to="#"
          size="small"
          color="inherit"
          component={RouterLink}
          endIcon={<Icon icon={arrowIosForwardFill} />}
        >
          View All
        </Button>
      </Box> */}
       </Form>
        </FormikProvider>
    </Card>
    </>

  );
}
