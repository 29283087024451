import faker from 'faker';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment'
// material
import { useTheme } from '@material-ui/core/styles';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import {
  Box,
  Card,
  Table,
  Button,
  Divider,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CardHeader,
  TableContainer,
  Input,
  TextField,
  InputLabel, Select, MenuItem,
  Autocomplete,
  Popover,
  Grid,
  CardContent,
  Typography,
  CircularProgress
} from '@material-ui/core';
// utils
// import { fCurrency } from '../../../utils/formatNumber';
import { fCurrency } from '../../../../utils/formatNumber';
//
// import Label from '../../Label';
// import Scrollbar from '../../Scrollbar';
// import MoreMenuButton from '../../MoreMenuButton';
import Scrollbar from 'src/components/Scrollbar';
import Label from 'src/components/Label';
import { Link , useLocation } from 'react-router-dom';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import { MIconButton } from 'src/components/@material-extend';
import closeFill from '@iconify/icons-eva/close-fill';
import { useSnackbar } from 'notistack';
import { Form, FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import Close from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import { AnalyticsWebsiteVisits } from '../../general-analytics';
import { ChartArea, ChartColumnMultiple } from 'src/components/charts';
import SalesChart from './SalesChart';
import ItemTypeChart from './ItemTypeChart';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import axios from 'axios'
import { REST_API } from 'src/defaultValues';
import { Block } from 'src/pages/components-overview/Block';
import { DateRangePicker } from '@material-ui/lab';
import { position } from 'stylis';
import zIndex from '@material-ui/core/styles/zIndex';
// ----------------------------------------------------------------------



const randomDate = faker.date.past(); 
const INVOICES = [
  {
    id: faker.datatype.uuid(),
    itemName: 'DualShock 4 wireless controller for payStation 4-Fortnite jet lack',
    date:"29-feb-2024",
    price: faker.finance.amount(),
    status: 'Awaiting Payment',
    type:"physical",
    suplier:"Shein",
    customer:"Felix Chizzoba"

  },{
    id: faker.datatype.uuid(),
    itemName: 'DualShock 4 wireless controller for payStation 4-Fortnite jet lack',
    date:"13-feb-2024",
    price: faker.finance.amount(),
    status: 'Order Placed',
    type:"physical",
    suplier:"Shein",
    customer:"David Kelly"


  }
];

// ----------------------------------------------------------------------

export default function AdminDashboard() {

      // visitor status
  // const visitor="customer"

  const theme = useTheme();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [click, setCLick] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState('');
  const[selectedType,setSelectedType] =useState('')
  const [data,setData]=useState([])
  const [physicalItemCount, setPhysicalItemCount] = useState(0);
  const [digitalItemCount, setDigitalItemCount] = useState(0);
  const [customersCount,setCustomersCount]=useState(null)
  const [isLoading, setIsLoading] = useState(true);
  const [value, setValue] = useState([null, null]);
  const [value2, setValue2] = useState([null, null]);
  const [openCalender,setOpenCalender] = useState(false)
  const [openCalender2,setOpenCalender2] = useState(false)
  const [visible1,setVisible1] = useState(false)
  const [visible2,setVisible2] = useState(false)
 
  const handleCalender=()=>{
    setOpenCalender(!openCalender)
    setVisible1(!visible1)
   
  }

  const handleCalender2=()=>{
    setOpenCalender2(!openCalender2)
    setVisible2(!visible2)
    console.log("date",value)
    
  }
   

useEffect(()=>{
  axios.post(`${REST_API}webservice/admin/get-purchased-products-chart`)
  .then(res => {
    setData(res.data.data)
    console.log("sdfasdfsd",res.data.data)
  }).catch(err =>console.log(err))

  axios.get(`${REST_API}webservice/customer/findAll-customer`)
     .then(res => {
      console.log(res.data.data.length,"customers");
      setCustomersCount(res.data.data.length)
     })
     .catch(err => console.log(err))
     .finally(() => setIsLoading(false));
},[])

useEffect(()=>{
  const physicalCount = data.reduce((total, item) => {
    if (item.itemType.toLowerCase() === 'physical') {
      return total + 1;
    }
    return total;
  }, 0);

  const digitalCount = data.reduce((total, item) => {
    if (item.itemType.toLowerCase() === 'digital') {
      return total + 1;
    }
    return total;
  }, 0);

  setPhysicalItemCount(physicalCount);
  setDigitalItemCount(digitalCount);

},[data])



  const handleClick = (event) => {
    setCLick(event.currentTarget);
  };
  const handleClose = () => {
    setCLick(null);
    setSelectedStatus("")
  };
  
  const handleInvoice=()=> {
    enqueueSnackbar('invoice currently not available', {
      variant: 'warning',
      action: (key) => (
        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
          <Icon icon={closeFill} />
        </MIconButton>
      )
    });
  }
  const monthsInYear = [
    { month: 'All', monthNumber: 0 },
    { month: 'January', monthNumber: 1 },
    { month: 'February', monthNumber: 2 },
    { month: 'March', monthNumber: 3 },
    { month: 'April', monthNumber: 4 },
    { month: 'May', monthNumber: 5 },
    { month: 'June', monthNumber: 6 },
    { month: 'July', monthNumber: 7 },
    { month: 'August', monthNumber: 8 },
    { month: 'September', monthNumber: 9 },
    { month: 'October', monthNumber: 10 },
    { month: 'November', monthNumber: 11 },
    { month: 'December', monthNumber: 12 },
  ];
  
  
  const formik = useFormik({
    theStatus:null
  })
  const { values, errors, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;
  
  const [updateStatus,setUpdateStatus] = useState(false)

  const toggleStatus = (id) => {
    const item = INVOICES.find(item => item.id === id);
    if (item) {
      setUpdateStatus(id, !item.status); 
    } else {
      console.error(`Item with ID ${id} not found.`);
    }
  };
   const handleStatusChange = (event, newValue) => {
    setSelectedStatus(newValue); 
    

  };
  const handleTypeChange=(event, newValue)=>{
    setSelectedType(newValue)
  }
  
  const handleUpdate = () => {
    setCLick(null);

  };
  

  // if (isLoading) {
  //   return(
  //     <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
  //       <CircularProgress />
  //     </Box>
  
  //   ) 
  // }

  return (
    <>
     <Box sx={{ml:2,mt:5}}>
    {/* <Typography sx={{color:"#32b59f"}}>Order History</Typography> */}

    {/* <Typography sx={{color:"#32b59f"}}>Order History</Typography> */}
 
   </Box>
   <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardHeader title="Sales" sx={{position:"absolute"}}/>
            <Box sx={{display:"flex",justifyContent:"end",mt:{xs:6,sm:2}}}>
                <Button variant='contained' sx={{mr:2}} onClick={handleCalender}>{visible1? "Close" :"Period"}</Button>
               
                 

                <Autocomplete
                size='small'
                         disabled={visible1}
                          options={monthsInYear}
                          getOptionLabel={(option) => option.month}
                          renderInput={(params) => <TextField {...params} label="Select" />}
                          onChange={handleStatusChange} 
                          onClick={()=> setOpenCalender(false)}
                          sx={{width:"150px",mr:3}}
                        />
                     
            </Box>
            <CardContent dir="ltr">
                {/* <Typography sx={{mt:"40%",position:"absolute"}}>Value</Typography> */}
                {/* <Typography sx={{ position: 'absolute', top: '40%', left: '-2px', transform: 'rotate(-90deg)',color:"#bab5b5" }}>Value (&#8358;)</Typography> */}
             


               {
                openCalender ?

              
          <Block title="Period">
          <DateRangePicker
            startText="From"
            endText="Till"
            calendars={1}
            value={value}
            onChange={(newValue) => {
              // console.log(newValue,')))))))))))')
              // console.log(moment(newValue[0]).format('MM/DD/YYYY'),'____________moment')
              // console.log(moment(newValue[1]).format('MM/DD/YYYY'),'____________moment')
              const formattedDates = [
                moment(newValue[0]).format('MM/DD/YYYY'),
                moment(newValue[1]).format('MM/DD/YYYY')
              ];
              setValue(formattedDates);
            }}
            renderInput={(startProps, endProps) => (
              <>
                <TextField {...startProps} size='small'/>
                <Box sx={{ mx: 2 }}>to</Box>
                <TextField {...endProps} size='small'/>
              </>
            )}
          />
        </Block>
        :"" }

            <Typography sx={{ position: 'absolute', top: '50%', left: '-2px', transform: 'rotate(-90deg)',color:"#bab5b5" }}>Value ($)</Typography>
             {/* Sales chart ----------------------------------------------------------------------------- */}
              <SalesChart  selectedStatus={selectedStatus} value={value}/>
           
           
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <CardHeader title="Item Type" sx={{position:"absolute"}}/>
            <CardContent dir="ltr">
            <Box sx={{display:"flex",justifyContent:"end",mb:2, mt:{xs:3,sm:0}}}>
                <Button variant='contained' sx={{mr:2}} onClick={handleCalender2}>{visible2? "Close" :"Period"}</Button>
                <Autocomplete
                size='small'
                disabled={visible2}
                          options={monthsInYear}
                          getOptionLabel={(option) => option.month}
                          renderInput={(params) => <TextField {...params} label="Select" />}
                          // value={selectedStatus} 
                          onChange={handleTypeChange} 
                          
                          sx={{width:"150px"}}
                        />
            </Box>

            {
                openCalender2 ?

              
          <Block title="Period">
          {/* <Typography gutterBottom> 1 calendar </Typography> */}
          <DateRangePicker
            startText="From"
            endText="Till"
            calendars={1}
            value={value2}
            onChange={(newValue) => {
              // console.log(newValue,')))))))))))')
              // console.log(moment(newValue[0]).format('MM/DD/YYYY'),'____________moment')
              // console.log(moment(newValue[1]).format('MM/DD/YYYY'),'____________moment')
              const formattedDates = [
                moment(newValue[0]).format('MM/DD/YYYY'),
                moment(newValue[1]).format('MM/DD/YYYY')
              ];
              setValue2(formattedDates);
            }}
            renderInput={(startProps, endProps) => (
              <>
                <TextField {...startProps} size='small'/>
                <Box sx={{ mx: 2 }}>to</Box>
                <TextField {...endProps} size='small'/>
              </>
            )}
          />
        </Block>
        :"" }
           <Typography sx={{ position: 'absolute', top: '50%', left: '-2px', transform: 'rotate(-90deg)',color:"#bab5b5" }}>Value ($)</Typography>
                 {/* Item chart ----------------------------------------------------------------------------- */}
            <ItemTypeChart selectedType={selectedType} value2={value2}/>
            {/* <ChartArea /> */}
            </CardContent>
          </Card>
        </Grid>
    </Grid>
   <Grid container spacing={2} sx={{mt:2}}>
   <Grid item xs={12} md={6}>
     <Card sx={{p:3,boxShadow:4}}>
        <Typography>Registered User</Typography>
        <Box sx={{display:"flex",justifyContent:"center",alignItems:"center"}}>
            <PeopleAltIcon sx={{fontSize:"100px"}}/>
        </Box>
        <Typography align='right'>{customersCount? customersCount : ""}</Typography>
     </Card>
   </Grid>

   <Grid item xs={12} md={6} >
     <Card sx={{p:3,boxShadow:4}}>
        <Typography>Total Processed</Typography>
        <Box sx={{display:"flex",justifyContent:"center",alignItems:"center"}}>
        <Typography align='right' sx={{fontSize:"25px"}}>{physicalItemCount + digitalItemCount}</Typography>
        </Box>
        <Box sx={{display:"flex",justifyContent:"center"}}>
        <Box sx={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"start",mr:3,mt:5}}>
        <Typography align='right'>Physical Goods</Typography>
        <Typography ml={5}>{physicalItemCount}</Typography>
        </Box>

        <Box sx={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"start",mt:5}}>
        <Typography align='right'>Digital Goods</Typography>
        <Typography ml={5}>{digitalItemCount}</Typography>
        </Box>

        </Box>
     </Card>
   </Grid>

   </Grid>
  </>
  );
}
