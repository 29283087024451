import faker from 'faker'
import { Icon } from '@iconify/react'
import { sentenceCase } from 'change-case'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
// material
import { useTheme } from '@material-ui/core/styles'
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill'
import {
  Box,
  Card,
  Table,
  Button,
  Divider,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CardHeader,
  TableContainer,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Stack,
  Container,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogActions,
} from '@material-ui/core'
// utils
import { fCurrency } from '../../../../utils/formatNumber'
//
import Label from '../../../Label'
import Scrollbar from '../../../Scrollbar'
import MoreMenuButton from '../../../MoreMenuButton'
import { Link, useLocation } from 'react-router-dom'
import { PATH_DASHBOARD } from '../../../../routes/paths'
import SearchIcon from '@mui/icons-material/Search'
import InputAdornment from '@material-ui/core/InputAdornment'
import { useFormik, Form, FormikProvider } from 'formik'
import * as Yup from 'yup'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { REST_API } from 'src/defaultValues'
import { LoadingButton } from '@material-ui/lab'
import { useSnackbar } from 'notistack'
import { MIconButton } from 'src/components/@material-extend'
import closeFill from '@iconify/icons-eva/close-fill'
import { mapValues } from 'lodash'
// ----------------------------------------------------------------------

const randomDate = faker.date.past()
const INVOICES = [
  {
    id: 1,
    subtotalRange: '$ 51 - $100',
    commission: '3%',
    country: 'USA',
    weightType: 'Actual Weight',
  },
  {
    id: 2,
    subtotalRange: '$ 0 - $50',
    commission: '2%',
    country: 'China',
    weightType: 'Volumetric Weight',
  },
]

// ----------------------------------------------------------------------

export default function ForwarderFeeAdd({
  setUpdatePage,
  update,
  itemId,
  setRefresh,
}) {
  const theme = useTheme()
  const navigate = useNavigate()
  const [data, setData] = useState('')
  const [countries, setCountries] = useState([])
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  useEffect(() => {
    axios
      .get(`${REST_API}webservice/admin/get-country`)
      .then((res) => {
        // console.log('get country', res.data)
        setCountries(res.data.data)
      })
      .catch((err) => console.log(err))
    update
      ? axios
          .get(`${REST_API}webservice/admin/get-forwarder-fee/${itemId}`)
          .then((res) => {
            if (res.data.status === 1) {
              // console.log("get-forwarder-fee",res.data.result)
              setData(res.data.result)
            } else {
              console.log('not getting data', res.data.status)
            }
          })
          .catch((err) => console.log(err))
      : ''
  }, [])

  const RegisterSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('title is required'),
    setWeightFrom: Yup.number()
      .typeError('Set weight from must be a number')
      .required('Set weight from is required')
      .min(0, 'Set weight from must be greater than or equal to 0'),
    // setWeightTo: Yup.number()
    //   .typeError('Set Weight to must be a number').nullable()
    //   // .required('Set Weight to is required')
    //   .min(
    //     Yup.ref('setWeightFrom'),
    //     'Set Weight to must be greater than Set Weight from',
    //   )
    //   .test(
    //     'greaterThan',
    //     'Set Weight to must be greater than Set Weight from',
    //     function (value) {
    //       return value > this.parent.setWeightFrom
    //     },
    //   ),
    setWeightTo: Yup.number()
      .nullable()
      .when('setWeightFrom', (setWeightFrom, schema) => {
        return schema
          .typeError('Set weight to must be a number')
          .nullable()
          .min(
            setWeightFrom,
            'Set weight to must be greater than Set weight from',
          )
          .test(
            'greater-than-from',
            'Set weight to must be greater than Set weight from',
            function (value) {
              return value > setWeightFrom
            },
          )
          .test(
            'not-negative',
            'Set weight to must not be negative',
            function (value) {
              return value >= 0
            },
          )
      }),

    weightType: Yup.string().required('weightType is required'),
    // country: Yup.string().required('country is required'),
    country: Yup.object().nullable().required('country is required'),
    // fee: Yup.number().min(1, 'Rate must be greater than or equal 1').nullable(),
    // fixedFee: Yup.number()
    //   .min(1, 'Fixed Fee must be greater than or equal 1').nullable(),
  })
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: data ? data.name : '',
      country: data
        ? countries?.find((option) => option.id == parseInt(data?.country))
        : null,
      setWeightFrom: data ? data.setWeightFrom : '',
      setWeightTo: data ? data.setWeightTo : '',
      weightType: data ? data.weightType : '',
      fee: data ? data.fee : null,
      fixedFee: data ? data.fixedFee : null,
    },
    validationSchema: RegisterSchema,

    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        console.log('Form data:', values)
        values.country = values?.country?.id

        // await register(values.email, values.password, values.Name, values.phoneNumber);
        let response
        {
          console.log("values",values)

          update
            ? (response = await axios
                .put(
                  `${REST_API}webservice/admin/update-forwarder-fee/${itemId}`,
                  { values },
                )
                
                .then((res) => {
                  console.log("res.data",res.data)
                  if (res.data.status === 1) {
                    setUpdatePage(false)
                    setRefresh(true)
                    enqueueSnackbar('Forwarder fee Updated successfully', {
                      variant: 'success',
                      action: (key) => (
                        <MIconButton
                          size="small"
                          onClick={() => closeSnackbar(key)}
                        >
                          <Icon icon={closeFill} />
                        </MIconButton>
                      ),
                    })
                  } else {
                    enqueueSnackbar('Failed to update forwarder fee', {
                      variant: 'error',
                    })
                  }
                })
                .catch((err) => console.log(err)))
            : (response = await axios
                .post(`${REST_API}webservice/admin/add-forwarder-fee-rate`, {
                  values,
                })
                .then((res) => {
                  navigate(PATH_DASHBOARD.settings.forwarderFee)
                  // console.log(res.data)
                  if (res.data.status === 1) {
                    enqueueSnackbar('Forwarder Fee added successfully', {
                      variant: 'success',
                      action: (key) => (
                        <MIconButton
                          size="small"
                          onClick={() => closeSnackbar(key)}
                        >
                          <Icon icon={closeFill} />
                        </MIconButton>
                      ),
                    })
                  } else {
                    enqueueSnackbar('Failed to add forwarder fee', {
                      variant: 'error',
                    })
                  }
                })
                .catch((err) => {
                  console.log(err)
                }))
        }

        // if (isMountedRef.current) {
        setSubmitting(false)
        //   navigate('/');
        // navigate(PATH_DASHBOARD.staffDetails.root);
        // }
      } catch (error) {
        console.error(error)
        // if (isMountedRef.current) {
        setErrors({ afterSubmit: error.message })
        setSubmitting(false)
        // }
      }
    },
  })
  // console.log('countrysssssssssss', countries)
  const {
    errors,
    values,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
  } = formik

  const handleBack = () => {
    navigate(PATH_DASHBOARD.settings.forwarderFee)
    update ? setUpdatePage(false) : ''
  }
  return (
    <>
      <Box sx={{ m: 2 }}>
        {update ? (
          <Typography sx={{ color: '#32b59f', fontSize: 'small' }}>
            Settings / Forwarder Fee / Update
          </Typography>
        ) : (
          <Typography sx={{ color: '#32b59f', fontSize: 'small' }}>
            Settings / Forwarder Fee / Add
          </Typography>
        )}
      </Box>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Card>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <CardHeader
                title={
                  update ? 'Edit New Forwarder Fee' : 'Add New Forwarder Fee'
                }
                sx={{ mb: 1, fontSize: 'small' }}
              />
              <Button
                sx={{
                  mr: 3,
                  mt: 3,
                  height: '40px',
                  color: '#fff',
                  backgroundColor: '#32b59f',
                  '&:hover': {
                    backgroundColor: '#32b59f',
                  },
                }}
                onClick={handleBack}
              >
                Back
              </Button>
            </Box>
            <Divider sx={{ mt: 3 }} />
            <Container>
              <Box sx={{ width: '100%', p: 3 }}>
                <Box>
                  {/* <TextField variant="outlined" disabled placeholder='Search ...'/> */}

                  <Stack spacing={{ xs: 2, md: 3 }} sx={{ mt: 3 }}>
                    <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Title"
                        placeholder="Enter Title"
                        defaultValue={update ? 'title' : ''}
                        {...getFieldProps('name')}
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                      />

                      <Autocomplete
                        select
                        fullWidth
                        options={countries}
                        getOptionLabel={(option) => option.country}
                        value={
                          countries?.find(
                            (option) => option?.id === values?.country?.id,
                          ) || null
                        }
                        onChange={(event, newValue) => {
                          if (newValue) {
                            setFieldValue('country', newValue)
                          } else {
                            setFieldValue('country', '')
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select Country"
                            error={Boolean(touched.country && errors.country)}
                            helperText={touched.country && errors.country}
                          />
                        )}
                      />
                    </Stack>
                    <Stack
                      direction={{ xs: 'column', md: 'row' }}
                      spacing={2}
                      sx={{ mt: 3 }}
                    >
                      <Box>
                        <Typography>Set Weight Range</Typography>
                        <Stack
                          direction={{ xs: 'column', md: 'row' }}
                          spacing={2}
                          sx={{ mt: 1 }}
                        >
                          <TextField
                            type="number"
                            fullWidth
                            variant="outlined"
                            placeholder=" 0"
                            defaultValue={update ? 'From' : ''}
                            label="From"
                            {...getFieldProps('setWeightFrom')}
                            error={Boolean(
                              touched.setWeightFrom && errors.setWeightFrom,
                            )}
                            helperText={
                              touched.setWeightFrom && errors.setWeightFrom
                            }
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  lb
                                </InputAdornment>
                              ),
                            }}
                          />
                          <TextField
                            type="number"
                            fullWidth
                            variant="outlined"
                            defaultValue={update ? 'To' : ''}
                            placeholder=" 0"
                            label="To"
                            {...getFieldProps('setWeightTo')}
                            error={Boolean(
                              touched.setWeightTo && errors.setWeightTo,
                            )}
                            helperText={
                              touched.setWeightTo && errors.setWeightTo
                            }
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  lb
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Stack>
                      </Box>

                      <Box sx={{ width: '100%' }}>
                        {/* <Typography>Weight Type</Typography> */}
                        <Stack
                          direction={{ xs: 'column', md: 'row' }}
                          spacing={2}
                          sx={{ mt: 4 }}
                        >
                          <Autocomplete
                            select
                            fullWidth
                            options={INVOICES}
                            getOptionLabel={(option) => option.weightType}
                            value={
                              INVOICES?.find(
                                (option) =>
                                  option.weightType == values.weightType,
                              ) || null
                            }
                            onChange={(event, newValue) => {
                              // console.log('newValue---',newValue);
                              if (newValue) {
                                const selectedOption = INVOICES?.find(
                                  (option) =>
                                    option.weightType == newValue.weightType,
                                )
                                // console.log('selectedOption---',selectedOption);
                                if (selectedOption) {
                                  setFieldValue(
                                    'weightType',
                                    selectedOption.weightType,
                                  )
                                } else {
                                  setFieldValue('weightType', '')
                                }
                              } else {
                                setFieldValue('weightType', '')
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Select weightType"
                                error={Boolean(
                                  touched.weightType && errors.weightType,
                                )}
                                helperText={
                                  touched.weightType && errors.weightType
                                }
                              />
                            )}
                          />
                        </Stack>

                        <Stack
                          direction={{ xs: 'column', md: 'row' }}
                          spacing={2}
                          sx={{ mt: 3 }}
                        >

{
                          !values.fixedFee ?
                          <TextField
                            type="number"
                            fullWidth
                            variant="outlined"
                            // defaultValue={update ? 'Rate' : ''}
                            placeholder="0"
                            label="Rate ($/lb)"
                            {...getFieldProps('fee')}
                            error={Boolean(touched.fee && errors.fee)}
                            helperText={touched.fee && errors.fee}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              ),
                            }}
                          />
                          :"" }

{
                          !values.fee ?

                          <TextField
                        
                            type="number"
                            fullWidth
                            variant="outlined"
                            // defaultValue={update ? 'fixedFee' : ''}
                            placeholder="0"
                            label="Fixed Fee"
                            {...getFieldProps('fixedFee')}
                            error={Boolean(touched.fixedFee && errors.fixedFee)}
                            helperText={touched.fixedFee && errors.fixedFee}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              ),
                            }}
                          />
                          :""}
                        </Stack>
                      </Box>
                    </Stack>
                  </Stack>
                </Box>
              </Box>
              <Divider />
              <Box sx={{ display: 'flex', justifyContent: 'end', mb: 3 }}>
                <Button
                  sx={{
                    mr: 3,
                    mt: 3,
                    height: '40px',
                    color: '#fff',
                    backgroundColor: '#32b59f',
                    '&:hover': { backgroundColor: '#32b59f' },
                  }}
                  onClick={handleBack}
                >
                  Cancel
                </Button>
                <LoadingButton
                  loading={isSubmitting}
                  type="submit"
                  sx={{
                    mr: 3,
                    mt: 3,
                    height: '40px',
                    color: '#fff',
                    backgroundColor: '#32b59f',
                    '&:hover': { backgroundColor: '#32b59f' },
                  }}
                >
                  {update ? 'Update' : 'Save'}
                </LoadingButton>
              </Box>
            </Container>
          </Card>
        </Form>
      </FormikProvider>
    </>
  )
}
