import faker from 'faker'
import { Icon } from '@iconify/react'
import { sentenceCase } from 'change-case'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
// material
import { useTheme } from '@material-ui/core/styles'
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill'
import {
  Box,
  Card,
  Table,
  Button,
  Divider,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CardHeader,
  TableContainer,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Dialog, DialogTitle, DialogActions,
  TablePagination,
  Grid,
} from '@material-ui/core'
// utils
import { fCurrency } from '../../../../utils/formatNumber'
//
import Label from '../../../Label'
import Scrollbar from '../../../Scrollbar'
import MoreMenuButton from '../../../MoreMenuButton'
import { Link, useLocation } from 'react-router-dom'
import { PATH_DASHBOARD } from '../../../../routes/paths'
import SearchIcon from '@mui/icons-material/Search'
import InputAdornment from '@material-ui/core/InputAdornment'
import { useEffect, useState } from 'react'
import ForwarderFeeAdd from './ForwarderFeeAdd'
import axios from 'axios'
import { REST_API } from 'src/defaultValues'
import { useSnackbar } from 'notistack';
import { MIconButton, MLinearProgress } from 'src/components/@material-extend'
import closeFill from '@iconify/icons-eva/close-fill';
import LoadingScreen from 'src/components/LoadingScreen'
// ----------------------------------------------------------------------

const randomDate = faker.date.past()
const INVOICES = [
  {
    id: 1,
    subtotalRange: '$ 51 - $100',
    commission: '3%',
    country: 'china',
    weightItem: '25-50',
    weightType: 'Actual Weight',
    forwarderFee: '$ 12',
  },
  {
    id: 2,
    subtotalRange: '$ 0 - $50',
    commission: '2%',
    country: 'china',
    weightItem: '0-25',
    weightType: 'Volumetric Weight',
    forwarderFee: '$ 12',
  },
  {
    id: 1,
    subtotalRange: '$ 51 - $100',
    commission: '3%',
    country: 'china',
    weightItem: '25-50',
    weightType: 'Actual Weight',
    forwarderFee: '$ 15',
  },
  {
    id: 2,
    subtotalRange: '$ 0 - $50',
    commission: '2%',
    country: 'china',
    weightItem: '0-25',
    weightType: 'Volumetric Weight',
    forwarderFee: '$ 8',
  },
]

// ----------------------------------------------------------------------

export default function ForwarderFee() {
  const theme = useTheme()
  const navigate = useNavigate()
  const { enqueueSnackbar,closeSnackbar } = useSnackbar()
  const [updatePage, setUpdatePage] = useState(false)
  const [itemId, setItemId] = useState('')
  const [data, setData] = useState([])
  const [open, setOpen] = useState(false);
  const[refresh,setRefresh]= useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  // console.log("#####Refresh",refresh);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5); // You can change the default rows per page if needed
  const [isLoading, setIsLoading] = useState(true);
 
  // Your existing filteredData and navigate function

  function formatMoney(amount) {
    // Split the amount into integer and decimal parts
    const parts = amount.toString().split('.');
    const integerPart = parts[0];
    const decimalPart = parts.length > 1 ? '.' + parts[1] : '';
  
    // Add commas every three digits from the right
    const integerWithCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  
    // Concatenate integer and decimal parts and return
    return integerWithCommas + decimalPart;
  }
  
 
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
 
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickOpen = (id) => {
    setOpen(true);
    setItemId(id)
  };

  const handleClose = () => {
    setOpen(false);
    
  };

  let filteredData = data;

  if (searchQuery) {
    filteredData = data.filter((row) => {
      // Convert the commission and search query to lowercase for case-insensitive comparison
      const commission = row.country_name.toLowerCase().trim();
      const searchQueryTrimmed = searchQuery.trim().toLowerCase();
  
      console.log("filteredData", filteredData);
      console.log("commission", commission);
      console.log("searchQuery", searchQueryTrimmed);
  
      return commission.includes(searchQueryTrimmed);
    });
  }



  useEffect(() => {
    axios
      .get(`${REST_API}webservice/admin/getAll-forwarder-fee`)
      .then((res) => {
        if(res.data.status===1) {
          console.log("getAll-forwarder-fee",res.data.data)
          console.log("getAll-forwarder-fee",refresh)
          setData(res.data.data)
          setRefresh(false)
        }else {
          setRefresh(false)
          console.log("not getting forwarder fee");
        }
      
       
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setTimeout(() => {
          setIsLoading(false);
        }, 2000); 
      })
     
  }, [refresh])

  const handleAdd = () => {
    navigate(PATH_DASHBOARD.settings.forwarderFeeAdd)
  }
  const handleUpdate = (id) => {
    setUpdatePage(true)
    setItemId(id)
  }
  const handleDelete = (id) => {
    axios
      .delete(`${REST_API}webservice/admin/delete-forwarder-fee/${id}`)
      .then((res) => {
        window.location.reload()
        console.log(res.data)
        enqueueSnackbar('Forwarder Fee Deleted successfully', {
          variant: 'success',
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          ),
      })
      })
      .catch((err) => console.log(err))
  }

  if (isLoading) {
    return(
      <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <LoadingScreen />
    </Box>
  
    ) 
  }

  return updatePage ? (
    <ForwarderFeeAdd
      setUpdatePage={setUpdatePage}
      update={true}
      itemId={itemId}
      setRefresh={setRefresh}
    />
  ) : (
    <>
      <Box sx={{ m: 2 }}>
        <Typography sx={{ color: '#32b59f', fontSize: 'small' }}>
          Settings / Forwarder Fee
        </Typography>
      </Box>
      <Card>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <CardHeader title="All" sx={{ mb: 1 }} />
          <Button
            sx={{
              mr: 3,
              mt: 3,
              height: '40px',
              color: '#fff',
              backgroundColor: '#32b59f',
              '&:hover': {
                backgroundColor: '#32b59f',
              },
            }}
            onClick={handleAdd}
          >
            Add
          </Button>
        </Box>

        <Box sx={{ width: '100%', p: 3 }}>
          <Box>
            {/* <TextField variant="outlined" disabled placeholder='Search ...'/> */}

            <TextField
             size='small'
              variant="outlined"
              placeholder="Search by Country..."
              sx={{ backgroundColor: "whitesmoke" }}
              onChange={(e) => setSearchQuery(e.target.value)}
              InputProps={{
            endAdornment: (
      <InputAdornment position="end">
        <SearchIcon />
      </InputAdornment>
    ),
  }}
/>
          </Box>
        </Box>

        <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Do you want to Delete the Forwarder fee</DialogTitle>
        
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={()=>handleDelete(itemId)} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
            


        <Scrollbar>
          <TableContainer sx={{ minWidth: 720 }}>
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Country</TableCell>
                  <TableCell align="center">Weight of Item (lb)</TableCell>
                  <TableCell>Weight Type</TableCell>
                  <TableCell align="center">Rate ($/lb)</TableCell>
                  <TableCell>Fixed Fee ($)</TableCell>
                  <TableCell></TableCell>

                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
              {filteredData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                  <TableRow key={row.id} sx={{borderBottom:'1px solid #e6e6e6',m:0,p:0}}>
                    <TableCell align="center">{row.country_name}</TableCell>
                    <TableCell align="center">
                     {row.setWeightTo===0 ?`${row.setWeightFrom} and above` :`${row.setWeightFrom} - ${row.setWeightTo} `} 
                    </TableCell>
                    <TableCell>{row.weightType}</TableCell>
                    <TableCell align="center">$ {row.fee? formatMoney(row.fee) :"NA"}</TableCell>

                    <TableCell>$ {row.fixedFee ?formatMoney(row.fixedFee) :"NA"}</TableCell>
                   

                    <TableCell align="right">
                      {/* <MoreMenuButton /> */}
                      {/* <Link underline="none" component={RouterLink} to={PATH_DASHBOARD.user.product}> */}
                      <Button
                     
                        sx={{
                        
                          
                          
                          color: '#fff',
                          fontSize: 'small',
                          backgroundColor: '#32b59f',
                          '&:hover': {
                            backgroundColor: '#32b59f',
                          },
                        }}
                        className='css-3jmj6j-MuiButtonBase-root-MuiButton-root1'
                        onClick={() => handleUpdate(row.id)}
                      >
                        Edit
                      </Button>

                      </TableCell>
                      <TableCell align="right">
                      <Button
                        sx={{
                          mr: 3,
                         
                       
                          color: '#fff',
                          backgroundColor: '#be4c4c',
                          '&:hover': {
                            backgroundColor: '#be4c4c',
                          },
                        }}
                        className='css-3jmj6j-MuiButtonBase-root-MuiButton-root1'
                        onClick={() => handleClickOpen(row.id)}
                      >
                        Delete
                      </Button>
                      {/* </Link> */}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        </Scrollbar>

        <Divider />

        <Box sx={{ p: 2, textAlign: 'right' }}>
          <Button
            to="#"
            size="small"
            color="inherit"
            component={RouterLink}
            endIcon={<Icon icon={arrowIosForwardFill} />}
          >
            View All
          </Button>
        </Box>
      </Card>
    </>
  )
}
