import faker from 'faker';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { useTheme } from '@material-ui/core/styles';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import {
  Box,
  Card,
  Table,
  Button,
  Divider,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CardHeader,
  TableContainer,
  TextField,
  InputLabel, Select, MenuItem, Typography, Stack, Container,
  Autocomplete

} from '@material-ui/core';
// utils
import { fCurrency } from '../../../../utils/formatNumber';
//
import Label from '../../../Label';
import Scrollbar from '../../../Scrollbar';
import MoreMenuButton from '../../../MoreMenuButton';
import { Link , useLocation } from 'react-router-dom';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useFormik, Form, FormikProvider } from 'formik'
import { useEffect, useState } from 'react';
import * as Yup from 'yup'
import { LoadingButton } from '@material-ui/lab'
import axios from 'axios'
import { REST_API } from 'src/defaultValues';
import { useSnackbar } from 'notistack';
import { MIconButton } from 'src/components/@material-extend';
import closeFill from '@iconify/icons-eva/close-fill';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
// ----------------------------------------------------------------------


const randomDate = faker.date.past(); 
const INVOICES = [
  {
    id:1,
    subtotalRange:"$ 51 - $100",
    percentage:'3%',
    country:"India"
  },{
    id:2,
    subtotalRange:"$ 0 - $50",
    percentage:'2%',
    country:"China"
  },{
    id:2,
    subtotalRange:"$ 0 - $50",
    percentage:'2%',
    country:"USA"
  },

];

// ----------------------------------------------------------------------

export default function SupplierCountryAdd({setUpdateCountryPage,update,itemId,setRefresh}) {
  const theme = useTheme();
  const { enqueueSnackbar,closeSnackbar } = useSnackbar()
  const navigate=useNavigate()
  const [data,setData]=useState([])

  useEffect(() => {
    if(itemId){
      axios
        .get(`${REST_API}webservice/admin/get-country/${itemId}`)
        .then((res) => {
          console.log("edit data - ",res.data.result)
          setData(res.data.result)
        })
        .catch((err) => console.log(err))
    }
  }, [itemId])


  const handleBack=()=> {
    navigate(PATH_DASHBOARD.settings.supplierCountry)
    setUpdateCountryPage?
    setUpdateCountryPage(false) : ""
  }
  const RegisterSchema = Yup.object().shape({
    country: Yup.string().required('Country is required'),
    salesTax: Yup.number().required('Sales Tax is required').min(0, 'Sales Tax cannot be negative'),
    edd:Yup.number().required('EDD is required').min(0, 'EDD cannot be negative')
    ,
  })
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      country: data ? data?.country : '',
      salesTax: data ? data?.salesTax :'',
      edd:data? data?.edd :''
    },
    validationSchema: RegisterSchema,
    
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      
      try {
        console.log('Form data:', values)
        // await register(values.email, values.password, values.Name, values.phoneNumber);
     let response;
       {update ? 
        response =  await axios
        .put(`${REST_API}webservice/admin/update-country/${itemId}`, {values})
        .then(res =>{
          console.log(res.data)
          if(res.data.status === 1){
            setUpdateCountryPage(false)
          setRefresh(true)
            enqueueSnackbar('Country Updated  successfully', {
              variant: 'success',
              action: (key) => (
                <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                  <Icon icon={closeFill} />
                </MIconButton>
              ),
            })
          }else{
            enqueueSnackbar('Failed to update country', { variant: 'error' })
          }
        })
        .catch(err => console.log(err))
      :
      response=await axios.post(`${REST_API}webservice/admin/add-country`,{values}) 
      .then((res) => {
        navigate(PATH_DASHBOARD.settings.supplierCountry)
        console.log(res.data)
        if(res.data.status === 1){
          enqueueSnackbar('Country added successfully', {
            variant: 'success',
            action: (key) => (
              <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                <Icon icon={closeFill} />
              </MIconButton>
            ),
          })
        }else{
          enqueueSnackbar('Failed to add country', { variant: 'error' })
        }
      })
      .catch((err) => {
        console.log(err)
      })
    }
    // response=await axios.post(`${REST_API}webservice/admin/add-country`,{values}) 
    // .then(res => {
    //   console.log(res.data)
    //   navigate(PATH_DASHBOARD.settings.supplierCountry)
    // }).catch(err => console.log(err))
      
       
        // if (isMountedRef.current) {
          setSubmitting(false)
          //   navigate('/');
          // navigate(PATH_DASHBOARD.staffDetails.root);
        // }
      } catch (error) {
        console.error(error)
        // if (isMountedRef.current) {
          setErrors({ afterSubmit: error.message })
          setSubmitting(false)
        // }
      }
    },
  })
  
  const { errors,values, touched, handleSubmit, isSubmitting, getFieldProps,setFieldValue } = formik
  
  return (
    <>
    <Box sx={{m:2}}>
      {update ?
    <Typography sx={{color:"#32b59f",fontSize:"small"}}>Settings / Supplier Country / Update</Typography>
      :
    <Typography sx={{color:"#32b59f",fontSize:"small"}}>Settings / Supplier Country / Add</Typography>
      }

   </Box>
    <Card>  
    <FormikProvider value={formik}>
    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
      <Box sx={{display:"flex",justifyContent:"space-between"}}>
      <CardHeader title={update ?"Update Country":"Add New Country"} sx={{ mb: 1,fontSize:"small" }} />
      <Button
      sx={{
        mr:3,
        mt:3,
        height:"40px",
        color:"#fff",
        backgroundColor: "#32b59f",
        '&:hover': {
          backgroundColor: "#32b59f", 
        },
      }} onClick={handleBack}
    > 
     Back 
    </Button>
        </Box>
      <Divider sx={{mt:3}}/>
       <Container>
      <Box sx={{width:"100%",p:3}}>
        <Box>
          {/* <TextField variant="outlined" disabled placeholder='Search ...'/> */}

             <Stack spacing={{ xs: 2, md: 3 }} sx={{mt:3}}>
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
               
            
                        <TextField  fullWidth variant="outlined"  placeholder='Enter the Country Name' label="Country"
                          {...getFieldProps('country')}
                          error={Boolean(touched.country && errors.country)}
                          helperText={touched.country && errors.country}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <TravelExploreIcon/>
                              </InputAdornment>
                            )
                          }}/>

                    </Stack>
                 
                    <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                    <Box sx={{ width: '100%' }}>
                        <Typography>Sales Tax</Typography>
                        <Stack
                          direction={{ xs: 'column', md: 'row' }}
                          spacing={2}
                          sx={{ mt: 1 }}
                        >
                        

                         <TextField 
                            fullWidth
                            defaultValue={update?"Enter percentage":""}
                            variant="outlined"
                            placeholder='Enter Sales Tax'
                            type='number'
                            {...getFieldProps('salesTax')}
                            error={Boolean(touched.salesTax && errors.salesTax)}
                            helperText={touched.salesTax && errors.salesTax}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  %
                                </InputAdornment>
                              )
                            }}
                          />
                        </Stack>
                      </Box>

                      <Box sx={{ width: '100%' }}>
                        <Typography>EDD</Typography>
                        <Stack
                          direction={{ xs: 'column', md: 'row' }}
                          spacing={2}
                          sx={{ mt: 1 }}
                        >
                        

                         <TextField 
                            fullWidth
                            variant="outlined"
                            placeholder='Enter the No.of Days'
                            type='number'
                            {...getFieldProps('edd')}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <CalendarMonthIcon/>
                                </InputAdornment>
                              ),
                              endAdornment:(
                                <InputAdornment position="start">
                               Days
                              </InputAdornment>
                              )
                            }}
                            error={Boolean(touched.edd && errors.edd)}
                            helperText={touched.edd && errors.edd}
                          />
                        </Stack>
                      </Box>

                      </Stack>

                    </Stack>
        </Box>
       
      </Box>
      <Divider sx={{mt:3}}/>
          <Box sx={{display:"flex",justifyContent:"end",mb:3}}>
          <Button 
      sx={{mr:3,mt:3,height:"40px",color:"#fff",backgroundColor:"#32b59f",'&:hover': {backgroundColor: "#32b59f"}}}
    onClick={handleBack}>
      Cancel 
    </Button>
    <LoadingButton
                  loading={isSubmitting}
                  type="submit"
                  sx={{
                    mr: 3,
                    mt: 3,
                    height: '40px',
                    color: '#fff',
                    backgroundColor: '#32b59f',
                    '&:hover': { backgroundColor: '#32b59f' },
                  }}
                >
                  {update ?"Update": "Save"}
                  
                </LoadingButton>
          </Box>
      </Container>
      </Form>
      </FormikProvider>
    </Card>
    </>

  );
}
