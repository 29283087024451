// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  kanban: getIcon('ic_kanban'),
  home: getIcon('ic_home'),
  customer:getIcon('ic_profile'),
  staff:getIcon('ic_staff'),
  settings:getIcon('ic_settings')
  
};
const visitor="staff"




const sidebarConfig =  [
  // GENERAL
  // ----------------------------------------------------------------------
 
  {
    // subheader: 'general',
   
    subheader: '',
    items: [
      {
        title: 'Home',
        path: PATH_DASHBOARD.general.app,
        icon: ICONS.home
      },
      // { title: 'Order History', path: PATH_DASHBOARD.orderHistory.root, icon: ICONS.ecommerce },
      { title: 'Order History', path: PATH_DASHBOARD.orderHistory.productOrderHistory, icon: ICONS.ecommerce },
        { title: 'Customers', path: PATH_DASHBOARD.customerDetails.root, icon: ICONS.customer },
        { title: 'Staff', path: PATH_DASHBOARD.staffDetails.root, icon: ICONS.staff },
      { title: 'Notifications', path: PATH_DASHBOARD.notification.root, icon:  ICONS.analytics },
      // { title: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
      {
        title: 'Settings',
        path: PATH_DASHBOARD.settings.root,
        icon: ICONS.settings,
        children: [
          { title: 'Service Fee', path: PATH_DASHBOARD.settings.serviceFee },
          { title: 'Forwarder Fee Rate', path: PATH_DASHBOARD.settings.forwarderFee },
          { title: 'VAT on Service Charge', path: PATH_DASHBOARD.settings.vat },
          { title: 'Supplier Country', path: PATH_DASHBOARD.settings.supplierCountry },
          { title: 'Pickup Locations', path: PATH_DASHBOARD.settings.pickupLocation },
          { title: 'Exchange Rate', path: PATH_DASHBOARD.settings.exchangeRate }
        ]
      }
      // {
      //     title: 'e-commerce',
      //     path: PATH_DASHBOARD.eCommerce.root,
      //     icon: ICONS.cart,
      //     children: [
      //       { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
      //       { title: 'product', path: PATH_DASHBOARD.eCommerce.productById },
      //       { title: 'list', path: PATH_DASHBOARD.eCommerce.list },
      //       { title: 'create', path: PATH_DASHBOARD.eCommerce.newProduct },
      //       { title: 'edit', path: PATH_DASHBOARD.eCommerce.editById },
      //       { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
      //       { title: 'invoice', path: PATH_DASHBOARD.eCommerce.invoice }
      //     ]
      //   },
    ]
  }]
  
  
  
  export default sidebarConfig;

  // MANAGEMENT
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'management',
  //   items: [
  //     // MANAGEMENT : USER
  //     {
  //       title: 'user',
  //       path: PATH_DASHBOARD.user.root,
  //       icon: ICONS.user,
  //       children: [
  //         { title: 'profile', path: PATH_DASHBOARD.user.profile },
  //         { title: 'cards', path: PATH_DASHBOARD.user.cards },
  //         { title: 'list', path: PATH_DASHBOARD.user.list },
  //         { title: 'create', path: PATH_DASHBOARD.user.newUser },
  //         { title: 'edit', path: PATH_DASHBOARD.user.editById },
  //         { title: 'account', path: PATH_DASHBOARD.user.account }
  //       ]
  //     },]
  //   }

      // MANAGEMENT : E-COMMERCE
      // {
      //   title: 'e-commerce',
      //   path: PATH_DASHBOARD.eCommerce.root,
      //   icon: ICONS.cart,
      //   children: [
      //     { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
      //     { title: 'product', path: PATH_DASHBOARD.eCommerce.productById },
      //     { title: 'list', path: PATH_DASHBOARD.eCommerce.list },
      //     { title: 'create', path: PATH_DASHBOARD.eCommerce.newProduct },
      //     { title: 'edit', path: PATH_DASHBOARD.eCommerce.editById },
      //     { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
      //     { title: 'invoice', path: PATH_DASHBOARD.eCommerce.invoice }
      //   ]
      // },

      // MANAGEMENT : BLOG
  //     {
  //       title: 'blog',
  //       path: PATH_DASHBOARD.blog.root,
  //       icon: ICONS.blog,
  //       children: [
  //         { title: 'posts', path: PATH_DASHBOARD.blog.posts },
  //         { title: 'post', path: PATH_DASHBOARD.blog.postById },
  //         { title: 'new post', path: PATH_DASHBOARD.blog.newPost }
  //       ]
  //     }
  //   ]
  // },

  // APP
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'app',
  //   items: [
  //     { title: 'mail', path: PATH_DASHBOARD.mail.root, icon: ICONS.mail },
  //     { title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
  //     { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
  //     { title: 'kanban', path: PATH_DASHBOARD.kanban, icon: ICONS.kanban }
  //   ]
  // }
// ];






// // routes
// import { PATH_DASHBOARD } from '../../routes/paths';
// // components
// import SvgIconStyle from '../../components/SvgIconStyle';

// // ----------------------------------------------------------------------

// const getIcon = (name) => (
//   <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
// );

// const ICONS = {
//   blog: getIcon('ic_blog'),
//   cart: getIcon('ic_cart'),
//   chat: getIcon('ic_chat'),
//   mail: getIcon('ic_mail'),
//   user: getIcon('ic_user'),
//   calendar: getIcon('ic_calendar'),
//   ecommerce: getIcon('ic_ecommerce'),
//   analytics: getIcon('ic_analytics'),
//   dashboard: getIcon('ic_dashboard'),
//   kanban: getIcon('ic_kanban'),
//   home: getIcon('ic_home'),
// };

// // const visitor = "stafff";

// let sidebarConfig = [];

// // Conditionally populate sidebar items based on visitor type
// if (visitor === "staff") {
//   sidebarConfig = [
//     {
//       subheader: '',
//       items: [




//         {
//           title: 'Home',
//           path: PATH_DASHBOARD.general.app,
//           icon: ICONS.home
//         },
//         { title: 'Order History', path: PATH_DASHBOARD.orderHistory.root, icon: ICONS.ecommerce },
//         { title: 'Customers', path: PATH_DASHBOARD.customerDetails.root, icon: ICONS.ecommerce },
//         // { title: 'Profile', path: PATH_DASHBOARD.orderHistory.root, icon: ICONS.ecommerce },
//         { title: 'Notifications', path: PATH_DASHBOARD.notification.root, icon: ICONS.analytics },
//       {
//         title: 'e-commerce',
//         path: PATH_DASHBOARD.eCommerce.root,
//         icon: ICONS.cart,
//         children: [
//           { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
//           { title: 'product', path: PATH_DASHBOARD.eCommerce.productById },
//           { title: 'list', path: PATH_DASHBOARD.eCommerce.list },
//           { title: 'create', path: PATH_DASHBOARD.eCommerce.newProduct },
//           { title: 'edit', path: PATH_DASHBOARD.eCommerce.editById },
//           { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
//           { title: 'invoice', path: PATH_DASHBOARD.eCommerce.invoice }
//         ]
//       },
//       //  MANAGEMENT : USER
//       {
//         title: 'user',
//         path: PATH_DASHBOARD.user.root,
//         icon: ICONS.user,
//         children: [
//           { title: 'profile', path: PATH_DASHBOARD.user.profile },
//           { title: 'cards', path: PATH_DASHBOARD.user.cards },
//           { title: 'list', path: PATH_DASHBOARD.user.list },
//           { title: 'create', path: PATH_DASHBOARD.user.newUser },
//           { title: 'edit', path: PATH_DASHBOARD.user.editById },
//           { title: 'account', path: PATH_DASHBOARD.user.account }
//         ]
//       },

//       ]
//     }
//   ];
// } else if(visitor ==="admin") {
//   sidebarConfig = [
//     {
//       subheader: '',
//       items: [
//         {
//           title: 'Home',
//           path: PATH_DASHBOARD.general.app,
//           icon: ICONS.home
//         },
//         { title: 'Order History', path: PATH_DASHBOARD.orderHistory.root, icon: ICONS.ecommerce },
//         { title: 'Customers', path: PATH_DASHBOARD.customerDetails.root, icon: ICONS.ecommerce },
//         { title: 'Staff', path: PATH_DASHBOARD.orderHistory.root, icon: ICONS.ecommerce },
//         { title: 'Notifications', path: PATH_DASHBOARD.notification.root, icon: ICONS.analytics },
//         { title: 'Settings', path: PATH_DASHBOARD.notification.root, icon: ICONS.analytics }

//       ]
//     }
//   ];
// } else {
//   sidebarConfig = [
//     {
//       subheader: '',
//       items: [
//         {
//           title: 'Home',
//           path: PATH_DASHBOARD.general.app,
//           icon: ICONS.home
//         },
//         { title: 'Order History', path: PATH_DASHBOARD.orderHistory.root, icon: ICONS.ecommerce },
//         { title: 'Notifications', path: PATH_DASHBOARD.notification.root, icon: ICONS.analytics }
//       ]
//     }
//   ];
// }

// export default sidebarConfig;
