import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import AuthLayout from 'src/layouts/AuthLayout';
import { Box } from '@mui/material';
// material
import {
  Link,
  Stack,
  Alert,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
// routes
import { PATH_AUTH, PATH_DASHBOARD } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
//
import { MIconButton } from '../../@material-extend';
import { REST_API } from 'src/defaultValues';
import axios from 'axios'
// ----------------------------------------------------------------------


export default function LoginForm() {
  const navigate = useNavigate();
  const { login } = useAuth();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const [open, setOpen] = useState(false);
  const [exchangeRate,setExchangeRate]=useState('')


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDashboard = () => {
    enqueueSnackbar('Login success', {
      variant: 'success',
      action: (key) => (
        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
          <Icon icon={closeFill} />
        </MIconButton>
      )
    });
    navigate(PATH_DASHBOARD.root);
  };


  const UpdateExcRate = async() => {
    if(exchangeRate){
      const response=await axios.post(`${REST_API}webservice/admin/add-exchange-rate`,{exchangeRate})
       if(response.data.status===1) {
        enqueueSnackbar('Exchange Rate Updated success', {variant: 'success'});
        navigate(PATH_DASHBOARD.root);
       }
    }else{
      enqueueSnackbar(' Enter Exchange Rate ', {variant: 'warning'});
    }

};


  const LoginSchema = Yup.object().shape({
    // email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    email: Yup.string()
    .email('Email must be a valid email address')
    .test('at-least-one-letter', 'Email must be a valid email address', value => {
      if (!value) return false; // handle empty values
      const beforeAtSymbol = value.split('@')[0]; // get the part before @
      return /[a-zA-Z]/.test(beforeAtSymbol); // check if it contains at least one letter
    })
    .required('Email is required'),
    password: Yup.string().required('Password is required')
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      // remember: true,
      exchangeRate:""
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      try {
        await login(values.email, values.password);
        // enqueueSnackbar('Login success', {
        //   variant: 'success',
        //   action: (key) => (
        //     <MIconButton size="small" onClick={() => closeSnackbar(key)}>
        //       <Icon icon={closeFill} />
        //     </MIconButton>
        //   )
        // });
        enqueueSnackbar('Login success', {variant: 'success'});
        if (isMountedRef.current) {
          handleClickOpen();
        }
      } catch (error) {

        enqueueSnackbar('Login credentials are not correct', {
          variant: 'error'})

        console.error(error);
        resetForm();
        if (isMountedRef.current) {
          setSubmitting(false);
          setErrors({ afterSubmit: error.message });
        }
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const handleInputChange = (e) => {
    let value = e.target.value.replace(/[^0-9.]/g, '');
    const parts = value.split('.');
    if (parts.length > 2) {
      value = parts[0] + '.' + parts.slice(1).join('');
    }
    if (parts.length === 2) {
      parts[1] = parts[1].slice(0, 4);
      value = parts.join('.');
    }
    if (value.length > 10) {
      value = value.slice(0, 10);
    }
  
    // Convert the value to a number and check if it's greater than 0
    const numericValue = parseFloat(value);
    if (numericValue > 0) {
      setExchangeRate(value);
    } else {
      setExchangeRate('');
    }
  };
  
  const handleKeyDown = (e) => {
    if (e.key === '-' || e.key === '+' || e.key === 'e') {
      e.preventDefault(); // Prevent typing "-", "+", or "e"
    }
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          {/* {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>} */}

          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            sx={{ backgroundColor: '#fff', borderRadius: '10px' }}
            placeholder="Email"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            sx={{ backgroundColor: '#fff', borderRadius: '10px' }}
            placeholder="Enter Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ my: 2 }}>
          {/* <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label={<Typography variant="body1" fontWeight="bold" color="#fff">Remember me</Typography>}
          /> */}

          <Link component={RouterLink} color="red" fontSize="15px" variant="subtitle2" fontWeight="bold" to={PATH_AUTH.resetPassword}>
            Forgot password?
          </Link>
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          Admin Login
        </LoadingButton>

        <Dialog open={open} onClose={handleClose}>
          <DialogTitle >Enter Today's Exchange Rate</DialogTitle>
          <DialogContent>
            {/* <DialogContentText>
              To subscribe to this website, please enter your email address here. We will send updates occasionally.
            </DialogContentText> */}
            {/* <TextField sx={{backgroundColor:"whitesmoke"}} autoFocus fullWidth type="number" 
            margin="dense" variant="outlined" label="Enter Rate"  
            {...getFieldProps('exchangeRate')}
            onChange={(e)=>setExchangeRate(e.target.value)}
            error={Boolean(touched.exchangeRate && errors.exchangeRate)}
            helperText={touched.exchangeRate && errors.exchangeRate}/> */}

<TextField
      sx={{ backgroundColor: "whitesmoke" }}
      autoFocus
      fullWidth
      type="text" // Change to "text" to better control input
      margin="dense"
      variant="outlined"
      label="Enter Rate"
      onChange={handleInputChange}
      onKeyDown={handleKeyDown}
      value={exchangeRate} // Ensure the input value is controlled
    />
            
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="inherit">
              Cancel
            </Button>
            <Button onClick={UpdateExcRate} variant="contained" sx={{backgroundColor:"#56407d"}}>
              Update 
            </Button>
            <Button onClick={handleDashboard} variant="contained">
               Continue
            </Button>
          </DialogActions>
        </Dialog>
      </Form>
    </FormikProvider>
  );
}
