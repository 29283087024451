import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
// utils
// import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';
import axios from 'axios'
import { REST_API } from 'src/defaultValues';
// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  }
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve()
});

let userId = null;
let user=JSON.parse(window.localStorage.getItem('user'))
userId=user?.id
let EmailOTP=null

AuthProvider.propTypes = {
  children: PropTypes.node
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  // useEffect(() => {
  //   const initialize = async () => {
  //     try {
  //       const accessToken = window.localStorage.getItem('accessToken');

  //       if (accessToken && isValidToken(accessToken)) {
  //         setSession(accessToken);

  //         const response = await axios.get('/api/account/my-account');
  //         const { user } = response.data;

  //         dispatch({
  //           type: 'INITIALIZE',
  //           payload: {
  //             isAuthenticated: true,
  //             user
  //           }
  //         });
  //       } else {
  //         dispatch({
  //           type: 'INITIALIZE',
  //           payload: {
  //             isAuthenticated: false,
  //             user: null
  //           }
  //         });
  //       }
  //     } catch (err) {
  //       console.error(err);
  //       dispatch({
  //         type: 'INITIALIZE',
  //         payload: {
  //           isAuthenticated: false,
  //           user: null
  //         }
  //       });
  //     }
  //   };

  //   initialize();
  // }, []);


  
  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);
          // const response = await axios.get('/api/account/my-account');
          // const { user } = response.data;
          const user=JSON.parse(localStorage.getItem('user'))
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };
  
    initialize();
  }, []);


  // const login = async (email, password) => {
  //   const response = await axios.post('/api/account/login', {
  //     email,
  //     password
  //   });
  //   const { accessToken, user } = response.data;

  //   setSession(accessToken);
  //   dispatch({
  //     type: 'LOGIN',
  //     payload: {
  //       user
  //     }
  //   });
  // };

  const login = async (email, password) => {
    const response = await axios.post(`${REST_API}webservice/admin/login-admin`, {
      email,
      password
    })
    const { accessToken, user } = response.data;
    console.log("userId",user.id);
    userId=user.id;
    window.localStorage.setItem('user',JSON.stringify(user)); 
    window.localStorage.setItem('accessToken', accessToken); 
    dispatch({
      type: 'LOGIN',
      payload: {
        user
      }
    });
    
  };

  const register = async (email, password, firstName, lastName) => {
    const response = await axios.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);
    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const resetPassword = async (email) => {
    console.log(email);
    axios.post(`${REST_API}webservice/customer/forget-password-otp`,{email})
    .then(res =>{
     if(res.data.status===1) {
       console.log(res.data)
     EmailOTP = res.data.otp
    }else {
     console.log("not getting otp");
    }
   })
    .catch(err =>console.log(err))
};

  const updateProfile = async (values) => {
    console.log("formData----",values);
    console.log("formData----",values.userId);
    
    const response = await axios.post(`${REST_API}webservice/admin/update-profile-admin/${values.userId}`,{values})
    console.log("profile updated",response.data);
    if(response.data.status===1){
      console.log("profile updated");
      // navigate(PATH_DASHBOARD.general.app)
      window.location.reload()
    }else {
      console.log("profile not updated");
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        resetPassword,
        updateProfile
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider,userId,EmailOTP};
