import faker from 'faker'
import { Icon } from '@iconify/react'
import { sentenceCase } from 'change-case'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
// material
import { useTheme } from '@material-ui/core/styles'
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill'
import {
  Box,
  Card,
  Table,
  Button,
  Divider,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CardHeader,
  TableContainer,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Dialog, DialogTitle, DialogActions,
  TablePagination,
  Grid,
} from '@material-ui/core'
// utils
import { fCurrency } from '../../../../utils/formatNumber'
//
import Label from '../../../Label'
import Scrollbar from '../../../Scrollbar'
import MoreMenuButton from '../../../MoreMenuButton'
import { Link, useLocation } from 'react-router-dom'
import { PATH_DASHBOARD } from '../../../../routes/paths'
import SearchIcon from '@mui/icons-material/Search'
import InputAdornment from '@material-ui/core/InputAdornment'
import { useEffect, useState } from 'react'
import PickupLocationAdd from './PickupLocationAdd'
import axios from 'axios'
import { REST_API } from 'src/defaultValues'
import { useSnackbar } from 'notistack';
import { MIconButton, MLinearProgress } from 'src/components/@material-extend'
import closeFill from '@iconify/icons-eva/close-fill';
import LoadingScreen from 'src/components/LoadingScreen'
// ----------------------------------------------------------------------

const randomDate = faker.date.past()
const INVOICES = [
  {
    id: 1,
    countryName: 'China',
    state: 'Kaduna',
    LGA: 'Kaduna North',
    Address: 'YY4 Abuja Road',
    number: '09098871504',
    contactPerson: 'Mrs Tina',
    cost: '$ 2',
  },
]

// ----------------------------------------------------------------------

export default function PickupLocation() {
  const theme = useTheme()
  const navigate = useNavigate()
  const [updatePage, setUpdatePage] = useState(false)
  const [itemId, setItemId] = useState()
  const [data, setData] = useState([])
  const { enqueueSnackbar,closeSnackbar } = useSnackbar()
  const [open, setOpen] = useState(false);
  const[refresh,setRefresh]= useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5); // You can change the default rows per page if needed
 
  // Your existing filteredData and navigate function
 
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
 
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickOpen = (id) => {
    setOpen(true);
    setItemId(id)
  };

  const handleClose = () => {
    setOpen(false);
    
  };

  useEffect(() => {
    axios
      .get(`${REST_API}webservice/admin/getAll-pickup-location`)
      .then((res) => {
        if(res.data.status===1) {
          console.log("get all pickup locaion",res.data)
          setData(res.data.data)
          setRefresh(false)

        }else {
          console.log("not getting pickup location");
          setRefresh(false)

        }
        
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setTimeout(() => {
          setIsLoading(false);
        }, 2000); 
      })
  }, [refresh])

  const handleAdd = () => {
    navigate(PATH_DASHBOARD.settings.pickupLocationAdd)
  }
  const handleUpdate = (id) => {
    setUpdatePage(true)
    setItemId(id)
  }

  const handleDelete = (id) => {
    axios
      .delete(`${REST_API}webservice/admin/delete-pickup-location/${id}`)
      .then((res) => {
        window.location.reload()
        console.log(res.data)
        enqueueSnackbar('Pickup location deleted successfully', {
          variant: 'error',
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          ),
        })
      })
      .catch((err) => console.log(err))
  }

  let filteredData = data;

  if (searchQuery) {
    filteredData = data.filter((row) => {
      // Convert the commission and search query to lowercase for case-insensitive comparison
      const commission = row.state.toLowerCase().trim();
      const searchQueryTrimmed = searchQuery.trim().toLowerCase();
  
      console.log("filteredData", filteredData);
      console.log("commission", commission);
      console.log("searchQuery", searchQueryTrimmed);
  
      return commission.includes(searchQueryTrimmed);
    });
  }


  function formatMoney(amount) {
    // Split the amount into integer and decimal parts
    const parts = amount.toString().split('.');
    const integerPart = parts[0];
    const decimalPart = parts.length > 1 ? '.' + parts[1] : '';
  
    // Add commas every three digits from the right
    const integerWithCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  
    // Concatenate integer and decimal parts and return
    return integerWithCommas + decimalPart;
  }

  if (isLoading) {
    return(
      <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <LoadingScreen />
    </Box>
  
    ) 
  }

  return (
    <>
      {updatePage ? (
        <PickupLocationAdd
          update={true}
          setUpdatePage={setUpdatePage}
          itemId={itemId}
          setRefresh={setRefresh}
        />
      ) : (
        <>
          <Box sx={{ m: 2 }}>
            <Typography sx={{ color: '#32b59f', fontSize: 'small' }}>
              Settings / Pickup location
            </Typography>
          </Box>
          <Card>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <CardHeader title="All" sx={{ mb: 1 }} />
              <Button
                sx={{
                  mr: 3,
                  mt: 3,
                  height: '40px',
                  color: '#fff',
                  backgroundColor: '#32b59f',
                  '&:hover': {
                    backgroundColor: '#32b59f',
                  },
                }}
                onClick={handleAdd}
              >
                Add
              </Button>
            </Box>
         
            <Box sx={{ width: '100%', p: 3 }}>
              <Box>
                {/* <TextField variant="outlined" disabled placeholder='Search ...'/> */}

                <TextField
                 size='small'
  variant="outlined"
  placeholder="Search by state......"
  sx={{ backgroundColor: "whitesmoke" }}
  onChange={(e) => setSearchQuery(e.target.value)}
  InputProps={{
    endAdornment: (
      <InputAdornment position="end">
        <SearchIcon />
      </InputAdornment>
    ),
  }}
/>
              </Box>
            </Box>

            <Dialog open={open} onClose={handleClose}>
                  <DialogTitle>Do you want to Delete the Pickup Location</DialogTitle>
                  
                  <DialogActions>
                    <Button onClick={handleClose}>Disagree</Button>
                    <Button onClick={()=>handleDelete(itemId)} autoFocus>
                      Agree
                    </Button>
                      </DialogActions>
             </Dialog>  




            <Scrollbar>
              <TableContainer sx={{ minWidth: 720 }}>
                <Table size='small'>
                  <TableHead>
                    <TableRow>
                      <TableCell>State</TableCell>
                      <TableCell>LGA</TableCell>
                      <TableCell>Address</TableCell>
                      <TableCell>Contact Number</TableCell>
                      <TableCell>Contact Person</TableCell>
                      <TableCell>Cost</TableCell>

                      {/* <TableCell>Date Registered</TableCell> */}

                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {filteredData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                      <TableRow key={row.id} sx={{borderBottom:'1px solid #e6e6e6',m:0,p:0}}>
                        <TableCell>{row.state}</TableCell>
                        <TableCell>{row.lga}</TableCell>
                        <TableCell>{row.address}</TableCell>
                        <TableCell>{row.contactNumber}</TableCell>
                        <TableCell>{row.contactPerson}</TableCell>
                        <TableCell>$ {formatMoney(row.cost)}</TableCell>

                        {/* <TableCell>{row.date}</TableCell> */}
                        {/* <TableCell align="right">
                    <Button variant='contained' sx={{backgroundColor:"#32b59f",
                    '&:hover': {
                      backgroundColor: "#32b59f", 
                    },}}
                    >View</Button>

                    </TableCell> */}

                        <TableCell align="right" sx={{display:"flex"}}>
                          {/* <MoreMenuButton /> */}
                          {/* <Link underline="none" component={RouterLink} to={PATH_DASHBOARD.user.product}> */}

                          <Button
                            sx={{
                              mr: 3,
                             
                             
                              color: '#fff',
                              backgroundColor: '#be4c4c',
                              '&:hover': {
                                backgroundColor: '#be4c4c',
                              },
                            }}
                            className='css-3jmj6j-MuiButtonBase-root-MuiButton-root1'
                            onClick={() => handleClickOpen(row.id)}
                          >
                            Delete
                          </Button>
                          
                          <Button
                            sx={{
                              mr: 3,
                              
                              
                              color: '#fff',
                              fontSize: 'small',
                              backgroundColor: '#32b59f',
                              '&:hover': {
                                backgroundColor: '#32b59f',
                              },
                            }}
                            className='css-3jmj6j-MuiButtonBase-root-MuiButton-root1'

                            onClick={() => handleUpdate(row.id)}
                          >
                            Edit
                          </Button>

                         
                          {/* </Link> */}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
            </Scrollbar>

            <Divider />

            <Box sx={{ p: 2, textAlign: 'right' }}>
              <Button
                to="#"
                size="small"
                color="inherit"
                component={RouterLink}
                endIcon={<Icon icon={arrowIosForwardFill} />}
              >
                View All
              </Button>
            </Box>
          </Card>
        </>
      )}
    </>
  )
}
