import * as Yup from 'yup'
import { useSnackbar } from 'notistack'
import { useCallback, useEffect, useState } from 'react'
import { Form, FormikProvider, useFormik } from 'formik'
// material
import {
  Box,
  Grid,
  Card,
  Stack,
  Switch,
  TextField,
  FormControlLabel,
  Typography,
  FormHelperText,
  Button,
  InputAdornment,
  Icon,
} from '@material-ui/core'
import { LoadingButton } from '@material-ui/lab'
// hooks
import useAuth from '../../../../hooks/useAuth'
import useIsMountedRef from '../../../../hooks/useIsMountedRef'
import { UploadAvatar } from '../../../upload'
// utils
import { fData } from '../../../../utils/formatNumber'
//
// import countries from '../../../../countries';
import countries from '../../user/countries'
import { PATH_DASHBOARD } from 'src/routes/paths'
import { useNavigate } from 'react-router'
import axios from 'axios'
import { REST_API } from 'src/defaultValues'
import { styled } from '@material-ui/styles'
import { WindowOutlined } from '@material-ui/icons'
import IconButton from 'src/theme/overrides/IconButton'
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { userId } from 'src/contexts/JWTContext'
import { MLinearProgress } from 'src/components/@material-extend'
import LoadingScreen from 'src/components/LoadingScreen'

// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}))

export default function EditCustomerPage({ setViewEditPage, id, customer }) {
  const isMountedRef = useIsMountedRef()
  const { enqueueSnackbar } = useSnackbar()
  const { user, updateProfile } = useAuth()
  const [data, setData] = useState([])
  const [showPassword, setShowPassword] = useState(false);
  const [selectedFile,setSelectedFile] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState();
 const [isLoading, setIsLoading] = useState(true);


  const navigate = useNavigate()
  

  useEffect(() => {
    customer
      ? axios
          .get(`${REST_API}webservice/customer/find-customer/${id}`)
          .then((res) => {
            if (res.data.status === 1) {
              console.log(res.data.result)
              setData(res.data.result[0])
              setPhoneNumber(res.data.result[0].phoneNumber.slice(1));
            } else {
              console.log('not getting data')
            }
          })
          .catch((err) => console.log(err))
          .finally(() => {
            setTimeout(() => {
              setIsLoading(false);
            }, 2000); 
          })
      : axios
          .get(`${REST_API}webservice/customer/find-staff/${id}`)
          .then((res) => {
            if (res.data.status === 1) {
              console.log(res.data.result)
              setData(res.data.result[0])
              setPhoneNumber(res.data.result[0].phoneNumber.slice(1));
            } else {
              console.log('not getting data')
            }
          })
          .catch((err) => console.log(err))
          .finally(() => {
            setTimeout(() => {
              setIsLoading(false);
            }, 2000); 
          })
  }, [])

  const UpdateUserSchema = Yup.object().shape({
    password: Yup.string().required('Password is required').min(6, 'Password must be 6 characters or more'),
    phoneNumber: Yup.string().min(10, 'Mobile Number must be 11 digit!').max(10, 'Mobile numbers cannot be longer than 11 digits.').required('Phone Number required'),
    // firstName: Yup.string().required('First Name is required'),
    // lastName: Yup.string().required('Last Name is required'),
    // email: Yup.string().required('Email is required')
    firstName: Yup.string()
    .matches(/^[a-zA-Z\s]+$/, 'First name should not contain numbers or special characters')
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('First name required'),
  lastName: Yup.string()
    .matches(/^[a-zA-Z\s]+$/,'Last name should not contain numbers or special characters')
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Last name required'),
    email: Yup.string()
    .email('Email must be a valid email address')
    .test('at-least-one-letter', 'Email must be a valid email address', value => {
      if (!value) return false; // handle empty values
      const beforeAtSymbol = value.split('@')[0]; // get the part before @
      return /[a-zA-Z]/.test(beforeAtSymbol); // check if it contains at least one letter
    })
    .required('Email is required'),
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: data ? data.firstName : '',
      lastName: data ? data.lastName : '',
      email: data ? data.email : '',
      photoURL: data ?  `${REST_API}${data?.image}` : '',
      phoneNumber: data ? phoneNumber : '',
      country: data ? data.country : '',
      address: data ? data.address : '',
      state: data ? data.state : '',
      city: data ? data.city : '',
      zipCode: data ? data.zipCode : '',
      about: data ? data.about : '',
      isPublic: data ? data.isPublic : '',
      password:data ? data.pass : '',
      userId:data? data.userId : '',
      id:data? data?.id:"",
      byId:userId
    },

    validationSchema: UpdateUserSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      console.log('val---', values)

      try {
        // await updateProfile({ ...values });\
        console.log('values---', values)

        customer
          ? await axios
              .put(`${REST_API}webservice/customer/update-customer/${id}`, {
                values,
              })
              .then((res) => {
                if (res.data.status === 1) {
                  enqueueSnackbar('Update success', { variant: 'success' })
                } else {
                  console.log(res.data.message)
                }
              })
              .catch((err) => console.log(err))
          : await axios
              .put(`${REST_API}webservice/customer/update-staff/${id}`, {
                values,
              })
              .then((res) => {
                if (res.data.status === 1) {
                  enqueueSnackbar('Update success', { variant: 'success' })
                } else {
                  console.log(res.data.message)
                }
              })
              .catch((err) => console.log(err))

        if (isMountedRef.current) {
          setSubmitting(false)
        }
      } catch (error) {
        if (isMountedRef.current) {
          setErrors({ afterSubmit: error.code })
          setSubmitting(false)
        }
      }
    },
  })

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0]
      if (file) {
        setSelectedFile(file)
        setFieldValue('photoURL', {
          ...file,
          preview: URL.createObjectURL(file),
        })
      }
    },
    [setFieldValue],
  )

  const handleBack = () => {
    // navigate(PATH_DASHBOARD.staffDetails.root)
    setViewEditPage(false)
  }

  // const handleUpdate = () => {
  //   console.log('Form submitted', values)

  //   axios
  //     .put(`${REST_API}webservice/customer/update-customer/${id}`, { values })
  //     .then((res) => {
  //       if (res.data.status === 1) {
  //         enqueueSnackbar('Update success', { variant: 'success' })
  //         setViewEditPage(false)
  //         window.location.reload()
  //       } else {
  //         console.log(res.data.message)
  //       }
  //     })
  //     .catch((err) => console.log(err))
  // }

  const handleUpdate= async()=>{
 
    const formData = new FormData();

    formData.append('firstName', values?.firstName);
    formData.append('lastName', values?.lastName);
    formData.append('email', values?.email);
    formData.append('photoURL', values?.photoURL);
    formData.append('phoneNumber', "0"+values?.phoneNumber);
    formData.append('country', values?.country);
    formData.append('address', values?.address);
    formData.append('state', values?.state);
    formData.append('city', values?.city);
    formData.append('zipCode', values?.zipCode);
    formData.append('about', values?.about);
    formData.append('userId', values?.userId);
    formData.append('file', selectedFile);
    formData.append('password', values?.password);
    formData.append('id', values?.id);
    formData.append('byId', values?.byId);
    formData.append('admin', 1);

  
   
   try {
         await UpdateUserSchema.validate(values, { abortEarly: false });

          const url=`${REST_API}webservice/customer/update-customer/${values.userId}`
          const response = await axios.put(url, formData, {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
          
          });
          if(response.data.status===1){
            console.log("profile updated");
            // navigate(PATH_DASHBOARD.general.app)
            console.log("Profile updated:", response.data);
            window.location.reload()
            enqueueSnackbar('Update success', { variant: 'success' });
          }else {
            console.log("profile not updated");
          }
         
      } catch (error) {
          console.error("Error updating profile:", error);
          enqueueSnackbar('Please fill the fields correctly', { variant: 'warning' });

      }
             
      
    }

const handleStaffUpdate = async () => {
         
          const formData = new FormData();

    formData.append('firstName', values?.firstName || "");
    formData.append('lastName', values?.lastName || "");
    formData.append('email', values?.email || "");
    formData.append('photoURL', values?.photoURL || "");
    formData.append('phoneNumber', "0"+values?.phoneNumber || "");
    formData.append('country', values?.country || "");
    formData.append('address', values?.address || "");
    formData.append('state', values?.state || "");
    formData.append('city', values?.city || "");
    formData.append('zipCode', values?.zipCode || "");
    formData.append('about', values?.about || "");
    formData.append('userId', values?.userId || "");
    formData.append('file', selectedFile);
    formData.append('password', values?.password || "");
    formData.append('id', values?.id || "");

  
   
   try {

    await UpdateUserSchema.validate(values, { abortEarly: false });

          const url=`${REST_API}webservice/customer/update-staff/${values.userId}`
          const response = await axios.put(url, formData, {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
          
          });
          if(response.data.status===1){
            console.log("profile updated");
            // navigate(PATH_DASHBOARD.general.app)
            console.log("Profile updated:", response.data);
            window.location.reload()
            enqueueSnackbar('Update success', { variant: 'success' });
          }else {
            console.log("profile not updated");
          }
         
      } catch (error) {
          console.error("Error updating profile:", error);
          enqueueSnackbar('Please fill the fields correctly', { variant: 'warning' });
      }
             
  }

  // const handleStaffUpdate = async () => {
  //   await axios
  //     .put(`${REST_API}webservice/customer/update-staff/${id}`, { values })
  //     .then((res) => {
  //       if (res.data.status === 1) {
  //         enqueueSnackbar('Update success', { variant: 'success' })
  //         setViewEditPage(false)
  //         window.location.reload()
  //       } else {
  //         console.log(res.data.message)
  //       }
  //     })
  //     .catch((err) => console.log(err))
  // }


  if (isLoading) {
    return(
      <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <LoadingScreen />
      </Box>

  
    ) 
  }


  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Card sx={{ py: 10, px: 3, textAlign: 'center' }}>
              <UploadAvatar
                accept="image/*"
                file={values.photoURL}
                maxSize={3145728}
                onDrop={handleDrop}
                error={Boolean(touched.photoURL && errors.photoURL)}
                caption={
                  <Typography
                    variant="caption"
                    sx={{
                      mt: 2,
                      mx: 'auto',
                      display: 'block',
                      textAlign: 'center',
                      color: 'text.secondary',
                    }}
                  >
                    Allowed *.jpeg, *.jpg, *.png, *.gif
                    <br /> max size of {fData(3145728)}
                  </Typography>
                }
              />

              <FormHelperText error sx={{ px: 2, textAlign: 'center' }}>
                {touched.photoURL && errors.photoURL}
              </FormHelperText>

              {/* <FormControlLabel
                control={<Switch {...getFieldProps('isPublic')} color="primary" />}
                labelPlacement="start"
                label="Public Profile"
                sx={{ mt: 5 }}
              /> */}
            </Card>
          </Grid>

          <Grid item xs={12} md={8}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={{ xs: 2, md: 3 }}>
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>

                <Box sx={{ width: '100%' }}>
                    <LabelStyle>First Name</LabelStyle>
                  <TextField
                    fullWidth
                    defaultValue={'Name'}
                    {...getFieldProps('firstName')}
                    error={Boolean(touched.firstName && errors.firstName)}
                    helperText={touched.firstName && errors.firstName}
                  />
                  </Box>

                  <Box sx={{ width: '100%' }}>
                    <LabelStyle>Last Name</LabelStyle>
                    <TextField
                      fullWidth
                      
                      defaultValue={'Name'}
                      {...getFieldProps('lastName')}
                      error={Boolean(touched.lastName && errors.lastName)}
                      helperText={touched.lastName && errors.lastName}
                    />
                  </Box>
                </Stack>

                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <Box sx={{ width: '100%' }}>
                    <LabelStyle>Email Address</LabelStyle>
                    <TextField
                      fullWidth
                      defaultValue={'Email'}
                      value={data ? data.email : ''}
                      {...getFieldProps('email')}
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                    />
                  </Box>
                </Stack>

                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <Box sx={{ width: '100%' }}>
                    <LabelStyle>Phone Number</LabelStyle>
                    <TextField
                    type='number'
                      fullWidth
                      placeholder={'xxx-xxx-xxxx'}
                      value={data ? data.phoneNumber : ''}
                      className='css-mqdhaa-MuiInputBase-input-MuiOutlinedInput-input-number'
                      InputProps={{
                        inputProps: {
                            min: 0,
                          },
                          startAdornment: (
                            <InputAdornment position="start" sx={{color:'black'}} className='css-1jirt6x-MuiTypography-root0'>
                              0
                            </InputAdornment>
                          ),
                      }}
                      {...getFieldProps('phoneNumber')}
                      error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                      helperText={touched.phoneNumber && errors.phoneNumber}
                    />
                  </Box>

                  <Box sx={{ width: '100%' }}>
                    <LabelStyle>Address</LabelStyle>
                    <TextField
                      fullWidth
                      {...getFieldProps('address')}
                      error={Boolean(touched.address && errors.address)}
                      helperText={touched.address && errors.address}
                    />
                  </Box>
                </Stack>

                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <Box sx={{ width: '100%' }}>
                    <LabelStyle>Country</LabelStyle>
                    <TextField
                      select
                      fullWidth
                      // label="Country"
                      placeholder="Country"
                      value={data ? data.country : ''}
                      {...getFieldProps('country')}
                      SelectProps={{ native: true }}
                      error={Boolean(touched.country && errors.country)}
                      helperText={touched.country && errors.country}
                    >
                      <option value="" />
                      {countries.map((option) => (
                        <option key={option.code} value={option.label}>
                          {option.label}
                        </option>
                      ))}
                    </TextField>
                  </Box>
                  <Box sx={{ width: '100%' }}>
                    <LabelStyle>State/Region</LabelStyle>
                    <TextField
                      fullWidth
                      value={data ? data.state : ''}
                      {...getFieldProps('state')}
                      error={Boolean(touched.state && errors.state)}
                      helperText={touched.state && errors.state}
                    />
                  </Box>
                </Stack>

                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <Box sx={{ width: '100%' }}>
                    <LabelStyle>City</LabelStyle>
                    <TextField
                      fullWidth
                      value={data ? data.city : ''}
                      {...getFieldProps('city')}
                    />
                  </Box>
                  <Box sx={{ width: '100%' }}>
                    <LabelStyle>Zip/Code</LabelStyle>
                    <TextField
                      fullWidth
                      type="number"
                      value={data ? data.zipCode : ''}
                      {...getFieldProps('zipCode')}
                      error={Boolean(touched.zipCode && errors.zipCode)}
                      helperText={touched.zipCode && errors.zipCode}
                    />
                  </Box>
                </Stack>

                <Box sx={{ width: '100%' }}>
                  <LabelStyle>About</LabelStyle>
                  <TextField
                    fullWidth
                    multiline
                    minRows={4}
                    maxRows={4}
                    value={data ? data.about : ''}
                    {...getFieldProps('about')}
                    error={Boolean(touched.about && errors.about)}
                    helperText={touched.about && errors.about}
                  />
                </Box>
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                <Box sx={{width:"100%"}}>
                          <LabelStyle>Password</LabelStyle>
                <TextField
                    fullWidth    
                    value={data ? data.password : ''}
                    {...getFieldProps('password')}
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                  />
                </Box>

                </Stack>
              </Stack>

              <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                {/* <LoadingButton type="submit" variant="contained" loading={isSubmitting} sx={{mr:3}}>
                  Update
                </LoadingButton> */}

                {customer ? (
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: '#32b59f',
                      '&:hover': {
                        backgroundColor: '#32b59f',
                      },
                      mr: 3,
                    }}
                    onClick={handleUpdate}
                  >
                    Update
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: '#32b59f',
                      '&:hover': {
                        backgroundColor: '#32b59f',
                      },
                      mr: 3,
                    }}
                    onClick={handleStaffUpdate}
                  >
                    Update
                  </Button>
                )}
                {/* 
                <Button variant='contained' sx={{backgroundColor:"#32b59f",
                    '&:hover': {
                      backgroundColor: "#32b59f", 
                    },mr:3}}
                    onClick={handleUpdate}
                    >Update</Button> */}

                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: '#32b59f',
                    '&:hover': {
                      backgroundColor: '#32b59f',
                    },
                  }}
                  onClick={handleBack}
                >
                  Back
                </Button>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  )
}
