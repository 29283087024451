import faker from 'faker';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { useTheme } from '@material-ui/core/styles';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import {
  Box,
  Card,
  Table,
  Button,
  Divider,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CardHeader,
  TableContainer,
  TextField,
  InputLabel, Select, MenuItem, Typography,
  CircularProgress,
  FormControlLabel,
  Switch,
  TablePagination,
  Grid,
  IconButton
} from '@material-ui/core';
// utils
import { fCurrency } from '../../../../utils/formatNumber';
//
import Label from '../../../Label';
import Scrollbar from '../../../Scrollbar';
import MoreMenuButton from '../../../MoreMenuButton';
import { Link , useLocation } from 'react-router-dom';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import axios from 'axios'
import { REST_API } from 'src/defaultValues';
import { useEffect, useState } from 'react';
import StaffList from './StaffList';
import ViewStaffDetails from './ViewStaffDetails';
import EditCustomerPage from '../CustomerDetails/EditCustomerDetails';
import { ArrowDownward, ArrowUpward, Sort } from '@material-ui/icons';
import { MLinearProgress } from 'src/components/@material-extend';
import LoadingScreen from 'src/components/LoadingScreen';
// ----------------------------------------------------------------------


const randomDate = faker.date.past(); 
const INVOICES = [
  {
    id: faker.datatype.uuid(),
    name:"Felix chizzoba",
    email:"felix@gmail.com",
    phoneNumber:"08080809922",
    date:"02-jan-2024",
    price: faker.finance.amount(),
    status: 'in_progress',
    type:"physical",
    suplier:"Shein"
  }, {
    id: faker.datatype.uuid(),
    name:"David Kelly",
    email:"davidk@gmail.com",
    phoneNumber:"08080809922",
    date:"02-feb-2024",
    price: faker.finance.amount(),
    status: 'in_progress',
    type:"physical",
    suplier:"Shein"
  },

];

const COLUMNS = [
  {id:'firstName',label:'Name'},
  {id:'email',label:'Email'},
  {id:'phoneNumber',label:'Phone Number'},
  {id:'dateRegistered',label:'Date Registered'},

]

// ----------------------------------------------------------------------

export default function Staffs() {
  const theme = useTheme();
  const navigate=useNavigate()
  const [data,setData]=useState([])
  const [viewDetailsPage,setViewDetailsPage] = useState(false)
  const [staffId,setStaffId]=useState('')
  const [viewEditPage,setViewEditPage] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [isLoading, setIsLoading] = useState(true);
 const [accountStatus,setAccountStatus]=useState(true)
 const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5); 
  const [sorting, setSorting] = useState({ column: null, direction: 'asc' });

  const handleSort = (columnId) => {
    const newDirection = sorting.column === columnId && sorting.direction === 'asc' ? 'desc' : 'asc';
    setSorting({ column: columnId, direction: newDirection });
  };
 
  // Your existing filteredData and navigate function
 
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
 
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  

  useEffect(()=> {
    axios.get(`${REST_API}webservice/customer/findAll-staff`)
    .then(res => {
      console.log(res.data);
      setData(res.data.result);
     
    }).catch(err =>{
      console.log(err);
    }) .finally(() => {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000); 
    })
  },[])

  const handleAddStaff=()=> {
    navigate(PATH_DASHBOARD.staffDetails.addStaff)
  }
  const handleView=(id)=> {
    setViewDetailsPage(true)
    setStaffId(id)
  }
  const handleEdit=(id)=> {
    setViewEditPage(true)
    setStaffId(id)
  }

  
  let filteredData = data;

if (searchQuery) {
  filteredData = data.filter((row) => {
    // Convert the item name and search query to lowercase for case-insensitive comparison
    const itemName = `${row.firstName || ''} ${row.lastName || ''}`.toLowerCase().trim();
    const searchQueryTrimmed = searchQuery.trim().toLowerCase();

    console.log("filteredData", filteredData);
    console.log("itemName", itemName);
    console.log("searchQuery", searchQueryTrimmed);
    return itemName.includes(searchQueryTrimmed);
  });
}


const handleEnableDisable= async(id,currentStatus)=>{
  // setAccountStatus(!accountStatus)
  const updatedAccountStatus = !accountStatus; // Get the updated accountStatus directly
  setAccountStatus(updatedAccountStatus);

   let userStatus=currentStatus ===2 ? 1 : 2
  console.log("userStatus",userStatus);
  console.log("currentStatus",currentStatus);

 await axios.put(`${REST_API}webservice/customer/update-status-staff/${id}`,{userStatus})
 .then(res => {
  if(res.data.status===1){
    console.log("res.data",res.data);
    window.location.reload()
  }else {
      console.log('not updated');
  }
 }).catch(err =>console.log(err))

}

const sortedData = [...filteredData].sort((a, b) => {    // in this data is the variable which stores the data from database
  if (sorting.column !== null) {
    const column = COLUMNS.find((col) => col.id === sorting.column);
    const aValue = a[sorting.column];
    const bValue = b[sorting.column];
    if (aValue < bValue) return sorting.direction === 'asc' ? -1 : 1;
    if (aValue > bValue) return sorting.direction === 'asc' ? 1 : -1;
  }
  return 0;
});



if (isLoading) {
  return(
    <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <LoadingScreen />
    </Box>


  ) 
}


  return (

    <>
      {
      viewEditPage ? <EditCustomerPage setViewEditPage={setViewEditPage} id={staffId} customer={false}/> :
      
    
    <>
      {viewDetailsPage ?
           <ViewStaffDetails  setViewDetailsPage={setViewDetailsPage} id={staffId} customer={false}/>
    :
<>
    <Box sx={{m:2}}>
    <Typography sx={{color:"#32b59f",fontSize:"small"}}>Staff</Typography>
   </Box>
    <Card>  
      <Box sx={{display:"flex",justifyContent:"space-between"}}>
      <CardHeader title="Staff List" sx={{ mb: 1 }} />
      <Button
      sx={{
        mr:3,
        mt:3,
        height:"40px",
        color:"#fff",
        backgroundColor: "#32b59f",
        '&:hover': {
          backgroundColor: "#32b59f", 
        },
      }}
      onClick={handleAddStaff}
    >
      Add  Staff
    </Button>
        </Box>
     
      <Box sx={{width:"100%",p:3}}>
        <Box>
          {/* <TextField variant="outlined" disabled placeholder='Search ...'/> */}

          
          <TextField
           size='small'
              variant="outlined"
              sx={{ backgroundColor: 'whitesmoke' }}
              placeholder="Search by name..."
              InputProps={{
                endAdornment: <SearchIcon style={{ cursor: 'pointer' }} />,
              }}
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value)
                
              }}
            />
        </Box>
       
      </Box>
      <Scrollbar>
        <TableContainer sx={{ minWidth: 720,}}>
          <Table size={'small'}>
            <TableHead >
              <TableRow >
                {/* <TableCell >Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone Number</TableCell>
                <TableCell>Date Registered</TableCell> */}
             {COLUMNS?.map((column) => (
                <>
                <TableCell key={column.id}>{column.label}
                <IconButton key={`${column.id}-icon`} onClick={() => handleSort(column.id)}>
                  {sorting.column === column.id ? (
                    sorting.direction === 'asc' ? <ArrowUpward sx={{fontSize:'15px'}}/> : <ArrowDownward sx={{fontSize:'15px'}}/>
                  ) : (
                    <Sort sx={{fontSize:'15px'}}/>
                  )}
                </IconButton>
                </TableCell>
                </>
              ))}
                <TableCell></TableCell>
                <TableCell></TableCell>
              
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
            {sortedData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
              <>
                <TableRow key={row.id} sx={{borderBottom:'1px solid #e6e6e6'}}>
                  <TableCell  sx={{fontSize:"small",borderBottom: "none", paddingTop: 0, paddingBottom: 0}} style={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}> {row.firstName} {row.lastName} </TableCell>
                  <TableCell  sx={{fontSize:"small",borderBottom: "none", paddingTop: 0, paddingBottom: 0}} style={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{row.email}</TableCell>
                  <TableCell  sx={{fontSize:"small",borderBottom: "none", paddingTop: 0, paddingBottom: 0}} style={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{row.phoneNumber}</TableCell>
                  <TableCell sx={{fontSize:"small",borderBottom: "none", paddingTop: 0, paddingBottom: 0}} style={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{row.dateRegistered}</TableCell>

                  {/* <TableCell>{row.date}</TableCell> */}
                    <TableCell align="right">
                    <Button variant='contained' sx={{borderBottom: "none", paddingTop: 0, paddingBottom: 10,backgroundColor:"#32b59f",boxShadow:"none",
                    '&:hover': {
                      backgroundColor: "#32b59f", 
                    },}}
                    onClick={()=>handleView(row.id)}
                    className='css-3jmj6j-MuiButtonBase-root-MuiButton-root1'
                    >View</Button>

                    </TableCell>
  
                  <TableCell >
                    {/* <MoreMenuButton /> */}
                   {/* <Link underline="none" component={RouterLink} to={PATH_DASHBOARD.user.product}> */}
                    <Button variant='contained' sx={{borderBottom: "none", paddingTop: 0, paddingBottom: "-20px",backgroundColor:"#56407d" ,boxShadow:"none",
                    '&:hover': {
                      backgroundColor: "#56407d", 
                    },}}
                    onClick={()=>handleEdit(row.id)}
                    className='css-3jmj6j-MuiButtonBase-root-MuiButton-root1'
                    >Edit</Button>
                    {/* </Link> */}
                  </TableCell>

                  <TableCell  align='right' sx={{borderBottom: "none", paddingTop: 0, paddingBottom: 0}}>
                {/* {
                      row.account_status===1 ? 
                   <FormControlLabel control={<Switch  defaultChecked  />} onClick={()=>handleEnableDisable(row.id)} label="" />
                   :
                   <FormControlLabel control={<Switch   />} onClick={()=>handleEnableDisable(row.id)} label="" />
                } */}    
                <FormControlLabel
                    control={<Switch defaultChecked={row.account_status === 1} />}
                   onClick={() => handleEnableDisable(row.id,row.account_status)}
               label=""
/>
                  </TableCell>
                </TableRow>
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Scrollbar>

      <Divider />

      {/* <Box sx={{ p: 2, textAlign: 'right' }}>
        <Button
          to="#"
          size="small"
          color="inherit"
          component={RouterLink}
          endIcon={<Icon icon={arrowIosForwardFill} />}
        >
          View All
        </Button>
      </Box> */}
    </Card>
    {/* <StaffList/> */}

    </>  
  }
    </>
  }
    </>
  );
}
