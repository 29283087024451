import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// material
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, Stack, AppBar, Toolbar, IconButton,Link, Button } from '@material-ui/core';
// components
import { MHidden, MIconButton } from '../../components/@material-extend';
//

import { PATH_DASHBOARD, PATH_DOCS } from '../../routes/paths';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';


import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import ContactsPopover from './ContactsPopover';
import NotificationsPopover from './NotificationsPopover';
import {Typography} from '@material-ui/core';
import MyAvatar from '../../components/MyAvatar';
import { REST_API } from 'src/defaultValues';
import { useEffect, useState } from 'react';
import axios from 'axios'
import { userId,user } from 'src/contexts/JWTContext';
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;


const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[500_12]
}));

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `100%`
    // width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function DashboardNavbar({ onOpenSidebar }) {
  const { pathname } = useLocation();
  const { user } = useAuth();
  const [data,setData]=useState('')
  const [adminName,setAdminName]=useState('')

  useEffect(()=>{
     axios.get(`${REST_API}webservice/admin/get-exchange-rate`)
     .then(res=>{
      if(res.data.status===1){
        console.log("exc",res.data.result);
        setData(res.data.result)
      }else{
        console.log("not getting exchange rate");
      }
     })
  },[])


  useEffect(()=>{
    axios.get(`${REST_API}webservice/admin/get-admin-data/${user.id}`)
    .then(res =>{
      console.log(res.data.result,"sadfdddddddddd")
      setAdminName(res.data.result)
      
    }).catch(err =>console.log(err,"err"))
  },[])


  return (
    <RootStyle>
      <ToolbarStyle>
        <MHidden width="lgUp">
          <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>
 
             {/* <Box>
              <Typography color={"#32b59f"} fontWeight={"bold"} fontSize={'large'}>{adminName?.firstName} {adminName?.lastName}</Typography>
             </Box> */}

             <Box sx={{ width:"70%",display:{xs:"none",md:"flex"},justifyContent:"end"}}>
              <Typography color={"#c15858"} fontWeight={"bold"} fontSize={'large'}>Today's exchange rate:$1 = ₦{data}</Typography>
             </Box>
             
        {/* <Searchbar /> */}
        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" spacing={{ xs: 0.5, sm: 1.5 }}>

        <Box>
              <Typography color={"#32b59f"} fontWeight={"bold"} fontSize={'large'} sx={{mt:1}}>{adminName?.firstName} {adminName?.lastName}</Typography>
             </Box>
          {/* <LanguagePopover /> */}
          {/* <NotificationsPopover /> */}
          {/* <ContactsPopover /> */}
         

          {/* <Button sx={{color:"black"}}>Track Order</Button> */}
          {/* <Box sx={{display:"flex",alignItems:"center"}}>

              <Link underline="none" component={RouterLink} to={PATH_DASHBOARD.trackOrders.root}>
                    <Button variant='contained' sx={{marginRight:"10px",backgroundColor:"#32b59f"}}>Track Order</Button>
               </Link>
          </Box> */}

          <AccountPopover />
          
          {/* <Box sx={{display:"flex",width:"350px",justifyContent:"space-around"}}>
            <Box sx={{display:"flex",alignItems:"center"}}>
           <Typography sx={{color:"black"}}>Track Order</Typography>
            </Box>
            <Box sx={{backgroundColor:"whitesmoke",color:"black",display:"flex",width:"200px",p:2}}>
              <Typography>Welcome back !</Typography>
              <Typography>Name</Typography>
            </Box>
             <Box sx={{ mb: 2, mx: 2.5 ,mt:2}}>
        <Link underline="none" component={RouterLink} to={PATH_DASHBOARD.user.account}>
          <AccountStyle>
            <MyAvatar />
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {user.displayName}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {user.role}
              </Typography>
            </Box>
          </AccountStyle>
        </Link>
      </Box>
          </Box> */}
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
