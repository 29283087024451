import faker from 'faker'
import { Icon } from '@iconify/react'
import { sentenceCase } from 'change-case'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { LoadingButton } from '@material-ui/lab'
import closeFill from '@iconify/icons-eva/close-fill';
// material
import { useTheme } from '@material-ui/core/styles'
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill'
import {
  Box,
  Card,
  Table,
  Button,
  Divider,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CardHeader,
  TableContainer,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Stack,
  Container,
  Autocomplete,
} from '@material-ui/core'
// utils
import { fCurrency } from '../../../../utils/formatNumber'
//
import Label from '../../../Label'
import Scrollbar from '../../../Scrollbar'
import MoreMenuButton from '../../../MoreMenuButton'
import { Link, useLocation } from 'react-router-dom'
import { PATH_DASHBOARD } from '../../../../routes/paths'
import SearchIcon from '@mui/icons-material/Search'
import InputAdornment from '@material-ui/core/InputAdornment'
import { useFormik, Form, FormikProvider } from 'formik'
import { REST_API } from '../../../../defaultValues'
import axios from 'axios'
import useIsMountedRef from '../../../../hooks/useIsMountedRef'
import { useEffect, useState } from 'react'
import { values } from 'lodash'
import { useSnackbar } from 'notistack'
import { MIconButton } from 'src/components/@material-extend'

// ----------------------------------------------------------------------

const randomDate = faker.date.past()

const INVOICES = [];
for (let i = 1; i <= 100; i++) {
  INVOICES.push({
    id: i,
    commission: `${i}%`
  });
}


// ----------------------------------------------------------------------

export default function ServiceFeeAdd({ setUpdatePage, update, itemId ,setRefresh}) {
  const theme = useTheme()
  const location = useLocation()
  const isEdit = location.state?.isEdit
  const navigate = useNavigate()
  const isMountedRef = useIsMountedRef()
  const [data, setData] = useState([])
  const { enqueueSnackbar,closeSnackbar } = useSnackbar()
 

  useEffect(() => {
    if(itemId){
      axios
        .get(`${REST_API}webservice/admin/get-service-fee/${itemId}`)
        .then((res) => {
          if(res.data.status===1) {
            console.log("get-service-fee- ",res.data.result)
            setData(res.data.result)
           
          }else {
            console.log('not getting service fee');
          
          }
         
        })
        .catch((err) => console.log(err))
    }
  }, [itemId])
  

  // const RegisterSchema = Yup.object().shape({
  //   title: Yup.string()
  //     .min(2, 'Too Short!')
  //     .max(50, 'Too Long!')
  //     .required('Title is required'),
  //   amountFrom: Yup.number()
  //     .typeError('Sub total from must be a number')
  //     .required('Sub total from is required')
  //     .min(1, 'Sub total from must be greater than or equal to 1'),
  //   amountTo: Yup.number()
  //     .typeError('Sub total to must be a number')
  //     .required('Sub total to is required')
  //     .min(Yup.ref('amountFrom'), 'Sub total to must be greater than Sub total from'),
  //   commission: Yup.string().required('Commission is required'),
  // });
  
  const RegisterSchema = Yup.object().shape({
    title: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Title is required'),
    amountFrom: Yup.number()
      .typeError('Sub total from must be a number')
      .required('Sub total from is required')
      .min(1, 'Sub total from must be greater than or equal to 1'),
    amountTo: Yup.number()
      .typeError('Sub total to must be a number')
      .required('Sub total to is required')
      .min(Yup.ref('amountFrom'), 'Sub total to must be greater than Sub total from')
      .test('greaterThan', 'Sub total to must be greater than Sub total from', function(value) {
        return value > this.parent.amountFrom;
      }),
    commission: Yup.number().min(1, 'Commission must be greater than or equal to 1').nullable(),
    fixedFee: Yup.number().min(1, 'Fixed Fee must be greater than or equal 1').nullable(),
  });
  
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title:data ? data?.title : '',
      amountFrom:data ? data?.amountFrom : '',
      amountTo: data ? data?.amountTo : '',
      commission: data ? data?.commission : '',
      fixedFee:data ? data.fixedFee : ""
    },
    validationSchema: RegisterSchema,
    
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      
      try {
        console.log('Form data:', values)
        // await register(values.email, values.password, values.Name, values.phoneNumber);
     let response;
       {update ? 
        response =  await axios
        .put(`${REST_API}webservice/admin/update-service-fee/${itemId}`, {values})
        .then(res =>{
          console.log(res.data)
          setUpdatePage(false)
          setRefresh(true)
          enqueueSnackbar('Service fee Updated successfully', {
            variant: 'success',
            action: (key) => (
              <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                <Icon icon={closeFill} />
              </MIconButton>
            ),
          })
        })
        .catch(err => console.log(err))
      :
      response =  await axios
      .post(`${REST_API}webservice/admin/add-service-fee`, {values})
      .then((res) => {
        navigate(PATH_DASHBOARD.settings.serviceFee)
        console.log(res.data)
        enqueueSnackbar('Service fee added successfully', {
          variant: 'success',
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          ),
        })
      })
      .catch((err) => {
        console.log(err)
      })
    }
      
       
        if (isMountedRef.current) {
          setSubmitting(false)
          //   navigate('/');
          // navigate(PATH_DASHBOARD.staffDetails.root);
        }
      } catch (error) {
        console.error(error)
        if (isMountedRef.current) {
          setErrors({ afterSubmit: error.message })
          setSubmitting(false)
        }
      }
    },
  })
  
  const { errors,values, touched, handleSubmit, isSubmitting, getFieldProps,setFieldValue } = formik
  const handleBack = () => {
    navigate(PATH_DASHBOARD.settings.serviceFee)
    setUpdatePage ? setUpdatePage(false) : ''
  }


  return (
    <>
      <Box sx={{ m: 2 }}>
        {/* <Typography sx={{color:"#32b59f",fontSize:"small"}}>{`Settings / Service Fee / ${isEdit? 'Update':'Add'}`}</Typography> */}
        {update ? (
          <Typography sx={{ color: '#32b59f', fontSize: 'small' }}>
            `Settings / Service Fee / Update
          </Typography>
        ) : (
          <Typography sx={{ color: '#32b59f', fontSize: 'small' }}>
            `Settings / Service Fee / Add
          </Typography>
        )}
      </Box>

      <Card>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <CardHeader
                title={itemId ? "Edit Service Fee" :"Add New Service Fee"}
                sx={{ mb: 1, fontSize: 'small' }}
              />
              <Button
                sx={{
                  mr: 3,
                  mt: 3,
                  height: '40px',
                  color: '#fff',
                  backgroundColor: '#32b59f',
                  '&:hover': {
                    backgroundColor: '#32b59f',
                  },
                }}
                onClick={handleBack}
              >
                Back
              </Button>
            </Box>
            <Divider sx={{ mt: 3 }} />
            <Container>
              <Box sx={{ width: '100%', p: 3 }}>
                <Box>
                  {/* <TextField variant="outlined" disabled placeholder='Search ...'/> */}
                  {/* {console.log("Field props",getFieldProps('title'))} */}
                  <Stack spacing={{ xs: 2, md: 3 }} sx={{ mt: 3 }}>
                    <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                      <TextField
                        fullWidth
                        defaultValue={update ? "r":""}
                        variant="outlined"
                        label="Title"
                        placeholder="Enter Title"
                        {...getFieldProps('title')}
                        error={Boolean(touched.title && errors.title)}
                        helperText={touched.title && errors.title}
                      />
                    </Stack>
                    <Stack
                      direction={{ xs: 'column', md: 'row' }}
                      spacing={2}
                      sx={{ mt: 3 }}
                    >
                      <Box>
                        <Typography>Set Subtotal Range</Typography>
                        <Stack
                          direction={{ xs: 'column', md: 'row' }}
                          spacing={2}
                          sx={{ mt: 1 }}
                        >
                          <TextField
                            type="number"
                            fullWidth
                            variant="outlined"
                            placeholder="0"
                            label="From"
                            {...getFieldProps('amountFrom')}
                            error={Boolean(touched.amountFrom && errors.amountFrom)}
                            helperText={touched.amountFrom && errors.amountFrom}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              )
                            }}
                          />
                          <TextField
                            type="number"
                            fullWidth
                            variant="outlined"
                            placeholder="0"
                            label="To"
                            {...getFieldProps('amountTo')}
                            error={Boolean(touched.amountTo && errors.amountTo)}
                            helperText={touched.amountTo && errors.amountTo}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              )
                            }}
                          />
                        </Stack>
                      </Box>

                      <Box sx={{ width: '100%' }}>
                        <Typography>Commission</Typography>
                        <Stack
                          direction={{ xs: 'column', md: 'row' }}
                          spacing={2}
                          sx={{ mt: 1 }}
                        >
                          {/* <Autocomplete
                            select
                            fullWidth
                            options={INVOICES}
                            getOptionLabel={(option) => option.commission}
                            value = {INVOICES.find((option) => option.commission == values.commission) || null}
                            onChange={(event, newValue) => {
                              // console.log('newValue---',newValue);
                              if (newValue) {
                                const selectedOption = INVOICES.find(option => option.commission == newValue.commission);
                                // console.log('selectedOption---',selectedOption);
                                if (selectedOption) {
                                  setFieldValue('commission', selectedOption.commission);
                                } else {
                                  setFieldValue('commission', '');
                                }
                              } else {
                              setFieldValue('commission', '');
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Select Commission"
                                error={Boolean(touched.commission && errors.commission)}
                                helperText={touched.commission && errors.commission}
                              />
                            )}
                          /> */}
  
  {
                          !values.fixedFee ?
                         <TextField 
                            fullWidth
                            defaultValue={update?"Enter percentage":""}
                            sx={{mb:3}}
                            variant="outlined"
                            placeholder='Enter Commission'
                            type='number'
                            {...getFieldProps('commission')}
                            error={Boolean(touched.commission && errors.commission)}
                            helperText={touched.commission && errors.commission}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  %
                                </InputAdornment>
                              )
                            }}
                          />
:""}
                        </Stack>

                        <Stack
                          direction={{ xs: 'column', md: 'row' }}
                          spacing={2}
                          
                        >
                        
                        {
                          !values.commission ?

                        
                        <TextField
                            type="number"
                            fullWidth
                            variant="outlined"
                            // sx={{mt:3}}
                            defaultValue={update ? 'fixedFee' : ''}
                            placeholder="0"
                            label="Fixed Fee"
                            {...getFieldProps('fixedFee')}
                            error={Boolean(touched.fixedFee && errors.fixedFee)}
                            helperText={touched.fixedFee && errors.fixedFee}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              ),
                            }}
                          />
                      :""  }
                        </Stack>

                      </Box>
                    </Stack>
                  </Stack>
                </Box>
              </Box>
              <Divider />
              <Box sx={{ display: 'flex', justifyContent: 'end', mb: 3 }}>
                <Button
                  sx={{
                    mr: 3,
                    mt: 3,
                    height: '40px',
                    color: '#fff',
                    backgroundColor: '#32b59f',
                    '&:hover': { backgroundColor: '#32b59f' },
                  }}
                  onClick={handleBack}
                >
                  Cancel
                </Button>
                {/* <Button 
      sx={{mr:3,mt:3,height:"40px",color:"#fff",backgroundColor:"#32b59f",'&:hover': {backgroundColor: "#32b59f", 
        }}}>
      Save
    </Button> */}
                <LoadingButton
                  loading={isSubmitting}
                  type="submit"
                  sx={{
                    mr: 3,
                    mt: 3,
                    height: '40px',
                    color: '#fff',
                    backgroundColor: '#32b59f',
                    '&:hover': { backgroundColor: '#32b59f' },
                  }}
                >
                  {update ?"Update": "Save"}
                  
                </LoadingButton>
              </Box>
            </Container>

            {/* <Box sx={{ p: 2, textAlign: 'right' }}>
        <Button
          to="#"
          size="small"
          color="inherit"
          component={RouterLink}
          endIcon={<Icon icon={arrowIosForwardFill} />}
        >
          View All
        </Button>
      </Box> */}
          </Form>
        </FormikProvider>
      </Card>
    </>
  )
}
