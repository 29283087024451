import * as Yup from 'yup';
import { useState,useCallback } from 'react';
import { Icon } from '@iconify/react';
import { useSnackbar } from 'notistack';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { REST_API } from 'src/defaultValues';
import {
  Card,
  Chip,
  Grid,
  Stack,
  Box,
  Switch,
  Button,
  TextField,
  InputLabel,
  Typography,
  RadioGroup,
  FormControl,
  Autocomplete,
  InputAdornment,
  FormHelperText,
  FormControlLabel,
  Divider
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { MIconButton } from '../../../@material-extend';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { QuillEditor } from 'src/components/editor';
import { UploadMultiFile } from 'src/components/upload';
import fakeRequest from 'src/utils/fakeRequest';
import axios from 'axios'

export default function AddUserForm() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState();

  const handleBack = () => {
    navigate(PATH_DASHBOARD.customerDetails.root);
  };

  const NewProductSchema = Yup.object().shape({
    // firstName: Yup.string().required('First Name is required'),
    // lastName: Yup.string().required('Last Name is required'),
    firstName: Yup.string()
    .matches(/^[a-zA-Z\s]+$/, 'First name should not contain numbers or special characters')
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('First name required'),
  lastName: Yup.string()
    .matches(/^[a-zA-Z\s]+$/,'Last name should not contain numbers or special characters')
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Last name required'),
    // email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    email: Yup.string()
    .email('Email must be a valid email address')
    .test('at-least-one-letter', 'Email must be a valid email address', value => {
      if (!value) return false; // handle empty values
      const beforeAtSymbol = value.split('@')[0]; // get the part before @
      return /[a-zA-Z]/.test(beforeAtSymbol); // check if it contains at least one letter
    })
    .required('Email is required'),
    phoneNumber: Yup.string().min(10, 'Mobile Number must be 11 digit!').max(10, 'Mobile numbers cannot be longer than 11 digits.').required('Phone Number required'),
    password: Yup.string().required('Password is required').min(6, 'Password must be 6 characters or more'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName:  '',
      lastName:  '',
      email:  '',
      phoneNumber: '',
      password: '',
    },
    validationSchema: NewProductSchema,
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      try {
        console.log('ssss',values);
        values.phoneNumber= "0"+values.phoneNumber
        const response = await axios.post(`${REST_API}webservice/customer/add-customer`, {
          values
      })
     .then(res => {
      if(res.data.status === 1) {

      setSubmitting(false);
      enqueueSnackbar( "Customer added successfully", { variant: 'success' });
      navigate(PATH_DASHBOARD.customerDetails.root);
      }else{
        enqueueSnackbar( "Account already existing", { variant: 'warning' });
        values.phoneNumber=""
        values.email=""
      }
     }).catch(err =>console.log(err))
      
        // await fakeRequest(500);
        // resetForm();
       
      } catch (error) {
        console.error(error);
        setSubmitting(false);
        setErrors(error);
      }
    }
  });

  const { errors, values, touched, handleSubmit, isSubmitting, setFieldValue, getFieldProps } = formik;

  

  return (
    
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
              <Stack spacing={3}>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                   <TextField
                    fullWidth
                    label="First Name"
                    {...getFieldProps('firstName')}
                    error={Boolean(touched.firstName && errors.firstName)}
                    helperText={touched.firstName && errors.firstName}
                  />
                  <TextField
                    fullWidth
                    label="Last Name"
                    {...getFieldProps('lastName')}
                    error={Boolean(touched.lastName && errors.lastName)}
                    helperText={touched.lastName && errors.lastName}
                  />

                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>

                <TextField
                    fullWidth
                    autoComplete="username"
                    type="email"
                    label="Email address"
                    {...getFieldProps('email')}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                  />

                  <TextField
                    fullWidth
                    type="number"
                    label="Phone number"
                    placeholder='xxx-xxx-xxxx'
                    className='css-mqdhaa-MuiInputBase-input-MuiOutlinedInput-input-number1'
                    InputProps={{
                      inputProps: {
                          min: 0,
                        },
                        startAdornment: (
                          <InputAdornment position="start" sx={{color:'black',gap:0}} className='css-1jirt6x-MuiTypography-root1'>
                            0
                          </InputAdornment>
                        ),
                    }}
                    {...getFieldProps('phoneNumber')}
                    error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                    helperText={touched.phoneNumber && errors.phoneNumber}
                  />
                  <TextField
                    fullWidth
                    autoComplete="current-password"
                    type={showPassword ? 'text' : 'password'}
                    label="Password"
                    {...getFieldProps('password')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <MIconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                            <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                          </MIconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                  />
                </Stack>
              </Stack>
              <Divider sx={{mt:5}}/>
            <Box sx={{ display: 'flex', justifyContent: 'end', mb: 5 }}>
              <Button
                sx={{ mr: 3, mt: 3, height: '40px', color: '#fff', backgroundColor: '#32b59f', '&:hover': { backgroundColor: '#32b59f' } }}
                onClick={handleBack}
              >
                Cancel
              </Button>
              <LoadingButton
                loading={isSubmitting}
                type="submit"
                sx={{
                  mr: 3,
                  mt: 3,
                  height: '40px',
                  color: '#fff',
                  backgroundColor: '#32b59f',
                  '&:hover': { backgroundColor: '#32b59f' },
                }}
              >
                Add Customer
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}