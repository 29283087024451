import faker from 'faker';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { useTheme } from '@material-ui/core/styles';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import {
  Box,
  Card,
  Table,
  Button,
  Divider,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CardHeader,
  TableContainer,
  TextField,
  InputLabel, Select, MenuItem, Typography, Stack, Container,
  Autocomplete

} from '@material-ui/core';
// utils
import { fCurrency } from '../../../../utils/formatNumber';
//
import Label from '../../../Label';
import Scrollbar from '../../../Scrollbar';
import MoreMenuButton from '../../../MoreMenuButton';
import { Link , useLocation } from 'react-router-dom';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useFormik, Form, FormikProvider } from 'formik'
import * as Yup from 'yup'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { REST_API } from 'src/defaultValues'
import { LoadingButton } from '@material-ui/lab'
import { Call, LocationOn } from '@material-ui/icons';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
// ----------------------------------------------------------------------


const randomDate = faker.date.past(); 
const INVOICES = [
  {
    id:1,
    state:"Abia",
    lga:"LGA 1",
    contact:"Person 1"
  },{
    id:2,
    state:"Adamawa",
    lga:"LGA 2",
    contact:"Person 2"
  },
 
];



// ----------------------------------------------------------------------

export default function PickupLocationAdd({update,setUpdatePage,itemId,setRefresh}) {
  const theme = useTheme();
  const isMountedRef = useIsMountedRef()
  const navigate=useNavigate()
  const [data, setData] = useState([]);
  const [states, setStates] = useState([]);
  const [lgas, setLgas] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState();


  const fetchStatesAndLgas = async() =>{
    try{
      const response = await axios.post(`${REST_API}webservice/admin/get-all-states-and-lgas`)
      if(response.data.status === 1){
        setStates(response.data.states)
        setLgas(response.data.lgas)
      }else{
        setStates([])
        setLgas([])
      }
    }catch(error){
      console.log("Error fetchStatesAndLgas : ",error);
    }
  }

  useEffect(()=> {
    fetchStatesAndLgas()
    if(itemId){
      axios.get(`${REST_API}webservice/admin/get-pickup-location/${itemId}`)
      .then(res =>{
        console.log("=========",res.data.result);
        setData(res.data.result)
        setPhoneNumber(res.data.result.contactNumber.slice(1));
        console.log("=========",res.data.result.contactNumber.slice(1));

      }).catch(err =>console.log(err))
    }
  },[itemId])


  const RegisterSchema = Yup.object().shape({
    state: Yup.object().nullable().required('State is required'),
    lga: Yup.object().nullable().required('LGA is required'),
    cost: Yup.number().required('Cost is required').min(1,"cost must be greater than or equal 1"),
    address: Yup.string().required('Address is required'),
    contactPerson: Yup.string().required('Contact person is required'),
    // contactNumber: Yup.string().required('NUmber is required')
    contactNumber: Yup.string().min(10, 'Mobile Number must be 11 digit!').max(10, 'Mobile numbers cannot be longer than 11 digits.').required('Phone Number required'),
  })
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      state: data ? states?.find((option) => option.name === data?.state) : null,
      lga: data ? lgas?.find((option) => option.name === data?.lga) : null,
      cost: data ? data?.cost : '',
      address: data ? data?.address : '',
      contactPerson: data ? data.contactPerson : '',
      contactNumber: phoneNumber ? phoneNumber : '',
    },
    validationSchema: RegisterSchema,

    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {

        console.log('Form data:', values)
        values.state = values.state.name
        values.lga = values.lga.name
        values.contactNumber = "0" + values.contactNumber
        console.log("79879797@#",values);
        let response
        {
          update
            ? (response = await axios
                .put(
                  `${REST_API}webservice/admin/update-pickup-location/${itemId}`,
                  { values },
                )
                .then((res) => {
                  console.log(res.data)
                  setUpdatePage(false)
                  setRefresh(true)
                  enqueueSnackbar('pickup loacation Updated successfully', {
                    variant: 'success',
                    action: (key) => (
                      <MIconButton
                        size="small"
                        onClick={() => closeSnackbar(key)}
                      >
                        <Icon icon={closeFill} />
                      </MIconButton>
                    ),
                  })
                })
                .catch((err) => console.log(err)))
            : 


              
            
              
              response = await axios
                .post(`${REST_API}webservice/admin/add-pickup-location`, {
                  values
                })
                .then((res) => {
                  navigate(PATH_DASHBOARD.settings.pickupLocation)
                  console.log(res.data)
                  enqueueSnackbar('Pickup Location added successfully', {
                    variant: 'success',
                    action: (key) => (
                      <MIconButton
                        size="small"
                        onClick={() => closeSnackbar(key)}
                      >
                        <Icon icon={closeFill} />
                      </MIconButton>
                    ),
                  })
                })
                .catch((err) => {
                  console.log(err)
                })
        }

        if (isMountedRef.current) {
          setSubmitting(false)
          //   navigate('/');
          // navigate(PATH_DASHBOARD.staffDetails.root);
        }
      } catch (error) {
        console.error(error)
        if (isMountedRef.current) {
          setErrors({ afterSubmit: error.message })
          setSubmitting(false)
        }
      }
    },
  })

  const {
    errors,
    values,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
  } = formik


  const handleBack=()=> {
    navigate(PATH_DASHBOARD.settings.pickupLocation)
    update ? setUpdatePage(false) : ''
  }
  return (
    <>
    <Box sx={{m:2}}>
    <Typography sx={{color:"#32b59f",fontSize:"small"}}>Settings / Pickup Location / Add</Typography>
   </Box>
   <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
    <Card>  
      <Box sx={{display:"flex",justifyContent:"space-between"}}>
      <CardHeader title="Add New Pickup Location" sx={{ mb: 1,fontSize:"small" }} />
      <Button
      sx={{
        mr:3,
        mt:3,
        height:"40px",
        color:"#fff",
        backgroundColor: "#32b59f",
        '&:hover': {
          backgroundColor: "#32b59f", 
        },
      }} onClick={handleBack}
    >
     Back 
    </Button>
        </Box>
      <Divider sx={{mt:3}}/>
       <Container>
      <Box sx={{width:"100%",p:3}}>
        <Box>
          {/* <TextField variant="outlined" disabled placeholder='Search ...'/> */}

             <Stack spacing={{ xs: 2, md: 3 }} sx={{mt:3}}>
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
              
                  <Autocomplete
                            select
                            fullWidth
                            options={states || []}
                            getOptionLabel={(option) => option.name}
                            value={values.state || null}
                            onChange={(event, newValue) => {
                              if (newValue) {
                                setFieldValue('state', newValue);
                                setFieldValue('lga', null);
                              } else {
                                setFieldValue('state', '');
                                setFieldValue('lga', null);
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Select State"
                                error={Boolean(touched.state && errors.state)}
                                helperText={touched.state && errors.state}
                              />
                            )}
/>  


                            <Autocomplete
                            select
                            fullWidth
                            options={lgas?.filter((item)=>item.state_id===values?.state?.id) || []}
                            getOptionLabel={(option) => option.name}
                            value={values.lga || null}
                            onChange={(event, newValue) => {
                              if (newValue) {
                                setFieldValue('lga', newValue);
                              } else {
                                setFieldValue('lga', '');
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Select lga"
                                error={Boolean(touched.lga && errors.lga)}
                                helperText={touched.lga && errors.lga}
                              />
                            )}
                          />  

                         <TextField type='number' fullWidth variant="outlined"  placeholder='0' label="Cost"
                          {...getFieldProps('cost')}
                          error={Boolean(touched.cost && errors.cost)}
                          helperText={touched.cost && errors.cost}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            )
                          }}/>

                    </Stack>

                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                <TextField fullWidth variant="outlined"  placeholder='Enter Address' label="Address"
                defaultValue={update ?"address":""}
                  {...getFieldProps('address')}
                  error={Boolean(touched.address && errors.address,)}
                  helperText={touched.address && errors.address}/>
                </Stack>

                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                {/* <Autocomplete
                    fullWidth
                    options={INVOICES}
                    getOptionLabel={(option) => option.contact}
                    renderInput={(params) => <TextField {...params} label="Contact Person (Staff List)" 
                     placeholder='Select'
                    />}
                  /> */}
                   <Autocomplete
  select
  fullWidth
  options={INVOICES}
  defaultValue={update ? "number":""}
  getOptionLabel={(option) => option.contact}
  value={INVOICES.find((option) => option.contact === values.contactPerson) || null}
  onChange={(event, newValue) => {
    if (newValue) {
      setFieldValue('contactPerson', newValue.contact);
    } else {
      setFieldValue('contactPerson', '');
    }
  }}
  renderInput={(params) => (
    <TextField
      {...params}
      placeholder="Select contact person"
      error={Boolean(touched.contactPerson && errors.contactPerson)}
      helperText={touched.contactPerson && errors.contactPerson}
    />
  )}
/>

{/* <TextField
  type='number'
  fullWidth
  variant="outlined"
  placeholder='xxx-xxxx-xxx'
  label="Contact Number"
   className='css-mqdhaa-MuiInputBase-input-MuiOutlinedInput-input-number1'
  {...getFieldProps('contactNumber')}
  error={Boolean(touched.contactNumber && errors.contactNumber)}
  helperText={touched.contactNumber && errors.contactNumber}
  InputProps={{
    startAdornment: (
      <InputAdornment position="start" className='css-1jirt6x-MuiTypography-root1'>
       0
      </InputAdornment>
    )
  }}
/> */}

<TextField
                    fullWidth
                    type="number"
                    placeholder='xxx-xxxx-xxx'
                    label="Phone number"
                    className='css-mqdhaa-MuiInputBase-input-MuiOutlinedInput-input-number1'
                    InputProps={{
                      inputProps: {
                          min: 0,
                        },
                        startAdornment: (
                          <InputAdornment position="start" sx={{color:'black'}} className='css-1jirt6x-MuiTypography-root1'>
                            0
                          </InputAdornment>
                        ),
                    }}
                    {...getFieldProps('contactNumber')}
                    error={Boolean(touched.contactNumber && errors.contactNumber)}
                    helperText={touched.contactNumber && errors.contactNumber}
                  />


                </Stack>

                    {/* <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{mt:3}}>
                        <Box>
                          <Typography>Set Subtotal Range</Typography>
                    <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{mt:1}}>
                         <TextField fullWidth variant="outlined"  placeholder='$ 0' label="From"/>
                         <TextField fullWidth variant="outlined"  placeholder='$ 0' label="To"/>
                    </Stack>
                     
                        </Box>
                      
                        
                    </Stack> */}

                    </Stack>
        </Box>
       
      </Box>
      <Divider />
          <Box sx={{display:"flex",justifyContent:"end",mb:3}}>
          <Button 
      sx={{mr:3,mt:3,height:"40px",color:"#fff",backgroundColor:"#32b59f",'&:hover': {backgroundColor: "#32b59f"}}}
    onClick={handleBack}>
      Cancel 
    </Button>
    <LoadingButton
                  loading={isSubmitting}
                  type="submit"
                  sx={{
                    mr: 3,
                    mt: 3,
                    height: '40px',
                    color: '#fff',
                    backgroundColor: '#32b59f',
                    '&:hover': { backgroundColor: '#32b59f' },
                  }}
                >
                  {update ? 'Update' : 'Save'}
                </LoadingButton>
          </Box>
      </Container>


      {/* <Box sx={{ p: 2, textAlign: 'right' }}>
        <Button
          to="#"
          size="small"
          color="inherit"
          component={RouterLink}
          endIcon={<Icon icon={arrowIosForwardFill} />}
        >
          View All
        </Button>
      </Box> */}
    </Card>
    </Form>
      </FormikProvider>
    </>

  );
}
