import PropTypes from 'prop-types';
// material
import { Card, CardHeader, Box, Typography } from '@material-ui/core';

// ----------------------------------------------------------------------

Label.propTypes = {
  title: PropTypes.string
};

export function Label({ title }) {
  return (
    <Typography variant="overline" component="p" gutterBottom sx={{ color: 'text.secondary' }}>
      {title}
    </Typography>
  );
}

Block.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  sx: PropTypes.object
};

export function Block({ title, sx, children }) {
  return (
    // <Card sx={{ overflow: 'unset', position: 'unset', width: '100%' }}>
    //   {title && <CardHeader title={title} />}
    //   <Box
    //     sx={{
    //       p: 3,
    //       minHeight: 180,
    //       ...sx
    //     }}
    //   >
    //     {children}
    //   </Box>
    // </Card>

    <Card sx={{ overflow: 'unset', position: 'unset', width: '100%',height:"180px" ,boxShadow:3,p:2}}>
    {/* {title && <CardHeader title={title} />} */}
    <Typography sx={{mt:2,fontWeight:"bold"}}>{title}</Typography>
    <Box
      sx={{
        mt:1,
        minHeight: 100,
        ...sx
      }}
    >
      {children}
    </Box>
  </Card>
  );
}
