


// import moment from 'moment';
// import { merge } from 'lodash';
// import { useEffect, useState } from 'react';
// import ReactApexChart from 'react-apexcharts';
// import axios from 'axios';
// import { REST_API } from 'src/defaultValues';
// import { BaseOptionChart } from 'src/components/charts';
// import { MLinearProgress } from 'src/components/@material-extend';
// import { Box, Grid } from '@material-ui/core';

// export default function ItemTypeChart({ selectedType, value2 }) {
//  const [isLoading, setIsLoading] = useState(true);
//   const [chartData, setChartData] = useState([]);
//   const chartOptions = merge(BaseOptionChart(), {
//     xaxis: {
//       type: 'category',
//       categories: [
//         'January', 'February', 'March', 'April', 'May', 'June', 
//         'July', 'August', 'September', 'October', 'November', 'December'
//       ]
//     },
//     yaxis: {
//       labels: {
//         formatter: function (value) {
//           return value.toFixed(0);
//         }
//       }
//     },
//     tooltip: { x: { format: 'dd/MM/yy HH:mm' } }
//   });

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get(`${REST_API}webservice/admin/get-purchased-products`);
//         const data = response.data.data;

//         const filterDataByDateRange = (data, startDate, endDate) => {
//           return data.filter(item => {
//             const itemDate = moment(item.date, 'DD/MM/YYYY');
//             return itemDate.isBetween(startDate, endDate, null, '[]');
//           });
//         };

//         let filteredData = data;
//         if (value2[0] && value2[1]) {
//           const startDate = moment(value2[0], 'MM/DD/YYYY');
//           const endDate = moment(value2[1], 'MM/DD/YYYY');
//           filteredData = filterDataByDateRange(data, startDate, endDate);
//         }

//         if (selectedType && selectedType.monthNumber !== 0) {
//           filteredData = filteredData.filter(item => {
//             const monthIndex = moment(item.date, 'DD/MM/YYYY').month() + 1;
//             return monthIndex === selectedType.monthNumber;
//           });
//         }

//         const physicalMonthlyTotal = Array(12).fill(0);
//         const digitalMonthlyTotal = Array(12).fill(0);

//         filteredData.forEach(item => {
//           const monthIndex = moment(item.date, 'DD/MM/YYYY').month();
//           if (item.type_of_item.toLowerCase() === 'physical') {
//             physicalMonthlyTotal[monthIndex] += item.total;
//           } else if (item.type_of_item.toLowerCase() === 'digital') {
//             digitalMonthlyTotal[monthIndex] += item.total;
//           }
//         });

//         const chartData = [
//           { name: 'Physical Goods', data: physicalMonthlyTotal, color: '#ff0000' },
//           { name: 'Digital Goods', data: digitalMonthlyTotal, color: '#0000ff' }
//         ];

//         setChartData(chartData);
//       } catch (error) {
//         console.log('Error fetching data:', error);
//       } finally {
       
//           setIsLoading(false);
      
//       }
//     };

//     fetchData();
//   }, [selectedType, value2]);




//   return <ReactApexChart type="area" series={chartData} options={chartOptions} height={450} />;
// }












import moment from 'moment';
import { merge } from 'lodash';
import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import axios from 'axios';
import { REST_API } from 'src/defaultValues';
import { BaseOptionChart } from 'src/components/charts';

export default function ItemTypeChart({ selectedType, value2 }) {
  const [isLoading, setIsLoading] = useState(true);
  const [chartData, setChartData] = useState([]);
  const chartOptions = merge(BaseOptionChart(), {
    xaxis: {
      type: 'category',
      categories: [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
      ]
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return value.toFixed(0);
        }
      }
    },
    tooltip: { x: { format: 'dd/MM/yy HH:mm' } }
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(`${REST_API}webservice/admin/get-purchased-products-chart`);
        const data = response.data.data;

        const filterDataByDateRange = (data, startDate, endDate) => {
          return data.filter(item => {
            const itemDate = moment(item.timestamp, 'DD-MM-YYYY');
            return itemDate.isBetween(startDate, endDate, null, '[]');
          });
        };

        let filteredData = data;
        if (value2 && value2[0] && value2[1]) {
          const startDate = moment(value2[0], 'MM/DD/YYYY');
          const endDate = moment(value2[1], 'MM/DD/YYYY');
          filteredData = filterDataByDateRange(data, startDate, endDate);
        }

        if (selectedType && selectedType.monthNumber !== 0) {
          filteredData = filteredData.filter(item => {
            const monthIndex = moment(item.timestamp, 'DD-MM-YYYY').month() + 1;
            return monthIndex === selectedType.monthNumber;
          });
        }

        const physicalMonthlyTotal = Array(12).fill(0);
        const digitalMonthlyTotal = Array(12).fill(0);

        filteredData.forEach(item => {
          const monthIndex = moment(item.timestamp, 'DD-MM-YYYY').month();
          if (item.itemType.toLowerCase() === 'physical') {
            physicalMonthlyTotal[monthIndex] += item.totalAmount;
          } else if (item.itemType.toLowerCase() === 'digital') {
            digitalMonthlyTotal[monthIndex] += item.totalAmount;
          }
        });

        const chartData = [
          { name: 'Physical Goods', data: physicalMonthlyTotal, color: '#ff0000' },
          { name: 'Digital Goods', data: digitalMonthlyTotal, color: '#0000ff' }
        ];

        setChartData(chartData);
      } catch (error) {
        console.log('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [selectedType, value2]);

  return <ReactApexChart type="area" series={chartData} options={chartOptions} height={450} />;
}
