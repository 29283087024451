import faker from 'faker';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';
// material
import { useTheme } from '@material-ui/core/styles';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import {
  Box,
  Card,
  Table,
  Button,
  Divider,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CardHeader,
  TableContainer,
  TextField,
  InputLabel, Select, MenuItem, Typography
} from '@material-ui/core';
// utils
import { fCurrency } from '../../../../utils/formatNumber';
//
import Label from '../../../Label';
import Scrollbar from '../../../Scrollbar';
import MoreMenuButton from '../../../MoreMenuButton';
import { Link , useLocation } from 'react-router-dom';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useState } from 'react';
import axios from 'axios'
import { REST_API } from 'src/defaultValues';
import { useSnackbar } from 'notistack';
import { MIconButton } from 'src/components/@material-extend'
import closeFill from '@iconify/icons-eva/close-fill';

// ----------------------------------------------------------------------





// ----------------------------------------------------------------------

export default function ExchangeRate() {
  const theme = useTheme();
  const [exchangeRate,setExchangeRate]=useState('')
  const { enqueueSnackbar,closeSnackbar } = useSnackbar()


  const handleUpdate=async()=> {
       const response=await axios.post(`${REST_API}webservice/admin/add-exchange-rate`,{exchangeRate})
       if(response) {
        if(response.data.status===1) {
          enqueueSnackbar(response.data.message, {
            variant: 'success',
            action: (key) => (
              <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                <Icon icon={closeFill} />
              </MIconButton>
            ),
          })
          window.location.reload()
        }else {
          enqueueSnackbar(response.data.message, {
            variant: 'success',
            action: (key) => (
              <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                <Icon icon={closeFill} />
              </MIconButton>
            ),
          })
        }
       
       }else {
        enqueueSnackbar('Currently Exchange Rate not Updating', {
          variant: 'error',
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          ),
        })
       }
  }
  const handleInputChange = (e) => {
    let value = e.target.value.replace(/[^0-9.]/g, '');
    const parts = value.split('.');
    if (parts.length > 2) {
      value = parts[0] + '.' + parts.slice(1).join('');
    }
    if (parts.length === 2) {
      parts[1] = parts[1].slice(0, 4);
      value = parts.join('.');
    }
    if (value.length > 10) {
      value = value.slice(0, 10);
    }
  
    // Convert the value to a number and check if it's greater than 0
    const numericValue = parseFloat(value);
    if (numericValue > 0) {
      setExchangeRate(value);
    } else {
      setExchangeRate('');
    }
  };
  
  const handleKeyDown = (e) => {
    if (e.key === '-' || e.key === '+' || e.key === 'e') {
      e.preventDefault(); // Prevent typing "-", "+", or "e"
    }
  };
  return (
    <>
    <Box sx={{m:2}}>
    <Typography sx={{color:"#32b59f",fontSize:"small"}}>Settings / Exchange Rate</Typography>
   </Box>
    <Card sx={{height:"30vh"}}>  
      
    <Divider />
  
    
      <Box sx={{width:"100%",display:"flex",justifyContent:"center",mt:5}}>
        <Box>
        <Typography>Enter Current Rate</Typography>
        <TextField
         size='small'
        type='number'
        variant="outlined"
        placeholder="&#8358; 0"
        sx={{backgroundColor:"whitesmoke",width:"500px"}}
        value={exchangeRate}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
           />
           
           <Button
      sx={{
      ml:1,
        
        color:"#fff",
       
        backgroundColor: "#32b59f",
        '&:hover': {
          backgroundColor: "#32b59f", 
        },
      }}
    onClick={handleUpdate}
    >
      Update  
    </Button>
            </Box>
            </Box>
      
        
    </Card>
    </>

  );
}
