
// import { useEffect, useState } from 'react';
// import ReactApexChart from 'react-apexcharts';
// import axios from 'axios';
// import { REST_API } from 'src/defaultValues';
// import { BaseOptionChart } from 'src/components/charts';
// import { MLinearProgress } from 'src/components/@material-extend';
// import { Box, Grid } from '@material-ui/core';
// import LoadingScreen from 'src/components/LoadingScreen';

// export default function SalesChart({selectedStatus,value}) {
//   // console.log("selectedStatus",selectedStatus);
//  const [isLoading, setIsLoading] = useState(true);
//   const [chartData, setChartData] = useState([]);
//   const [chartOptions, setChartOptions] = useState({
//     ...BaseOptionChart(),
//     stroke: { show: true, width: 2, colors: ['transparent'] },
//     tooltip: {
//       y: {
//         formatter(val) {
//           return `$ ${val} `;
//         }
//       },
//       x:{
//         formatter(val) {
//           return `Details`;
//         }
//       }
//     },
//     plotOptions: { bar: { columnWidth: '100%', borderRadius: 4 } }
//   });

// console.log("from",value[0])
// console.log("to",value[1])




  
//   useEffect(() => {
//     axios.get(`${REST_API}webservice/admin/get-purchased-products`)
//       .then(res => {
//         const data = res.data.data;
//         const monthlyTotals = {};
            
//         // Calculate monthly totals
//         data.forEach(item => {
//           const dateParts = item.date.split('-');
//           const month = parseInt(dateParts[1]) - 1; // Adjust month number to index
//           const monthName = getMonthName(month);
//           const year = dateParts[2];
//           const monthYear = `${monthName}-${year}`;
//           const amount = item.total;
  
//           if (monthlyTotals[monthYear]) {
//             monthlyTotals[monthYear] += amount;
//           } else {
//             monthlyTotals[monthYear] = amount;
//           }
//         });
  
//         // Prepare an array of all months to ensure they are included in the x-axis categories
//         const allMonths = Array.from({ length: 12 }, (_, i) => {
//           const monthName = getMonthName(i);
//           return `${monthName}-${new Date().getFullYear()}`; // Assuming you want to display the current year
//         });
  
//         let chartData = [];
//         let xAxisCategories = [];

       
  
//         if (!selectedStatus || selectedStatus.monthNumber === 0) {
//           // If selectedStatus is not defined or monthNumber is 0, show data for all months
//           xAxisCategories = allMonths;
//           chartData = allMonths.map(monthYear => ({
//             name: monthYear,
//             data: [monthlyTotals[monthYear] || 0]
//           }));
//         } else {
//           // Convert selected month number to month name
//           const selectedMonthName = getMonthName(selectedStatus.monthNumber - 1);
//           const selectedMonthYear = `${selectedMonthName}-${new Date().getFullYear()}`;
//           const selectedMonthAmount = monthlyTotals[selectedMonthYear] || 0;
  
//           xAxisCategories = [selectedMonthYear];
//           chartData = [{ name: selectedMonthYear, data: [selectedMonthAmount] }];
//         }

        
  
//         // Set x-axis categories dynamically based on the selected month or all months
//         setChartOptions(prevOptions => ({
//           ...prevOptions,
//           xaxis: { categories: xAxisCategories } // Include selected month or all months in x-axis categories
//         }));
  
//         // Set chart data
//         setChartData(chartData);
//       })
//       .catch(err => console.log(err))
//       .finally(() => {
       
//           setIsLoading(false);
      
//       })
//   }, [selectedStatus]);
  
// useEffect(() => {
//   console.log("valueeeeeeeeeeeee",value)
//   if(value[0] && value[1] !=null){


//   axios.get(`${REST_API}webservice/admin/get-purchased-products`)
//     .then(res => {
//       console.log("asddsfsfas",res.data)
//       const data = res.data.data;
//       const monthlyTotals = {};

//       // Calculate monthly totals
//       data.forEach(item => {
//         const dateParts = item.date.split('-');
//         const month = parseInt(dateParts[1]) - 1; // Adjust month number to index
//         const monthName = getMonthName(month);
//         const year = dateParts[2];
//         const monthYear = `${monthName}-${year}`;
//         const amount = item.total;

//         if (monthlyTotals[monthYear]) {
//           monthlyTotals[monthYear] += amount;
//         } else {
//           monthlyTotals[monthYear] = amount;
//         }
//       });

//       // Prepare an array of all months to ensure they are included in the x-axis categories
//       const allMonths = Array.from({ length: 12 }, (_, i) => {
//         const monthName = getMonthName(i);
//         return `${monthName}-${new Date().getFullYear()}`; // Assuming you want to display the current year
//       });

//       let chartData = [];
//       let xAxisCategories = allMonths; // Default to all months

//       // Filter chart data based on the date range specified by value
//       if (value && value.length === 2) {
//         const fromDate = new Date(value[0]);
//         const toDate = new Date(value[1]);

//         const filteredData = Object.keys(monthlyTotals)
//           .filter(monthYear => {
//             const [month, year] = monthYear.split('-');
//             const currentDate = new Date(`${month} 1, ${year}`);
//             return currentDate >= fromDate && currentDate <= toDate;
//           })
//           .reduce((obj, key) => {
//             obj[key] = monthlyTotals[key];
//             return obj;
//           }, {});

//         xAxisCategories = Object.keys(filteredData);
//         chartData = xAxisCategories.map(monthYear => ({
//           name: monthYear,
//           data: [filteredData[monthYear] || 0]
//         }));
//       } else {
//        // If value is not provided, display data for the selected month
// const selectedMonthName = getMonthName(selectedStatus.monthNumber - 1);
// const selectedMonthYear = `${selectedMonthName}-${new Date().getFullYear()}`;
// const selectedMonthAmount = monthlyTotals[selectedMonthYear] || 0;

// xAxisCategories = [selectedMonthYear];
// chartData = [{ name: selectedMonthYear, data: [selectedMonthAmount] }];

//       }

//       // Set x-axis categories dynamically based on the selected month, date range, or all months
//       setChartOptions(prevOptions => ({
//         ...prevOptions,
//         xaxis: { categories: xAxisCategories } // Include selected month, date range, or all months in x-axis categories
//       }));

//       // Set chart data
//       setChartData(chartData);
//     })
//     .catch(err => console.log(err))
  

//   }
// }, [value]);



  
//   const getMonthName = (monthNum) => {
//     const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
//     return months[monthNum];
//   };


//   return <ReactApexChart type="bar" series={chartData} options={chartOptions} height={450} />;
// }









// import moment from 'moment';
// import { useEffect, useState } from 'react';
// import ReactApexChart from 'react-apexcharts';
// import axios from 'axios';
// import { REST_API } from 'src/defaultValues';
// import { BaseOptionChart } from 'src/components/charts';

// export default function SalesChart({ selectedStatus, value }) {
//   const [isLoading, setIsLoading] = useState(true);
//   const [chartData, setChartData] = useState([]);
//   const [chartOptions, setChartOptions] = useState({
//     ...BaseOptionChart(),
//     stroke: { show: true, width: 2, colors: ['transparent'] },
//     tooltip: {
//       y: {
//         formatter(val) {
//           return `$ ${val}`;
//         }
//       },
//       x: {
//         formatter(val) {
//           return `Details`;
//         }
//       }
//     },
//     plotOptions: { bar: { columnWidth: '100%', borderRadius: 4 } },
//     xaxis: {
//       type: 'category',
//       categories: []
//     }
//   });

//   const processData = (data) => {
//     const dailyTotals = {};

//     // Calculate daily totals
//     data.forEach(item => {
//       if (item.timestamp) {
//         const date = moment(item.timestamp, 'DD-MM-YYYY').format('YYYY-MM-DD');
//         const amount = item.totalAmount;

//         if (dailyTotals[date]) {
//           dailyTotals[date] += amount;
//         } else {
//           dailyTotals[date] = amount;
//         }
//       }
//     });

//     return dailyTotals;
//   };

//   useEffect(() => {
//     axios.post(`${REST_API}webservice/admin/get-purchased-products-chart`)
//       .then(res => {
//         const data = res.data.data;
//         console.log(res.data.data, "Fetched data");
//         const dailyTotals = processData(data);

//         let chartData = [];
//         let xAxisCategories = [];

//         if (!selectedStatus || selectedStatus.monthNumber === 0) {
//           // If selectedStatus is not defined or monthNumber is 0, show data for all days
//           xAxisCategories = Object.keys(dailyTotals).sort((a, b) => new Date(a) - new Date(b));
//           chartData = [{
//             name: "Sales",
//             data: xAxisCategories.map(day => dailyTotals[day] || 0)
//           }];
//         } else {
//           // Filter data for the selected month
//           const selectedMonth = selectedStatus.monthNumber - 1;
//           const filteredData = Object.keys(dailyTotals)
//             .filter(date => moment(date).month() === selectedMonth)
//             .reduce((obj, key) => {
//               obj[key] = dailyTotals[key];
//               return obj;
//             }, {});

//           xAxisCategories = Object.keys(filteredData);
//           chartData = [{
//             name: "Sales",
//             data: xAxisCategories.map(day => filteredData[day] || 0)
//           }];
//         }

//         // Set x-axis categories dynamically based on the selected month or all days
//         setChartOptions(prevOptions => ({
//           ...prevOptions,
//           xaxis: { categories: xAxisCategories } // Include selected month or all days in x-axis categories
//         }));

//         // Set chart data
//         setChartData(chartData);
//       })
//       .catch(err => console.log(err))
//       .finally(() => {
//         setIsLoading(false);
//       });
//   }, [selectedStatus]);

//   useEffect(() => {
//     if (value && value[0] && value[1]) {
//       axios.post(`${REST_API}webservice/admin/get-purchased-products-chart`)
//         .then(res => {
//           const data = res.data.data;
//           const dailyTotals = processData(data);

//           let chartData = [];
//           let xAxisCategories = [];

//           // Filter chart data based on the date range specified by value
//           const fromDate = moment(value[0], 'MM/DD/YYYY');
//           const toDate = moment(value[1], 'MM/DD/YYYY');

//           const filteredData = Object.keys(dailyTotals)
//             .filter(date => {
//               const currentDate = moment(date, 'YYYY-MM-DD');
//               return currentDate.isBetween(fromDate, toDate, 'day', '[]');
//             })
//             .reduce((obj, key) => {
//               obj[key] = dailyTotals[key];
//               return obj;
//             }, {});

//           xAxisCategories = Object.keys(filteredData).sort((a, b) => new Date(a) - new Date(b));
//           chartData = [{
//             name: "Sales",
//             data: xAxisCategories.map(day => filteredData[day] || 0)
//           }];

//           // Set x-axis categories dynamically based on the date range
//           setChartOptions(prevOptions => ({
//             ...prevOptions,
//             xaxis: { categories: xAxisCategories } // Include selected date range in x-axis categories
//           }));

//           // Set chart data
//           setChartData(chartData);
//         })
//         .catch(err => console.log(err));
//     }
//   }, [value]);

//   return <ReactApexChart type="bar" series={chartData} options={chartOptions} height={450} />;
// }











import moment from 'moment';
import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import axios from 'axios';
import { REST_API } from 'src/defaultValues';
import { BaseOptionChart } from 'src/components/charts';

export default function SalesChart({ selectedStatus, value }) {
  const [isLoading, setIsLoading] = useState(true);
  const [chartData, setChartData] = useState([]);
  const [chartOptions, setChartOptions] = useState({
    ...BaseOptionChart(),
    stroke: { show: true, width: 2, colors: ['transparent'] },
    tooltip: {
      y: {
        formatter(val) {
          return `$ ${val}`;
        }
      },
      x: {
        formatter(val) {
          return `Details`;
        }
      }
    },
    plotOptions: { bar: { columnWidth: '100%', borderRadius: 4 } },
    xaxis: {
      type: 'category',
      categories: []
    }
  });

  const getMonthName = (monthNum) => {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return months[monthNum];
  };

  const processMonthlyData = (data) => {
    const monthlyTotals = {};

    data.forEach(item => {
      if (item.timestamp) {
        const date = moment(item.timestamp, 'DD-MM-YYYY');
        const monthYear = date.format('MMM-YYYY'); // Format as 'MMM-YYYY'
        const amount = item.totalAmount;

        if (monthlyTotals[monthYear]) {
          monthlyTotals[monthYear] += amount;
        } else {
          monthlyTotals[monthYear] = amount;
        }
      }
    });

    return monthlyTotals;
  };

  const processDailyData = (data) => {
    const dailyTotals = {};

    data.forEach(item => {
      if (item.timestamp) {
        const date = moment(item.timestamp, 'DD-MM-YYYY').format('YYYY-MM-DD');
        const amount = item.totalAmount;

        if (dailyTotals[date]) {
          dailyTotals[date] += amount;
        } else {
          dailyTotals[date] = amount;
        }
      }
    });

    return dailyTotals;
  };

  useEffect(() => {
    axios.post(`${REST_API}webservice/admin/get-purchased-products-chart`)
      .then(res => {
        const data = res.data.data;
        console.log(res.data.data, "Fetched data");
        const monthlyTotals = processMonthlyData(data);

        const allMonths = Array.from({ length: 12 }, (_, i) => {
          const monthName = getMonthName(i);
          return `${monthName}-${new Date().getFullYear()}`;
        });

        let chartData = [];
        let xAxisCategories = [];

        if (!selectedStatus || selectedStatus.monthNumber === 0) {
          xAxisCategories = allMonths;
          chartData = [{
            name: "Sales",
            data: allMonths.map(monthYear => monthlyTotals[monthYear] || 0)
          }];
        } else {
          const selectedMonthName = getMonthName(selectedStatus.monthNumber - 1);
          const selectedMonthYear = `${selectedMonthName}-${new Date().getFullYear()}`;
          const selectedMonthAmount = monthlyTotals[selectedMonthYear] || 0;

          xAxisCategories = [selectedMonthYear];
          chartData = [{ name: "Sales", data: [selectedMonthAmount] }];
        }

        setChartOptions(prevOptions => ({
          ...prevOptions,
          xaxis: { categories: xAxisCategories }
        }));

        setChartData(chartData);
      })
      .catch(err => console.log(err))
      .finally(() => {
        setIsLoading(false);
      });
  }, [selectedStatus]);

  useEffect(() => {
    if (value && value[0] && value[1]) {
      axios.post(`${REST_API}webservice/admin/get-purchased-products-chart`)
        .then(res => {
          const data = res.data.data;
          const dailyTotals = processDailyData(data);

          let chartData = [];
          let xAxisCategories = [];

          const fromDate = moment(value[0], 'MM/DD/YYYY');
          const toDate = moment(value[1], 'MM/DD/YYYY');

          const filteredData = Object.keys(dailyTotals)
            .filter(date => {
              const currentDate = moment(date, 'YYYY-MM-DD');
              return currentDate.isBetween(fromDate, toDate, 'day', '[]');
            })
            .reduce((obj, key) => {
              obj[key] = dailyTotals[key];
              return obj;
            }, {});

          xAxisCategories = Object.keys(filteredData).sort((a, b) => new Date(a) - new Date(b));
          chartData = [{
            name: "Sales",
            data: xAxisCategories.map(day => filteredData[day] || 0)
          }];

          setChartOptions(prevOptions => ({
            ...prevOptions,
            xaxis: { categories: xAxisCategories }
          }));

          setChartData(chartData);
        })
        .catch(err => console.log(err));
    }
  }, [value]);

  return <ReactApexChart type="bar" series={chartData} options={chartOptions} height={450} />;
}
