import faker from 'faker';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { useTheme } from '@material-ui/core/styles';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import {
  Box,
  Card,
  Table,
  Button,
  Divider,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  CardHeader,
  TableContainer,
  TextField,
  InputLabel, Select, MenuItem, Typography,
  Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText,
  TablePagination,
  Grid
} from '@material-ui/core';
// utils
import { fCurrency } from '../../../../utils/formatNumber';
//

import Label from '../../../Label';
import Scrollbar from '../../../Scrollbar';
import MoreMenuButton from '../../../MoreMenuButton';
import { Link , useLocation } from 'react-router-dom';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import axios from 'axios'
import { useEffect, useState } from 'react';
import { REST_API } from 'src/defaultValues';
import SupplierCountryAdd from './SupplierCountryAdd';
import { MButton, MLinearProgress } from '../../../../components/@material-extend';
import { useSnackbar } from 'notistack';
import { MIconButton } from 'src/components/@material-extend'
import { isConstructorDeclaration } from 'typescript';
import LoadingScreen from 'src/components/LoadingScreen';

// ----------------------------------------------------------------------



const randomDate = faker.date.past(); 
const INVOICES = [
  {
    id:1,
    countryName:"China"
  } ,
  {
    id:1,
    countryName:"Uk"
  } 
];

// ----------------------------------------------------------------------

export default function SupplierCountry() {
  const theme = useTheme();
  const navigate=useNavigate()
  const { enqueueSnackbar,closeSnackbar } = useSnackbar()
  const[data,setData]=useState([])
  const [updateCountryPage,setUpdateCountryPage]=useState(false)
  const[itemId,setItemId]=useState('')
  const [open, setOpen] = useState(false);
  const [countryId,setCountryId]=useState('')
  const[refresh,setRefresh]= useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(true);


  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5); // You can change the default rows per page if needed
 
  // Your existing filteredData and navigate function
 
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
 
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickOpen = (id) => {
    setOpen(true);
    setCountryId(id)
  };

  const handleClose = () => {
    setOpen(false);
  };


  useEffect(()=> {
     axios.get(`${REST_API}webservice/admin/get-country`)
     .then(res => {
      if(res.data.status===1) {
        console.log("get all country",res.data.data);
        setData(res.data.data);
        setRefresh(false)

      }else {
        console.log("not getting all country");
        setRefresh(false)

          
      }
     
     }).catch(err =>console.log(err))
     .finally(() => {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000); 
    })
  },[refresh])

  const handleUpdate=(id)=> {
  setUpdateCountryPage(true)
  setItemId(id)
  }

  const handleAdd=()=> {
    navigate(PATH_DASHBOARD.settings.supplierCountryAdd)
  }
  const handleDelete=(id)=> {
    axios.delete(`${REST_API}webservice/admin/delete-country/${id}`)
    .then(res => {
      if(res.data.status===1){
        window.location.reload()
        console.log(res.data)
        enqueueSnackbar('Country deleted successfully', {
          variant: 'error',
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
             
            </MIconButton>
          ),
        })
      }else {
        console.log("country not deleted");
      }
     
     
    })
    .catch(err =>console.log(err))
    }

    let filteredData = data;

    if (searchQuery) {
      filteredData = data.filter((row) => {
        // Convert the commission and search query to lowercase for case-insensitive comparison
        const commission = row.country.toLowerCase().trim();
        const searchQueryTrimmed = searchQuery.trim().toLowerCase();
    
        console.log("filteredData", filteredData);
        console.log("commission", commission);
        console.log("searchQuery", searchQueryTrimmed);
    
        return commission.includes(searchQueryTrimmed);
      });
    }


    if (isLoading) {
      return(
        <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <LoadingScreen />
      </Box>
    
      ) 
    }

    
  return (
    <>
    {updateCountryPage ?
    <SupplierCountryAdd setUpdateCountryPage={setUpdateCountryPage} update={true} itemId={itemId} setRefresh={setRefresh}/>
  :
  <>
    <Box sx={{m:2}}>
    <Typography sx={{color:"#32b59f",fontSize:"small"}}>Settings / Supplier Country</Typography>
   </Box>
    <Card>  
      <Box sx={{display:"flex",justifyContent:"space-between"}}>
      <CardHeader title="All" sx={{ mb: 1 }} />
      <Button
      sx={{
        mr:3,
        mt:3,
        height:"40px",
        color:"#fff",
        backgroundColor: "#32b59f",
        '&:hover': {
          backgroundColor: "#32b59f", 
        },
      }}
      onClick={handleAdd}
    >
      Add  
    </Button>
        </Box>
   
      <Box sx={{width:"100%",p:3}}>
        <Box>


        <TextField
         size='small'
  variant="outlined"
  placeholder="Search by country..."
  sx={{ backgroundColor: "whitesmoke" }}
  onChange={(e) => setSearchQuery(e.target.value)}
  InputProps={{
    endAdornment: (
      <InputAdornment position="end">
        <SearchIcon />
      </InputAdornment>
    ),
  }}
/>
        </Box>
      </Box>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Do you want to Delete the country name</DialogTitle>
        
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={()=>handleDelete(countryId)} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>

      <Scrollbar>
        <TableContainer sx={{ minWidth: 720 }}>
          <Table size='small'>
            <TableHead >
              <TableRow >
                <TableCell >Country Name</TableCell>
                <TableCell align='center'>Supplier Country's Tax</TableCell>
                <TableCell align='center'>EDD (Days)</TableCell>
                <TableCell></TableCell>

                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
            {filteredData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                <TableRow key={row.id} sx={{borderBottom:'1px solid #e6e6e6',m:0,p:0}}>
                  <TableCell>{row.country}</TableCell>
                  <TableCell align='center'>{row.salesTax} %</TableCell>
                  <TableCell align='center'>{row.edd}</TableCell>

                  <TableCell align='right'>
                    {/* <MoreMenuButton /> */}
                   {/* <Link underline="none" component={RouterLink} to={PATH_DASHBOARD.user.product}> */}
                   <Button
                         sx={{
                           mr:3,
                          
                           
                           color:"#fff",
                           fontSize:"small",
                           backgroundColor: "#32b59f",
                           '&:hover': {
                             backgroundColor: "#32b59f", 
                           },
                         }}
                        className='css-3jmj6j-MuiButtonBase-root-MuiButton-root1'

                       onClick={()=>handleUpdate(row.id)}
                       >
                         Edit  
                       </Button>

                       <Button 
                       sx={{
                         mr:3,
                    
                         fontSize:"small",
                        
                         color:"#fff",
                         backgroundColor: "#be4c4c",
                         '&:hover': {
                           backgroundColor: "#be4c4c", 
                         },
                       }}
                       className='css-3jmj6j-MuiButtonBase-root-MuiButton-root1'

                       onClick={()=>handleClickOpen(row.id)}
                       
                     >
                       Delete  
                      </Button>
                    {/* </Link> */}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Scrollbar>

      <Divider />

      <Box sx={{ p: 2, textAlign: 'right' }}>
        {/* <Button
          to="#"
          size="small"
          color="inherit"
          component={RouterLink}
          endIcon={<Icon icon={arrowIosForwardFill} />}
        >
          View All
        </Button> */}
      </Box>
    </Card>
    </>
  }
    </>

  );
}
