import * as Yup from 'yup'
import { useSnackbar } from 'notistack'
import { useCallback, useEffect, useState } from 'react'
import { Form, FormikProvider, useFormik } from 'formik'
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom'

// material
import {
  Box,
  Grid,
  Card,
  Stack,
  Switch,
  TextField,
  FormControlLabel,
  Typography,
  FormHelperText,
  Autocomplete,
  InputAdornment,
  Divider,
} from '@material-ui/core'
import { LoadingButton } from '@material-ui/lab'
// hooks
import useAuth from '../../../../hooks/useAuth'
import useIsMountedRef from '../../../../hooks/useIsMountedRef'
import { UploadAvatar, UploadSingleFile } from '../../../upload'
// utils
import { fData } from '../../../../utils/formatNumber'
//
// import countries from '../../../../countries';
import countries from '../../user/countries'
import ProductTimeline from './ProductTimeLine'
import { min } from 'lodash'
import { Button } from '@mui/material'
import { Link } from 'react-router-dom'
import { PATH_DASHBOARD } from 'src/routes/paths'
import { styled } from '@material-ui/styles'

import axios from 'axios'
import { REST_API } from 'src/defaultValues'
import StatusDecriptionImg from './StatusDecriptionImg'
import { MLinearProgress } from 'src/components/@material-extend'
import LoadingScreen from 'src/components/LoadingScreen'

// ----------------------------------------------------------------------
const top100Films = [
  { Pickup: 'Abuja', Supplier: 'Us', type: 'physical' },
  { Pickup: 'Lagos', Supplier: 'South Africa', type: 'Digital' },
]

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}))

export default function ViewProductdetails() {
  const isMountedRef = useIsMountedRef()
  const { enqueueSnackbar } = useSnackbar()
  const { user, updateProfile } = useAuth()
  const [type, setType] = useState(false)
  const [unitCost, setUnitCost] = useState('')
  const [quantity, setQuantity] = useState('')
  const [amount, setAmount] = useState('')
  const [length, setLength] = useState('')
  const [bredth, setBredth] = useState('')
  const [height, setHeight] = useState('')
  const [total, setTotal] = useState('')
  const [agreement, setAgreement] = useState(false)
  const [agree, setAgree] = useState(false)
  const [supCountries, setSupCountries] = useState([])
  const [pickupLocations, setPickupLocations] = useState([])
  const [forwarderFees, setForwarderFees] = useState([])
  const [totalForwarderFee, setTotalForwarderFee] = useState()
  const [subTotal, setSubTotal] = useState(0)
  const [serviceChargeData, setServiceChargeData] = useState([])
  const [totalServiceFee, setTotalServiceFee] = useState()
  const [pickupLocationFee, setPickupLocationFee] = useState()
  const [vatData, setVatData] = useState([])
  const [vatRate, setVatRate] = useState(0)
  const [save, setSave] = useState(2)
  const [viewData, setViewData] = useState([])
  const [countryName, setCountryName] = useState()
  const [prefferredLocation, setPrefferedLocation] = useState()
  const navigate = useNavigate()
  const { id } = useParams()
 const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    axios
      .get(`${REST_API}webservice/admin/get-country`)
      .then((res) => {
        if (res.data.status === 1) {
          console.log('get all country', res.data.data)
          setSupCountries(res.data.data)
        } else {
          console.log('not getting all country')
        }
      })
      .catch((err) => console.log(err))

    axios
      .get(`${REST_API}webservice/admin/getAll-pickup-location`)
      .then((res) => {
        if (res.data.status === 1) {
          console.log('get all pickup locaion', res.data)
          setPickupLocations(res.data.data)
        } else {
          console.log('not getting pickup location')
        }
      })
      .catch((err) => console.log(err))

    axios
      .get(`${REST_API}webservice/admin/getAll-forwarder-fee`)
      .then((res) => {
        if (res.data.status === 1) {
          console.log('getAll-forwarder-fee', res.data.data)
          setForwarderFees(res.data.data)
        } else {
          console.log('not getting forwarder fee')
        }
      })
      .catch((err) => console.log(err))

    axios
      .get(`${REST_API}webservice/admin/getAll-service-fee`)
      .then((res) => {
        if (res.data.status === 1) {
          console.log('get all service fee', res.data)
          setServiceChargeData(res.data.data)
        } else {
          console.log('not getting service fee')
        }
      })
      .catch((err) => console.log(err))

    axios.get(`${REST_API}webservice/admin/getAll-vat`).then((res) => {
      if (res.data.status === 1) {
        console.log('get all vat', res.data.data)
        setVatData(res.data.data)
      } else {
        console.log('not getting vat details')
      }
    })
  }, [])

  const handleLength = (e) => {
    const L = e.target.value
    setLength(L)
    calculateTotal(L, bredth, height)
    setFieldValue('lInches', L)
  }

  const handleBredth = (e) => {
    const B = e.target.value
    setBredth(B)
    calculateTotal(length, B, height)
    setFieldValue('bInches', B)
  }

  const handleHeight = (e) => {
    const H = e.target.value
    setHeight(H)
    calculateTotal(length, bredth, H)
    setFieldValue('hInches', H)
  }

  const calculateTotal = (L, B, H) => {
    const LBH = L * B * H
    setTotal(LBH)
    setFieldValue('weight', LBH)
  }
  const handleUnitCostChange = (e) => {
    const cost = e.target.value
    setUnitCost(cost)
    calculateAmount(cost, quantity)
    setFieldValue('unitCost', cost)
  }

  const handleQuantityChange = (e) => {
    const qty = e.target.value
    setQuantity(qty)
    calculateAmount(unitCost, qty)
    setFieldValue('quantity', qty)
    setFieldValue('ForwarderFee', totalForwarderFee)
    // console.log('quantity ForwarderFeeeee', totalForwarderFee);
  }

  const calculateAmount = (cost, qty) => {
    const total = qty ? cost * qty : cost

    setAmount(total)
    setFieldValue('amount', total)
    setSubTotal(total)

    setFieldValue('subtotal', subTotal)
    // setFieldValue('ForwarderFee', totalForwarderFee);

    console.log('ForwarderFeeeee', totalForwarderFee)
  }

  // console.log("amount Values",subTotal , totalForwarderFee ,totalServiceFee , pickupLocationFee , vatRate);
  let subTotalValue = subTotal ? Number(subTotal) : 0
  let forwarderFeeValue = totalForwarderFee ? Number(totalForwarderFee) : 0
  let serviceFeeValue = totalServiceFee ? Number(totalServiceFee) : 0
  let pickupFeeValue = pickupLocationFee ? Number(pickupLocationFee) : 0
  let vatRateValue = vatRate ? Number(vatRate) : 0

  const fullTotal =
    subTotalValue +
    forwarderFeeValue +
    serviceFeeValue +
    pickupFeeValue +
    vatRateValue

  //  console.log("fullTotal", fullTotal.toFixed(2));

  useEffect(() => {
    ////////////////////////////////////////Forwarder Fee

    //  const totalAmount = !quantity ? unitCost : unitCost * quantity;
    const totalAmount = subTotal

    let foundFee = false

    const weight = values.actualWeight ? values.actualWeight : values.weight

    forwarderFees.forEach((feeObj) => {
      if (weight >= feeObj.setWeightFrom && weight <= feeObj.setWeightTo) {
        setTotalForwarderFee(feeObj.fee)

        foundFee = true
        return
      }
      /////////////////////////////////////////////////Service Fee
      serviceChargeData.forEach((data) => {
        if (totalAmount >= data.amountFrom && totalAmount <= data.amountTo) {
          const persentage = data.commission / 100
          const ans = totalAmount * persentage
          // console.log("ans",ans.toFixed(2));
          setTotalServiceFee(parseInt(ans.toFixed(2)))
        }
      })

      /////////////////////////////////////////////////Pickup Location
      //  console.log("preffeeee",values.prefferedLocation?.state);
      pickupLocations.forEach((data) => {
        if (data.state === values.prefferedLocation?.state) {
          // console.log("cost:", data.cost);
          setPickupLocationFee(parseInt(data.cost))
        }
      })

      vatData.forEach((data) => {
        if (data.name === 'VAT') {
          // console.log(data.percentage,"VAT Percentagr");
          const percentage = data.percentage / 100
          //  console.log("percentage",percentage);

          setVatRate(totalServiceFee * percentage)
          //  console.log("vatRate", vatRate);
        }
      })
    })
    //  if (!foundFee) {
    //      console.log("No matching fee found for totalAmount:", totalAmount);
    //      setTotalForwarderFee(0)
    //  }
  }, [
    unitCost,
    quantity,
    handleUnitCostChange,
    handleQuantityChange,
    forwarderFees,
  ])

  useEffect(() => {
    axios.get(`${REST_API}webservice/admin/get-product/${id}`)
    .then((res) => {
      console.log('productData-------------', res.data.result)
      setViewData(res.data.result)
    }).catch(err =>console.log(err))
    .finally(() => {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000); 
    })
  }, [])

  useEffect(() => {
    axios
      .get(
        `${REST_API}webservice/admin/get-country/${viewData.supplier_country}`,
      )
      .then((res) => {
        //   console.log("edit data - ",res.data.result)
        setCountryName(res.data.result.country)
      })
      .catch((err) => console.log(err))

    axios
      .get(
        `${REST_API}webservice/admin/get-pickup-location/${viewData.preffered_location}`,
      )
      .then((res) => {
        console.log('=========', res.data.result)
        setPrefferedLocation(res.data.result.state)
      })
      .catch((err) => console.log(err))
  }, [viewData])

  const UpdateUserSchema = Yup.object().shape({
    unitCost: Yup.number()
      .required('Unit cost is required')
      .min(1, 'value should be greater than one'),
    quantity: Yup.number()
      .required('Quantity is required')
      .min(1, 'quantity should be greater than one'),
    type: Yup.object().nullable().required('Type of item is required'),
    prefferedLocation: Yup.object()
      .nullable()
      .required('Preffered Location is required'),
    suplierCountry: Yup.object()
      .nullable()
      .required('Supplier Country is required'),
    itemName: Yup.string().required('Item Name is required'),
    supplierName: Yup.string().required('Supplier Name is required'),
  })
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      type: viewData ? viewData.type_of_item : null,
      itemName: viewData ? viewData.item_name : '',
      supplierName: viewData ? viewData.supplier_name : '',
      unitCost: viewData ? viewData.unit_cost : 0,
      quantity: viewData ? viewData.quantity : 0,
      amount: viewData ? viewData.amount : null,
      costofShipping: viewData ? viewData.cost_of_shipping : null,
      weight: viewData ? viewData.weight : null,
      actualWeight: viewData ? viewData.actual_weight : null,
      lInches: viewData ? viewData.l_inches : null,
      bInches: viewData ? viewData.b_inches : null,
      hInches: viewData ? viewData.h_inches : null,
      suplierCountry: viewData ? countryName : null,
      prefferedLocation: viewData ? prefferredLocation : null,
      subtotal: viewData ? viewData.sub_total : null,
      ForwarderFee: viewData ? viewData.forwarder_fee : 0,
      ServiceCharge: viewData ? viewData.service_charge : 0,
      vat: viewData ? viewData.vat : 0,
      customerNote: viewData ? viewData.customer_note : null,
      ShopAmericaNotes: viewData ? viewData.shop_america_note : null,
      pickupLocation: viewData ? viewData.delivery_to_pickup_location : 0,
      total: viewData ? viewData.total : null,
      //   userId:userId,
      status: viewData ? viewData.status : '',
      itemLink: viewData ? viewData.item_link : '',
      supplierCountryTax:viewData? viewData?.sales_tax : null,
      image:viewData? `${REST_API}${viewData.image}`:null,
      pdfDocument:viewData? `${REST_API}${viewData?.pdfDocument}`:null,

    },

    validationSchema: UpdateUserSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        values.ForwarderFee = totalForwarderFee
        values.ServiceCharge = totalServiceFee
        values.pickupLocation = pickupLocationFee
        values.vat = vatRate.toFixed(2)
        values.total = fullTotal.toFixed(2)
        console.log('Form values:', values)

        // await updateProfile({ ...values });
        axios
          .post(`${REST_API}webservice/admin/add-new-product`, { values, save })
          .then((res) => {
            if (res.data.status === 1) {
              console.log(res.data.message)
              enqueueSnackbar(res.data.message, { variant: 'success' })
              navigate(PATH_DASHBOARD.general.app)
            } else {
              console.log(res.data.message)
              enqueueSnackbar(res.data.message, { variant: 'error' })
            }
          })

        if (isMountedRef.current) {
          setSubmitting(false)
        }
      } catch (error) {
        if (isMountedRef.current) {
          setErrors({ afterSubmit: error.code })
          setSubmitting(false)
        }
      }
    },
  })

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0]
      if (file) {
        setFieldValue('photoURL', {
          ...file,
          preview: URL.createObjectURL(file),
        })
      }
    },
    [setFieldValue],
  )
  const handleSave = () => {
    enqueueSnackbar('Item Saved', { variant: 'success' })
  }
  const handleAgrementSubmit = () => {
    setAgreement(!agreement)
  }

  const handleAgree = async () => {
    try {
      await UpdateUserSchema.validate(formik.values, { abortEarly: false })
      setSave(1)
      setAgree(true)
      handleSubmit()
    } catch (error) {
      alert('Please fill in all required fields.')
      console.error('Validation Error:', error)
    }
  }

  const handleDisAgree = () => {
    setAgree(false)
    setAgreement(false)
  }

  let theAmount = values.quantity
    ? values.unitCost * values.quantity
    : values.unitCost
  let theSubTotal = values.costofShipping
    ? Number(theAmount) + Number(values.costofShipping)
    : theAmount
  //   let theFinalTotal=  Number(theSubTotal) + Number(totalForwarderFee)
  //                       + Number(totalServiceFee) + Number(pickupLocationFee) + Number(vatRate)

  let theFinalTotal = 0

  // Check and add theSubTotal if available
  if (!isNaN(theSubTotal)) {
    theFinalTotal += Number(theSubTotal)
  }

  // Check and add totalForwarderFee if available
  if (!isNaN(totalForwarderFee)) {
    theFinalTotal += Number(totalForwarderFee)
  }

  // Check and add totalServiceFee if available
  if (!isNaN(totalServiceFee)) {
    theFinalTotal += Number(totalServiceFee)
  }

  // Check and add pickupLocationFee if available
  if (!isNaN(pickupLocationFee)) {
    theFinalTotal += Number(pickupLocationFee)
  }

  // Check and add vatRate if available
  if (!isNaN(vatRate)) {
    theFinalTotal += Number(vatRate)
  }

  const statusDetails = [
    { status: 'Awaiting Approval' },
    { status: 'Approved for Payment' },
    { status: 'Payment Made' },
    { status: 'Order Placed' },
    { status: 'Ready for Shipment' },
    { status: 'Undergoing Clearing' },
    { status: 'Ready for Pickup' },
    { status: 'Marked for Demurrage' },
    { status: 'Marked for Auction' },
  ]



  function formatMoney(amount) {
    // Check if amount is defined and not null
    if (amount === undefined || amount === null) {
      return ''; // Return empty string or handle the case appropriately
    }
  
    // Split the amount into integer and decimal parts
    const parts = amount.toString().split('.');
    const integerPart = parts[0];
    let decimalPart = parts.length > 1 ? parts[1] : '';
  
    // If decimalPart is empty or has only one digit, add a trailing zero
    if (decimalPart.length === 0) {
      decimalPart = '00';
    } else if (decimalPart.length === 1) {
      decimalPart = decimalPart + '0'; // Append a zero for single-digit decimal
    } else {
      decimalPart = decimalPart.slice(0, 2); // Take only the first two digits
    }
  
    // Add commas every three digits from the right
    const integerWithCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  
    // Concatenate integer and decimal parts and return
    return integerWithCommas + '.' + decimalPart;
  }


  if (isLoading) {
    return(
      <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <LoadingScreen />
    </Box>
  
  
    ) 
  }

  return (
    <FormikProvider value={formik}>
      {/* <Modal
      open={agreement}
      handleClose={()=>{
        setAgreement(false)
      }}
      modalTitle={agree ? "Order Placed" :'Service Agreements'}>
        <>
        <Divider/>
        <Box sx={{display:"flex",justifyContent:"center",alignItems:"center",mt:3}}> 
          <Typography color={agree ?"green":""}>{agree ? "Your order has been submitted and being reviewed you shall get an invoice shortly" :"Service Agreement ..."}</Typography>
        </Box>
        <Box sx={{display:"flex",justifyContent:"end",mt:3}}>
          {
            agree ? "" :
            (
            <>
               <Button variant='contained' sx={{mr:3}} onClick={handleDisAgree}>No, i do not Agree</Button>
               
               <Button variant='contained' onClick={handleAgree}>Yes, i Agree</Button>
               </> )
          }
        </Box>
        </>
      </Modal> */}
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          {/* <Grid item xs={12} md={4}>
          {viewData.saved ==1 ?
          <Typography sx={{display:{color:"#32b59f"}}}>Order Status  </Typography>
          :""
} */}
          {/* <Card sx={{ py: 10, px: 3, textAlign: 'center' }}>
            {viewData.saved ==1 ?  
              <ProductTimeline view={true} date={viewData.date}/>
            :""} */}

          {/* <FormHelperText error sx={{ px: 2, textAlign: 'center' }}>
                {touched.photoURL && errors.photoURL}
              </FormHelperText>
          </Grid> */}

          {/* <Grid item xs={12} md={viewData.saved===1 ? 8 : 12}> */}
      

          <Grid item xs={12} md={4}>
          {viewData.saved ==1 || viewData.saved==3 ?
          <Typography sx={{display:{color:"#32b59f"}}}>Order Status  </Typography>
          :""
}
            {/* <Card sx={{ py: 10, px: 3, textAlign: 'center' }}> */}
            {viewData.saved ==1 || viewData.saved==3 ?  
              <ProductTimeline  date={viewData.date} status={viewData?.status} productId={viewData?.id} countryId={viewData.supplier_country}/>
            :""}

              {/* <FormHelperText error sx={{ px: 2, textAlign: 'center' }}>
                {touched.photoURL && errors.photoURL}
              </FormHelperText> */}
          </Grid>
             


          {/* <Grid item xs={12} md={12}> */}
          <Grid item xs={12} md={viewData.saved===1 || viewData.saved===3 ? 8 : 12} sx={{mt:-2}}>
            <Typography sx={{ display: { color: '#32b59f' } }}>
              Item Description{' '}
            </Typography>

            <Card sx={{ p: 3 }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <TextField
                  label="Status"
                  disabled
                  placeholder="Select Status"
                  defaultValue={'Status'}
                  {...getFieldProps('status')}
                  error={Boolean(touched.status && errors.status)}
                  helperText={touched.status && errors.status}
                />
              </Box>
              <Stack spacing={{ xs: 2, md: 3 }} sx={{ mt: 3 }}>
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <Box sx={{ width: '100%' }}>
                    <LabelStyle>Item Link</LabelStyle>
                    <TextField
                      fullWidth
                      placeholder="Paste Item Link"
                      sx={{ color: 'blue' }}
                      disabled
                      defaultValue={'Link'}
                      {...getFieldProps('itemLink')}
                      error={Boolean(touched.itemLink && errors.itemLink)}
                      helperText={touched.itemLink && errors.itemLink}
                    />
                  </Box>
                </Stack>
              


                {
                      viewData.image ?

                      <StatusDecriptionImg image={values.image}/>
                    : "" 
                    }





                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  {/* <Autocomplete
                    fullWidth
                    options={top100Films}
                    getOptionLabel={(option) => option.type}
                    renderInput={(params) => <TextField {...params} label="Type Of Item" 
                    error={Boolean(touched.type && errors.type)}
                    helperText={touched.type && errors.type}/>}
                    value={values.type}
                    onChange={(event, newValue) => {
                      setFieldValue('type', newValue);
                      if (newValue && newValue.type === "Digital") {
                        setType(true);
                      } else {
                        setType(false);
                      }
                    }}
                  /> */}
                  <Box sx={{ width: '100%' }}>
                    <LabelStyle>Type of Item</LabelStyle>
                    <TextField
                      fullWidth
                      disabled
                      placeholder="Enter Type Of Item"
                      {...getFieldProps('type')}
                      error={Boolean(touched.type && errors.type)}
                      helperText={touched.type && errors.type}
                    />
                  </Box>

                  <Box sx={{ width: '100%' }}>
                    <LabelStyle>Supplier Country</LabelStyle>
                    <TextField
                      fullWidth
                      disabled
                      {...getFieldProps('suplierCountry')}
                      error={Boolean(
                        touched.suplierCountry && errors.suplierCountry,
                      )}
                      helperText={
                        touched.suplierCountry && errors.suplierCountry
                      }
                    />
                  </Box>
                  <Box sx={{ width: '100%' }}>
                    <LabelStyle>Preferred Pickup Location</LabelStyle>
                    <TextField
                      fullWidth
                      disabled
                      {...getFieldProps('prefferedLocation')}
                      error={Boolean(
                        touched.prefferedLocation && errors.prefferedLocation,
                      )}
                      helperText={
                        touched.prefferedLocation && errors.prefferedLocation
                      }
                    />
                  </Box>



                </Stack>

                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <Box sx={{ width: '100%' }}>
                    <LabelStyle>Item Name</LabelStyle>
                    <TextField
                      fullWidth
                      disabled
                      placeholder="Enter Item Name"
                      {...getFieldProps('itemName')}
                      error={Boolean(touched.itemName && errors.itemName)}
                      helperText={touched.itemName && errors.itemName}
                    />
                  </Box>

                  <Box sx={{ width: '100%' }}>
                    <LabelStyle>Supplier</LabelStyle>
                    <TextField
                      fullWidth
                      disabled
                      placeholder="Enter Supplier Name"
                      {...getFieldProps('supplierName')}
                      error={Boolean(
                        touched.supplierName && errors.supplierName,
                      )}
                      helperText={touched.supplierName && errors.supplierName}
                    />
                  </Box>
                </Stack>

                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  {/* <Box sx={{ width: '100%' }}>
                    <LabelStyle>Supplier Country</LabelStyle>
                    <TextField
                      fullWidth
                      disabled
                      {...getFieldProps('suplierCountry')}
                      error={Boolean(
                        touched.suplierCountry && errors.suplierCountry,
                      )}
                      helperText={
                        touched.suplierCountry && errors.suplierCountry
                      }
                    />
                  </Box> */}
                </Stack>

                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <Box sx={{ width: '100%' }}>
                    <LabelStyle>Unit Cost</LabelStyle>
                    <TextField
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      disabled
                      {...getFieldProps('unitCost')}
                      error={Boolean(touched.unitCost && errors.unitCost)}
                      helperText={touched.unitCost && errors.unitCost}
                    />
                  </Box>

                  <Box sx={{ width: '100%' }}>
                    <LabelStyle>Quantity</LabelStyle>
                    <TextField
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start"></InputAdornment>
                        ),
                      }}
                      disabled
                      {...getFieldProps('quantity')}
                      error={Boolean(touched.quantity && errors.quantity)}
                      helperText={touched.quantity && errors.quantity}
                    />
                  </Box>

                  <Box sx={{ width: '100%' }}>
                    <LabelStyle>Amount</LabelStyle>
                    <TextField
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      disabled
                      {...getFieldProps('amount')}
                      error={Boolean(touched.amount && errors.amount)}
                      helperText={touched.amount && errors.amount}
                    />
                  </Box>
                </Stack>
              

                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  
                  <TextField sx={{backgroundColor:"whitesmoke"}} fullWidth 
                                   defaultValue={"$0.00"} placeholder="$0.00"  disabled label='Supplier Country Tax'
                                  {...getFieldProps('supplierCountryTax')}  
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        $
                                      </InputAdornment>
                                    ),
                                  }}  
                                  error={Boolean(touched.supplierCountryTax && errors.supplierCountryTax)}
                                  helperText={touched.supplierCountryTax && errors.supplierCountryTax}/>

<Box sx={{ width: '100%' }}>
                    {/* <LabelStyle>
                      Cost of Shipping item from supplier to forwarder
                    </LabelStyle> */}
                    <TextField
                      fullWidth
                      label='Cost of Shipping item from supplier to forwarder'
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      disabled
                      {...getFieldProps('costofShipping')}
                      error={Boolean(
                        touched.costofShipping && errors.costofShipping,
                      )}
                      helperText={
                        touched.costofShipping && errors.costofShipping
                      }
                    />
                  </Box>
    
    </Stack>


                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <Box sx={{ width: '100%' }}>
                    <LabelStyle>
                      Cost of Shipping item from supplier to forwarder
                    </LabelStyle>
                    <TextField
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      disabled
                      {...getFieldProps('costofShipping')}
                      error={Boolean(
                        touched.costofShipping && errors.costofShipping,
                      )}
                      helperText={
                        touched.costofShipping && errors.costofShipping
                      }
                    />
                  </Box>
                </Stack>

                {values.type === 'Digital' ? (
                  ''
                ) : (
                  <>
                    <Box sx={{ width: '100%' }}>
                      <LabelStyle>Actual weight of item</LabelStyle>
                      <TextField
                        fullWidth
                        disabled
                        {...getFieldProps('actualWeight')}
                        error={Boolean(
                          touched.actualWeight && errors.actualWeight,
                        )}
                        helperText={touched.actualWeight && errors.actualWeight}
                      />
                    </Box>

                    <Typography fontSize={'medium'}>
                      Volumetric weight of item (pounds)
                    </Typography>
                    <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                      <Box sx={{ width: '100%' }}>
                        <LabelStyle>L(inches)</LabelStyle>
                        <TextField
                          fullWidth
                          disabled
                          {...getFieldProps('lInches')}
                          InputProps={{
                            type: 'number',
                            inputProps: {
                              min: 0,
                            },
                          }}
                          onChange={handleLength}
                          error={Boolean(touched.lInches && errors.lInches)}
                          helperText={touched.lInches && errors.lInches}
                        />
                      </Box>

                      <Box sx={{ width: '100%' }}>
                        <LabelStyle>B(inches)</LabelStyle>
                        <TextField
                          fullWidth
                          disabled
                          {...getFieldProps('bInches')}
                          InputProps={{
                            type: 'number',
                            inputProps: {
                              min: 0,
                            },
                          }}
                          onChange={handleBredth}
                          error={Boolean(touched.bInches && errors.bInches)}
                          helperText={touched.bInches && errors.bInches}
                        />
                      </Box>

                      <Box sx={{ width: '100%' }}>
                        <LabelStyle>H(inches)</LabelStyle>
                        <TextField
                          fullWidth
                          disabled
                          {...getFieldProps('hInches')}
                          InputProps={{
                            type: 'number',
                            inputProps: {
                              min: 0,
                            },
                          }}
                          onChange={handleHeight}
                          error={Boolean(touched.hInches && errors.hInches)}
                          helperText={touched.hInches && errors.hInches}
                        />
                      </Box>
                    </Stack>
                    <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                      <Box sx={{ width: '100%' }}>
                        <LabelStyle>Weight calculated by system</LabelStyle>
                        <TextField
                          fullWidth
                          disabled
                          {...getFieldProps('weight')}
                          InputProps={{
                            type: 'number',
                            inputProps: {
                              min: 0,
                            },
                          }}
                          error={Boolean(touched.weight && errors.weight)}
                          helperText={touched.weight && errors.weight}
                        />
                      </Box>
                    </Stack>
                  </>
                )}

                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  {/* <Box sx={{ width: '100%' }}>
                    <LabelStyle>Supplier Country</LabelStyle>
                    <TextField
                      fullWidth
                      disabled
                      {...getFieldProps('suplierCountry')}
                      error={Boolean(
                        touched.suplierCountry && errors.suplierCountry,
                      )}
                      helperText={
                        touched.suplierCountry && errors.suplierCountry
                      }
                    />
                  </Box>
                  <Box sx={{ width: '100%' }}>
                    <LabelStyle>Preferred Pickup Location</LabelStyle>
                    <TextField
                      fullWidth
                      disabled
                      {...getFieldProps('prefferedLocation')}
                      error={Boolean(
                        touched.prefferedLocation && errors.prefferedLocation,
                      )}
                      helperText={
                        touched.prefferedLocation && errors.prefferedLocation
                      }
                    />
                  </Box> */}
                </Stack>

                <Stack
                  direction={{ xs: 'column', md: 'row' }}
                  spacing={2}
                  display={'flex'}
                  justifyContent={'end'}
                >
                  <Box sx={{ width: '100%' }}>
                    <LabelStyle>Sub Total</LabelStyle>
                    <TextField
                      placeholder="$0.00"
                      disabled
                      {...getFieldProps('subtotal')}
                      error={Boolean(touched.subtotal && errors.subtotal)}
                      helperText={touched.subtotal && errors.subtotal}
                    />
                  </Box>
                </Stack>
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <Typography
                    sx={{
                      width: '50%',
                      color: '#32b59f',
                      display: { xs: 'none', md: 'flex' },
                    }}
                  >
                    Shipping Charges
                  </Typography>
                  <Typography
                    sx={{
                      width: '50%',
                      color: '#32b59f',
                      display: { xs: 'none', md: 'flex' },
                    }}
                    fullWidth
                  >
                    Service Charges
                  </Typography>
                </Stack>

                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <Typography
                    sx={{
                      width: '50%',
                      color: '#32b59f',
                      display: { xs: 'flex', md: 'none' },
                    }}
                    fullWidth
                  >
                    Shipping Charges
                  </Typography>

                  <TextField
                    sx={{ backgroundColor: 'whitesmoke' }}
                    fullWidth
                    label="Forwarder Fee"
                    placeholder="$0.00"
                    disabled
                    {...getFieldProps('ForwarderFee')}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    error={Boolean(touched.ForwarderFee && errors.ForwarderFee)}
                    helperText={touched.ForwarderFee && errors.ForwarderFee}
                  />

                  <TextField
                    sx={{
                      backgroundColor: 'whitesmoke',
                      display: { xs: 'none', md: 'flex' },
                    }}
                    fullWidth
                    label="Service Charge"
                    placeholder="$0.00"
                    disabled
                    {...getFieldProps('ServiceCharge')}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    error={Boolean(
                      touched.ServiceCharge && errors.ServiceCharge,
                    )}
                    helperText={touched.ServiceCharge && errors.ServiceCharge}
                  />

                  <TextField
                    sx={{
                      backgroundColor: 'whitesmoke',
                      display: { xs: 'flex', md: 'none' },
                    }}
                    fullWidth
                    label="Delivery to pickup Location"
                    placeholder="$0.00"
                    disabled
                    {...getFieldProps('pickupLocation')}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    error={Boolean(
                      touched.pickupLocation && errors.pickupLocation,
                    )}
                    helperText={touched.pickupLocation && errors.pickupLocation}
                  />
                </Stack>

                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <Typography
                    sx={{
                      width: '50%',
                      color: '#32b59f',
                      display: { xs: 'flex', md: 'none' },
                    }}
                    fullWidth
                  >
                    Service Charges
                  </Typography>

                  <TextField
                    sx={{
                      backgroundColor: 'whitesmoke',
                      display: { xs: 'none', md: 'flex' },
                    }}
                    fullWidth
                    label="Delivery to pickup Location"
                    placeholder="$0.00"
                    disabled
                    {...getFieldProps('pickupLocation')}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    error={Boolean(
                      touched.pickupLocation && errors.pickupLocation,
                    )}
                    helperText={touched.pickupLocation && errors.pickupLocation}
                  />

                  <TextField
                    sx={{
                      backgroundColor: 'whitesmoke',
                      display: { xs: 'flex', md: 'none' },
                    }}
                    fullWidth
                    label="Service Charge"
                    disabled
                    placeholder="$0.00"
                    {...getFieldProps('ServiceCharge')}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    error={Boolean(
                      touched.ServiceCharge && errors.ServiceCharge,
                    )}
                    helperText={touched.ServiceCharge && errors.ServiceCharge}
                  />

                  <TextField
                    sx={{ backgroundColor: 'whitesmoke' }}
                    fullWidth
                    label="VAT on Service Charge"
                    placeholder="$0.00"
                    disabled
                    {...getFieldProps('vat')}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    error={Boolean(touched.vat && errors.vat)}
                    helperText={touched.vat && errors.vat}
                  />
                </Stack>

                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <Typography sx={{ width: '50%', color: '#32b59f' }}>
                    Notes
                  </Typography>
                </Stack>

                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <Box sx={{ width: '100%' }}>
                    <LabelStyle>Customer Note</LabelStyle>
                    <TextField
                      fullWidth
                      disabled
                      placeholder="Enter Notes"
                      {...getFieldProps('customerNote')}
                      error={Boolean(
                        touched.customerNote && errors.customerNote,
                      )}
                      helperText={touched.customerNote && errors.customerNote}
                    />
                  </Box>
                </Stack>

                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <Box sx={{ width: '100%' }}>
                    <LabelStyle>ShopAmerica’s Note</LabelStyle>
                    <TextField
                      sx={{ backgroundColor: 'whitesmoke' }}
                      fullWidth
                      defaultValue={'Note'}
                      disabled
                      placeholder="Note"
                      {...getFieldProps('ShopAmericaNotes')}
                      error={Boolean(
                        touched.ShopAmericaNotes && errors.ShopAmericaNotes,
                      )}
                      helperText={
                        touched.ShopAmericaNotes && errors.ShopAmericaNotes
                      }
                    />
                  </Box>
                </Stack>

                {/* <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <Box sx={{ width: '100%' }}>
                    <LabelStyle>Invoice</LabelStyle>
                    <TextField
                      sx={{ backgroundColor: 'whitesmoke' }}
                      fullWidth
                      disabled
                      defaultValue={'view /download Invoice'}
                      placeholder="$50"
                      {...getFieldProps('Invoice')}
                    />
                  </Box>
                </Stack> */}

                 {viewData?.pdfDocument && (
                      <Box key={values.pdfDocument} my={1}>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 3 }}>
                        <Typography>Invoice</Typography>
                        </Box>
                        <iframe src={values?.pdfDocument}  type="application/pdf" width="100%" height="400px" />
                      </Box>
                    )}
                              
              </Stack>

              <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                <TextField
                  sx={{ backgroundColor: 'whitesmoke' }}
                  label="Total"
                  disabled
                  placeholder="50"
                  value={formatMoney(viewData.total)}
                  // {...getFieldProps('total')}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
              </Box>

              {/* {
                    retrive ? */}

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'end',
                  mt: 5,
                }}
              >
                <Link
                  underline="none"
                  component={RouterLink}
                  to={PATH_DASHBOARD.orderHistory.root}
                >
                  <Button
                    variant="contained"
                    sx={{ mr: 3, backgroundColor: '#32b59f' }}
                  >
                    Close
                  </Button>
                </Link>

                {/* <Link underline="none" component={RouterLink} to={PATH_DASHBOARD.root}>
                 <Button variant='contained'sx={{mr:3,backgroundColor:"#32b59f"}} >Close</Button>
                 </Link> */}

                {/* <LoadingButton type="submit" sx={{backgroundColor:"#32b59f",mr:3}} variant="contained" loading={isSubmitting} >
                 Save
                 </LoadingButton> */}

                {/* <Button variant='contained' sx={{backgroundColor:"#32b59f"}} onClick={handleAgrementSubmit}>Submit</Button> */}
              </Box>
              {/* : */}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'end',
                  mt: 2,
                }}
              >
                {/* <Link underline="none" component={RouterLink} to={PATH_DASHBOARD.orderHistory.root}>
                    <Button variant='contained'sx={{mr:3,backgroundColor:"#32b59f"}}>Close</Button>
                    </Link> */}
              </Box>
              {/* } */}
            </Card>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  )
}
